import React, { useState, useEffect } from "react";
import {
    Container, Row, Col, Table
} from "reactstrap";
import { Button } from 'primereact/button';
import { InputNumber } from "primereact/inputnumber";
const EditPutting = ({ id, setEdit }) => {

    const rows = Array.from({ length: 18 }, (_, i) => i + 1);

    const [distance, setDistance] = useState((Array(18).fill(0)));
    const [score, setScore] = useState((Array(18).fill(0)));



    const updateScoreArray = (index, value) => {
        const newArray = [...score];
        newArray[index - 1] = Number(value);
        setScore(newArray);
    };



    function submitPutting() {
        fetch(`/api/puttingpractice/id?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(score)
        })
            .then(response => response)
            .then(data => {
                setEdit(false);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getPutting();
    }, [])

    function getPutting() {
        fetch(`/api/puttingpractice/id?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setDistance(data.distances);
                setScore(data.scores);
            })
            .catch(error => console.error(error));
    }

    return (
        <Container >
            <Row className="table-container mt-3 mb-4">
                <Col md="12" lg="12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>Distance</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row}>
                                    <td>
                                        {row}
                                    </td>
                                    <td>
                                        {distance[row - 1]}
                                    </td>
                                    <td>
                                        <InputNumber
                                            onValueChange={(e) => updateScoreArray(row, e.target.value)}
                                            value={score[row - 1]}
                                            showButtons buttonLayout="horizontal"
                                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                            className="p-fluid"
                                            maxLength={1}
                                            min={1}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    <span className="p-buttonset mb-5" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button className="m-1" label="Cancel" icon="pi pi-arrow-circle-left" severity="secondary" onClick={() => { setEdit(false) }} style={{ width: "45%" }} />
                        <Button className="m-1" label="Submit" icon="pi pi-check" iconPos="right" severity="success" onClick={() => { submitPutting() }} style={{ width: "45%" }} />

                    </span>
                </Col>
            </Row>
        </Container >
    )
}

export { EditPutting }