// ConstantContext.js
import React, { createContext, useContext, useState } from 'react';

const ConstantContext = createContext();

export const ConstantProvider = ({ children }) => {

    const [pgaBase, setPgaBase] = useState(true);
    const [units, setUnits] = useState("yards")

    const metersToYards = 1.09361;
    const yardsToMeters = 0.9144;
    const sgPerShotUpper = 0.05;
    const sgPerShotLower = -0.05;
    //if low is true that means that it's better to have lower number
    const tourAvg = {
        noOfRounds: { value: "--", low: true },
        scoreAvg: { value: `${pgaBase ? 71.09 : 71.48}`, low: true, greenValue: `${pgaBase ? 69.9 : 70.2}`, redValue: `${pgaBase ? 72.1 : 73.0}` },
        par3Avg: { value: `${pgaBase ? 3.07 : 3.05}`, low: true, greenValue: `${pgaBase ? 3.02 : 2.98}`, redValue: `${pgaBase ? 3.13 : 3.15}` },
        par4Avg: { value: `${pgaBase ? 4.04 : 4.07}`, low: true, greenValue: `${pgaBase ? 3.98 : 4.0}`, redValue: `${pgaBase ? 4.09 : 4.17}` },
        par5Avg: { value: `${pgaBase ? 4.66 : 4.77}`, low: true, greenValue: `${pgaBase ? 4.54 : 4.66}`, redValue: `${pgaBase ? 4.73 : 4.88}` },
        birdiesPerRound: { value: 3.61, low: false, greenValue: 4.1, redValue: 3.3 },
        bogeysPerRound: { value: 2.67, low: true, greenValue: 2.27, redValue: 2.98 },
        doublesPerRound: { value: 0.32, low: true, greenValue: 0.24, redValue: 0.5 },
        otherPerRound: { value: 0.04, low: true, greenValue: 0.03, redValue: 0.1 },
        bogeyPar5PerRound: { value: 0.16, low: true, greenValue: 0.16, redValue: 0.25 },
        doublePerRound: { value: 0.32, low: true, greenValue: 0.24, redValue: 0.5 },
        easyChipPerRound: { value: "90%", low: false, greenValue: 97, redValue: 83 },
        threePuttPerRound: { value: 0.53, low: true, greenValue: 0.53, redValue: 0.66 },
        bogey150PerRound: { value: 1.15, low: true, greenValue: 1.15, redValue: 1.75 },
        sgApp: { value: 0, low: false, greenValue: `${pgaBase ? 0.49 : 0.6}`, redValue: `${pgaBase ? -0.53 : -1}` },
        sgAppShot: { value: 0, low: false, greenValue: `${pgaBase ? 0.49 : 0.6}`, redValue: `${pgaBase ? -0.53 : -1}` },
        sg50100: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        sg100150: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        sg150200: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        sg200: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        gir: { value: `${pgaBase ? 11.8 : 12.4}`, low: false, greenValue: `${pgaBase ? 12.4 : 13.4}`, redValue: `${pgaBase ? 11.2 : 11.3}` },
        gOrfir: { value: 12.9, low: false, greenValue: 13.6, redValue: 12.1 },
        gir100: { value: "85%", low: false, greenValue: 88.6, redValue: 81.5 },
        gir100150: { value: "72%", low: false, greenValue: 78.5, redValue: 64.7 },
        gir150200: { value: "59%", low: false, greenValue: 64.5, redValue: 52.7 },
        gir200: { value: "44%", low: false, greenValue: 53.2, redValue: 36.6 },
        eGPerRound: { value: 10.7, low: false, greenValue: 11.3, redValue: 10.1 },
        iPPerRound: { value: 7, low: false, greenValue: 7.6, redValue: 6.4 },
        bjKPerRound: { value: 2.7, low: false, greenValue: 3.3, redValue: 2.1 },
        appMissLeft: { value: "--", low: true, greenValue: "--", redValue: "--" },
        appMissRight: { value: "--", low: true, greenValue: "--", redValue: "--" },
        appMissLong: { value: "--", low: true, greenValue: "--", redValue: "--" },
        appMissShort: { value: "--", low: true, greenValue: "--", redValue: "--" },
        easyMissPct: { value: "--", low: false, greenValue: "--", redValue: "--" },
        mediumMissPct: { value: "--", low: false, greenValue: "--", redValue: "--" },
        hardMissPct: { value: "--", low: true, greenValue: "--", redValue: "--" },
        expected: {
            average: { value: "--", low: true, greenValue: "--", redValue: "--" },
            hit: { value: "--", low: true, greenValue: "--", redValue: "--" },
            missed: { value: "--", low: true, greenValue: "--", redValue: "--" }
        },
        sgARG: { value: 0, low: false, greenValue: `${pgaBase ? 0.39 : 0.24}`, redValue: `${pgaBase ? -0.33 : -0.53}` },
        sgARGShot: { value: 0, low: false, greenValue: `${pgaBase ? 0.39 : 0.24}`, redValue: `${pgaBase ? -0.33 : -0.53}` },
        sgARG030: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        sgARG3050: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        sgARGFringe: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        sgARGSand: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        scrambling: { value: "58%", low: false, greenValue: 63.7, redValue: 53.9 },
        p6: { value: "50%", low: false, greenValue: 58.7, redValue: 45 },
        p12: { value: "67%", low: false, greenValue: 71, redValue: 60 },
        easyChipSG: { value: 0, low: false, greenValue: "--", redValue: "--" },
        sgPutting: { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.59}`, redValue: `${pgaBase ? -0.46 : -0.96}` },
        makeRate3: { value: "99.42%", low: false, greenValue: 99.9, redValue: 98.95 },
        makeRate35: { value: "87.97%", low: false, greenValue: 91.88, redValue: 82.62 },
        makeRate510: { value: "56.47%", low: false, greenValue: 61.69, redValue: 50.27 },
        sgPutt: {
            sg20Birdie: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
            sg20Par: { value: 0, low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower }
        },
        sgPuttingLagOver30Shot: { value: "0", low: false, greenValue: sgPerShotUpper, redValue: sgPerShotLower },
        totalFirstPuttPerRound: { value: "--", low: false, greenValue: "--", redValue: "--" },
        totalFeetMadePerRound: { value: "72'", low: false, greenValue: 77, redValue: 66 },
        low35: { value: "--", low: true, greenValue: "--", redValue: "--" },
        high35: { value: "--", low: true, greenValue: "--", redValue: "--" },
        short35: { value: "--", low: true, greenValue: "--", redValue: "--" },
        low510: { value: "--", low: true, greenValue: "--", redValue: "--" },
        high510: { value: "--", low: true, greenValue: "--", redValue: "--" },
        short510: { value: "--", low: true, greenValue: "--", redValue: "--" },
        low1020: { value: "--", low: true, greenValue: "--", redValue: "--" },
        high1020: { value: "--", low: true, greenValue: "--", redValue: "--" },
        short1020: { value: "--", low: true, greenValue: "--", redValue: "--" },
        low20: { value: "--", low: true, greenValue: "--", redValue: "--" },
        high20: { value: "--", low: true, greenValue: "--", redValue: "--" },
        short20: { value: "--", low: true, greenValue: "--", redValue: "--" },
        sgOTT: { value: 0, low: false, greenValue: `${pgaBase ? 0.45 : 0.46}`, redValue: `${pgaBase ? -0.5 : -0.65}` },
        teeHit: { value: `${pgaBase ? "60%" : "74%"}`, low: false, greenValue: `${pgaBase ? 67 : 80}`, redValue: `${pgaBase ? 52 : 64}` },
        fairwayDriverHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway3wHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway5wHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway3hHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway4hHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway2iHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway3iHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        fairway4iHit: { value: "--", low: true, greenValue: "--", redValue: "--" },
        teeRecoveryHit: { value: "5.4%", low: true, greenValue: 2.7, redValue: 8.3 },
        teeSandHit: { value: "5.8%", low: true, greenValue: 4.7, redValue: 7.8 },
        teeMissLeft: { value: "--", low: true, greenValue: "--", redValue: "--" },
        teeMissRight: { value: "--", low: true, greenValue: "--", redValue: "--" },
        goingForItPct: { value: "57%", low: false, greenValue: 69, redValue: 43.5 },
        goingGreenHitPct: { value: "22%", low: false, greenValue: 27.8, redValue: 16.1 },
        goingBirdiePct: { value: "57%", low: false, greenValue: 64.3, redValue: 50.4 },
        goingBogeyPct: { value: "--", low: true, greenValue: "--", redValue: "--" },
        goingDistanceAfter: { value: units === "yards" ? "24.5 yds" : "22.4 m", low: true, greenValue: 22.3, redValue: 28 },
        avgGoing: { value: "--", low: true, greenValue: "--", redValue: "--" },
        avgLayUp: { value: "--", low: true, greenValue: "--", redValue: "--" },


    }

    return (
        <ConstantContext.Provider value={{ tourAvg, sgPerShotUpper, sgPerShotLower, yardsToMeters, metersToYards, pgaBase, setPgaBase, setUnits }} >
            {children}
        </ConstantContext.Provider>
    );
};

export const useConstant = () => {
    return useContext(ConstantContext);
};
