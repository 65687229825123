import React from "react";
import { useState, useRef } from "react";
import countries from 'country-list';
import { Form, Row, Col } from "reactstrap";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../css/flags.css"
import 'primeicons/primeicons.css';
function AddCourse(props) {
  const crsNameInputRef = useRef(null);
  const crsCityInputRef = useRef(null);
  const [submitDisabled, setSubmitDisabled] = useState(false);

  // country-list package test
  const countryList = countries.getData();
  const [selectedCountry, setSelectedCountry] = useState('');

  const [courseName, setCourseName] = useState("");
  const [city, setCity] = useState("")
  const handleCountryClick = (event) => {
    setSelectedCountry(event.target.value);
  };
  function sanitizeInput(str) {
    while (str.charAt(0) === " ") {
      str = str.substring(1);
    }
    while (str.charAt(str.length - 1) === " ") {
      str = str.substring(0, str.length - 1);
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmitCourse = (event) => {

    const data = {
      courseName: sanitizeInput(courseName),
      city: sanitizeInput(city),
      country: selectedCountry ? selectedCountry.name : 'None'
    };

    // Send a POST request to the API endpoint
    fetch('/api/Course', {
      method: 'POST',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then(data => {
        props.toast.current.show({ severity: 'success', summary: 'Course Added', detail: 'Course was added.' });
        setSubmitDisabled(false);
        setCourseName("");
        setCity("");
        setSelectedCountry("");
      })
      .catch(error => {
        props.toast.current.show({ severity: 'error', summary: 'Course Exists', detail: 'Course with this name already exists' });
        setCourseName("");
        setCity("");
        setSelectedCountry("");
        setSubmitDisabled(false);
        console.error(error);
      });
  }

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
          <div>{option.name}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
        <div>{option.name}</div>
      </div>
    );
  };


  return (
    <div className="mt-5">
      <Form>
        <Row className="row-cols-lg-auto g-1 align-items-center">
          <Col xs="2" style={{ marginRight: "15px", marginTop: "0px" }}>
            <p style={{ marginBottom: "0px" }}>Add your course</p>
          </Col>
          <Col xs="6">
            <span className="p-float-label">
              <InputText id="courseName" value={courseName} onChange={(e) => setCourseName(e.target.value)} />
              <label htmlFor="courseName">Course Name</label>
            </span>
          </Col>
          <Col xs="7" className="mt-sm-0 mt-3">
            <span className="p-float-label">
              <InputText id="city" value={city} onChange={(e) => setCity(e.target.value)} />
              <label htmlFor="city">City</label>
            </span>
          </Col>
          <Col xs="7" className="mt-sm-0 mt-3">
            <Dropdown
              color="primary"
              value={selectedCountry}
              onChange={(event) => handleCountryClick(event)}
              options={countryList}
              optionLabel="name"
              placeholder="Select a Country"
              filter
              filterPlaceholder="Search a Country"
              valueTemplate={selectedCountryTemplate}
              itemTemplate={countryOptionTemplate}
              className="w-full md:w-14rem" />
          </Col>

          <Col xs="6" className="mt-sm-0 mt-3">
            <Button
              label="Submit"
              severity="secondary"
              loading={submitDisabled}
              onClick={() => {
                setSubmitDisabled(true);
                handleSubmitCourse();
                props.onClick2();
                setSelectedCountry('')
              }} />
          </Col>
        </Row>
      </Form>
    </div>
  );
}

export default AddCourse;
