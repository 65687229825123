import React, { useEffect, useState } from 'react';
import '../custom.css'
import {
    Container, Table,
    Row, Col
} from "reactstrap";
import '../css/loading.css'
import { Message } from 'primereact/message';
import { Tooltip } from 'primereact/tooltip';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import 'primeicons/primeicons.css';
import '../css/stats.css'
import { useConstant } from '../ConstantContext';
const IndividualStats = ({ data, baseline, requestProcessed, units }) => {

    const analysisOptions = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement'];

    const [scoreAverage, setScoreAverage] = useState("");
    const [totalImprovement, setTotalImprovement] = useState("");
    const [newScoreAverage, setNewScoreAverage] = useState("");

    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"
    const constants = useConstant();

    // Define the ranges for the values and attempts
    const ranges = ['sg50100', 'sg100150', 'sg150200', 'sg200'];
    const positions = ['BackLeft', 'BackCenter', 'BackRight',
        'MiddleLeft', 'MiddleCenter', 'MiddleRight', 'FrontLeft',
        'FrontCenter', 'FrontRight'];

    const tiger5stats = [{ name: 'bogeyPar5', display: 'Bogeys on Par 5' },
    { name: 'double', display: "Doubles+" }, { name: 'threePutt', display: "Three Putts" },
    { name: 'bogey150', display: `Bogeys inside ${units === "yards" ? baseline === "LPGA" ? '125y' : '150y' : baseline === "LPGA" ? '114m' : '137m'}` },
    { name: 'easyChip', display: 'Easy u&d' }];
    const options = ['PerRound', 'Attempts', 'Count'];

    const clubsApp = ['lw', 'sw', 'gw', 'pw', '_9i', '_8i', '_7i', '_6i', '_5i', '_4i', '_3i', '_2i', '_4h', '_3h', '_7w', '_5w', '_3w'];
    const clubsTee = ['Driver', '3w', '5w', '7w', '3h', '4h', '2i', '3i', '4i'];
    const teeStats = [{ name: 'tee', display: 'Fairway hit' }, { name: 'teeRecovery', display: 'Tee shots in recovery or penalty' },
    { name: 'teeSand', display: 'Tee shots in sand' }]
    const teeMiss = [{ name: 'teeMissLeft', display: "Miss Left" }, { name: 'teeMissRight', display: 'Miss Right' }];

    const scoringStats = [{ name: 'scoreAvg', display: "Score Avg." }, { name: 'par3Avg', display: 'Par 3 Score Avg.' },
    { name: 'par4Avg', display: 'Par 4 Score Avg.' }, { name: 'par5Avg', display: 'Par 5 Score Avg.' },
    { name: 'birdiesPerRound', display: 'Birdies Per Round' }, { name: 'bogeysPerRound', display: 'Bogeys Per Round' },
    { name: 'doublesPerRound', display: 'Double Bogeys Per Round' }, { name: 'otherPerRound', display: 'Other Per Round' },];

    const sgApp = [{ name: "sgAppShot", display: 'SG' }, { name: "sg50100", display: `SG ${units === "yards" ? '50-100y' : '46-91m'}` },
    { name: "sg100150", display: `SG ${units === "yards" ? '100-150y' : '91-137m'}` }, { name: "sg150200", display: `SG ${units === "yards" ? '150-200y' : '137-182m'}` },
    { name: "sg200", display: `SG ${units === "yards" ? '200+y' : '182+m'}` }]
    const rangesApp = [{ name: "gir", display: `GIR` }, { name: "gOrfir", display: `Green or Fringe in Reg` },
    { name: "gir100", display: `GIR ≤ ${units === "yards" ? '100y' : '91m'}` }, { name: "gir100150", display: `GIR ${units === "yards" ? '100-150y' : '91-137m'}` },
    { name: "gir150200", display: `GIR ${units === "yards" ? '150-200y' : '137-182m'}` }, { name: "gir200", display: `GIR ${units === "yards" ? '200+y' : '182+m'}` },];

    const positionsApp = ["", "Attempts", "Success"];
    const gfApp = [{ name: 'eG', display: 'GIR to ≤40\'' }, { name: 'iP', display: 'GIR to ≤20\'' }, { name: 'bjK', display: 'GIR to ≤8\'' }];
    const gfOptions = ['Attempts', 'Success', 'PerRound'];
    const missApp = [{ name: 'appMissLong', display: 'Miss Long' }, { name: 'appMissShort', display: 'Miss Short' },
    { name: 'appMissRight', display: 'Miss Right' }, { name: 'appMissLeft', display: 'Miss Left' },
    { name: 'easyMissPct', display: 'Approaches missed to easy spots' },
    { name: 'mediumMissPct', display: 'Approaches missed to medium spots' },
    { name: 'hardMissPct', display: 'Approaches missed to hard spots' }];
    const expected = [{ name: "", display: "Exptected Strokes After Approach" }, { name: "Hit", display: "Exptected Strokes After Approach (green hit)" },
    { name: "Missed", display: "Exptected Strokes After Approach (green missed)" }]

    const goingForIt = [{ name: 'goingForIt', display: 'Going for the green' }, { name: 'goingGreenHit', display: 'Going for the green - Green Hit' },
    { name: 'goingBirdie', display: 'Going for the green - Birdie or better' }, { name: 'goingBogey', display: 'Going for the green - Bogey or worse' }];
    const goingForItOptions = ['Pct', 'Count', 'Attempts'];
    const goingAvg = [{ name: 'avgGoing', display: 'Going for it average score' }, { name: 'avgLayUp', display: 'Laying up average' }]

    const puttingStats = ['sgPutting', 'sgPuttingLagOver30Shot'];
    const puttingFeet = [{ name: 'totalFirstPuttPerRound', display: 'Total ft of first putts per round' }, { name: 'totalFeetMadePerRound', display: 'Total ft of putts made per round' }];
    const puttingMake = [{ name: '3', display: 'Make rate 3\'' }, { name: '35', display: 'Make rate 3-5\'' }, { name: '510', display: 'Make rate 5-10\'' }];
    const puttingMakeOptions = ['makeRate', 'attempts', 'success'];
    const puttingSG = [{ name: '20Birdie', display: 'SG inside 20\' for birdie' }, { name: '20Par', display: 'SG inside 20\' for par' }]
    const sgPutt = [{ name: "3", display: "SG 3'" }, { name: "35", display: "SG 3-5'" }, { name: "510", display: "SG 5-10'" },
    { name: "1020", display: "SG 10-20'" }, { name: "2030", display: "SG 20-30'" }
    ]
    const puttingMissDst = [{ name: '35', display: 'miss 3-5\'' }, { name: '510', display: 'miss 5-10\'' },
    { name: '1020', display: 'miss 10-20\'' }, { name: '2030', display: 'miss 20-30\'' }, { name: '30', display: 'miss 30+\'' }];
    const puttingMisses = ['Low', 'High', 'Short'];

    const argStats = [{ name: 'scrambling', display: 'Scrambling' }, { name: 'p6', display: 'Chips to ≤6\'' }, { name: 'p12', display: 'Chips to ≤12\'' }];
    const argOptions = ['', 'Success', 'Attempts'];
    const argSG = [{ name: 'sgARGShot', display: 'SG per round' }, { name: 'sgARG030', display: `SG ${units === "yards" ? '0-30y*' : '0-27m*'}` },
    { name: 'sgARG3050', display: `SG ${units === "yards" ? '30-50y*' : '27-46m*'}` }, { name: 'sgARGSand', display: 'SG from sand*' },
    { name: 'sgARGFringe', display: 'SG from fringe*' }, { name: 'easyChipSG', display: 'SG easy chip' }];
    const argSGOptions = ['', 'Attempts'];

    const mistakes = [{ name: 'good', display: "Good shots" }, { name: 'technique', display: "Technique error" },
    { name: 'mental', display: "Mental error" }, { name: 'decision', display: "Decision error" }];
    const mistakesOptions = ['App', 'Arg', 'Tee', 'Putting'];

    // Function to format the ranges for display
    const formatDisplayRange = (range) => {
        if (range.length >= 8) {
            const start = range.slice(2, 5);
            const end = range.slice(5);
            return `${baseline === "yards" ? start : parseInt(start * constants.yardsToMeters)}-${baseline === "yards" ? end : parseInt(end * constants.yardsToMeters)}${baseline === "yards" ? "y" : "m"}`;
        } else if (range.length === 7) {
            const start = range.slice(2, 4);
            const end = range.slice(4);
            return `${baseline === "yards" ? start : parseInt(start * constants.yardsToMeters)}-${baseline === "yards" ? end : parseInt(end * constants.yardsToMeters)}${baseline === "yards" ? "y" : "m"}`;
        } else {
            const start = range.slice(2, 5);
            return `${baseline === "yards" ? start : parseInt(start * constants.yardsToMeters)}+${baseline === "yards" ? "y" : "m"}`;
        }
    };
    // Create state variables and setters using loops
    const [state, setState] = useState(() => {
        const initialState = {};
        initialState['noOfRounds'] = 0;
        for (var i = 1; i < 6; i++) {
            analysisOptions.forEach((option) => {
                initialState[`analysis${i}${option}`] = '';
                initialState[`strength${i}${option}`] = '';
            })
        }
        ranges.forEach((range) => {
            positions.forEach((position) => {
                initialState[`${range}${position}`] = 0;
                initialState[`${range}${position}Attempts`] = 0;
            });
        });
        tiger5stats.forEach((stat) => {
            options.forEach((option) => {
                initialState[`${stat.name}${option}`] = 0;
            });
        });
        clubsApp.forEach((clubApp) => {
            initialState[`${clubApp}`] = {};/* 
            initialState[`attempts${clubApp}`] = 0; */
        });
        teeStats.forEach((stat) => {
            initialState[`${stat}Attempts`] = 0;
            initialState[`${stat}Count`] = 0;
            initialState[`${stat}Hit`] = 0;

        });
        teeMiss.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        clubsTee.forEach((clubTee) => {
            initialState[`tee${clubTee}Attempts`] = 0;
            initialState[`tee${clubTee}Success`] = 0;
            initialState[`fairway${clubTee}Hit`] = 0;
        });
        scoringStats.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        sgApp.forEach((stat) => {
            initialState[`${stat}`] = 0.0;
        });
        initialState[`sgApp`] = 0.0;
        rangesApp.forEach((range) => {
            positionsApp.forEach((position) => {
                initialState[`${range.name}${position}`] = 0;
            });
        });
        gfApp.forEach((range) => {
            gfOptions.forEach((position) => {
                initialState[`${range}${position}`] = 0;
            });
        });
        missApp.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        initialState[`expected`] = {}
        goingForIt.forEach((a) => {
            goingForItOptions.forEach((b) => {
                initialState[`${a}${b}`] = 0;
            });
        });
        initialState['goingDistanceAfter'] = 0;
        puttingMake.forEach((a) => {
            puttingMakeOptions.forEach((b) => {
                initialState[`${b}${a}`] = 0;
            });
        });
        initialState['sgPutt'] = {}
        /* puttingSG.forEach((a) => {
            initialState['sgPutt'][`sg${a.name}`] = 0.0;
            initialState['sgPutt'][`attempts${a.name}`] = 0;
            initialState['sgPutt'][`sg${a.name}Total`] = 0.0;
        }) */
        sgPutt.forEach((stat) => {
            initialState[`sgPutting${stat.name}`] = 0.0;
        })
        initialState[`sgPuttingShot`] = 0.0;
        initialState[`attemptssgPuttingShot`] = 0;
        initialState[`attempts2030`] = 0;
        puttingStats.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        puttingMissDst.forEach((dst) => {
            puttingMisses.forEach((miss) => {
                initialState[`${miss.toLowerCase()}${dst.name}`] = 0;
                initialState[`count${miss}${dst.name}`] = 0;
            });
        });
        initialState['attempts20'] = 0;
        puttingFeet.forEach((stat) => {
            initialState[`${stat.name}`] = 0;
        });
        argStats.forEach((a) => {
            argOptions.forEach((b) => {
                initialState[`${b}${a}`] = 0;
            });
        });
        initialState[`sgARGShot`] = 0.0;
        initialState[`sgARGShotAttempts`] = 0;
        argSG.forEach((a) => {
            argSGOptions.forEach((b) => {
                initialState[`${b}${a}`] = 0;
            });
        });
        mistakes.forEach((a) => {
            mistakesOptions.forEach((b) => {
                initialState[`${b}${a}`] = 0;
            });
        });
        goingAvg.forEach((a) => {
            initialState[`${a.name}`] = 0.0
        })
        return initialState;
    });

    const [activeTabs, setActiveTabs] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11])


    useEffect(() => {
        if (data || requestProcessed) {
            fillOutTable(data);
        }
        if (baseline === "LPGA") {
            constants.setPgaBase(false);
        }
        if (units === "meters") {
            constants.setUnits("meters");
        }
    }, [data, requestProcessed, units])
    /**
     * Calls all functions that fill out tables
     * @param {JSON} data 
     */
    async function fillOutTable(data) {
        try {
            fillAnalysis(data);
            fillStats(data);
            setActiveTabs([0, 1, 2, 3, 4, 5, 8, 9, 10, 11])
        } catch (e) {
            console.error(e)
        }
    }

    function fillAnalysis(data) {
        // Define the variables and their values
        var variables = [
            { name: "PUTT 3'", value: data.sgPutting3, attempts: data.attempts3, perShot: data.sgPutting3 / data.attempts3, improvement: (data.sgPutting3 / data.attempts3) * (data.attempts3 / data.noOfRounds) },
            { name: "PUTT 3-5'", value: data.sgPutting35, attempts: data.attempts35, perShot: data.sgPutting35 / data.attempts35, improvement: (data.sgPutting35 / data.attempts35) * (data.attempts35 / data.noOfRounds) },
            { name: "PUTT 5-10'", value: data.sgPutting510, attempts: data.attempts510, perShot: data.sgPutting510 / data.attempts510, improvement: (data.sgPutting510 / data.attempts510) * (data.attempts510 / data.noOfRounds) },
            { name: "PUTT 10-20'", value: data.sgPutting1020, attempts: data.attempts1020, perShot: data.sgPutting1020 / data.attempts1020, improvement: (data.sgPutting1020 / data.attempts1020) * (data.attempts1020 / data.noOfRounds) },
            { name: "PUTT 20-30'", value: data.sgPutting2030, attempts: data.attempts2030, perShot: data.sgPutting2030 / data.attempts2030, improvement: (data.sgPutting2030 / data.attempts2030) * (data.attempts2030 / data.noOfRounds) },
            { name: "Lag Putting 30'+", value: data.sgPuttingLagOver30, attempts: data.attempts30, perShot: data.sgPuttingLagOver30 / data.attempts30, improvement: (data.sgPuttingLagOver30 / data.attempts30) * (data.attempts30 / data.noOfRounds) },
            { name: `ARG ${units === "meters" ? "0-27m" : "0-30y"}`, value: data.sgARG030 * data.sgARG030Attempts, attempts: data.sgARG030Attempts, perShot: data.sgARG030, improvement: (data.sgARG030) * (data.sgARG030Attempts / data.noOfRounds) },
            { name: `ARG ${units === "meters" ? "27-46m" : "30-50y"}`, value: data.sgARG3050 * data.sgARG3050Attempts, attempts: data.sgARG3050Attempts, perShot: data.sgARG3050, improvement: (data.sgARG3050) * (data.sgARG3050Attempts / data.noOfRounds) },
          /*   { name: 'ARG Sand', value: data.sgARGSand * data.sgARGSandAttempts, attempts: data.sgARGSandAttempts, perShot: data.sgARGSand, improvement: (data.sgARGSand) * (data.sgARGSandAttempts / data.noOfRounds) },
            { name: 'ARG Fringe', value: data.sgARGFringe * data.sgARGFringeAttempts, attempts: data.sgARGFringeAttempts, perShot: data.sgARGFringe, improvement: (data.sgARGFringe) * (data.sgARGFringeAttempts / data.noOfRounds) },
            */ { name: `APP ${units === "meters" ? "46-91m" : "50-100y"}`, value: data.sg50100 * data.sg50100Attempts, attempts: data.sg50100Attempts, perShot: data.sg50100, improvement: (data.sg50100) * (data.sg50100Attempts / data.noOfRounds) },
            { name: `APP ${units === "meters" ? "91-137m" : "100-150y"}`, value: data.sg100150 * data.sg100150Attempts, attempts: data.sg100150Attempts, perShot: data.sg100150, improvement: (data.sg100150) * (data.sg100150Attempts / data.noOfRounds) },
            { name: `APP ${units === "meters" ? "137-183m" : "150-200y"}`, value: data.sg150200 * data.sg150200Attempts, attempts: data.sg150200Attempts, perShot: data.sg150200, improvement: (data.sg150200) * (data.sg150200Attempts / data.noOfRounds) },
            { name: `APP ${units === "meters" ? "183+m" : "200+y"}`, value: data.sg200 * data.sg200Attempts, attempts: data.sg200Attempts, perShot: data.sg200, improvement: (data.sg200) * (data.sg200Attempts / data.noOfRounds) },
            { name: 'Tee Shots', value: data.sgOTTTotal, attempts: data.teeAttempts, perShot: data.sgOTTTotal / data.teeAttempts, improvement: (data.sgOTTTotal / data.teeAttempts) * (data.teeAttempts / data.noOfRounds) }
        ];

        // Sort the variables based on their values in ascending order
        variables.sort(function (a, b) {
            return a.value - b.value;
        });

        // Get the first five variables with the lowest values
        var lowestVariables = variables.slice(0, 5);
        for (let i = 0; i < 5; i++) {
            setState((prev) => ({
                ...prev,
                [`analysis${i + 1}Name`]: lowestVariables[i].name,
                [`analysis${i + 1}Total`]: parseFloat(lowestVariables[i].value).toFixed(2),
                [`analysis${i + 1}Attempts`]: lowestVariables[i].attempts,
                [`analysis${i + 1}PerShot`]: parseFloat(lowestVariables[i].perShot).toFixed(3),
                [`analysis${i + 1}Improvement`]: parseFloat(lowestVariables[i].improvement).toFixed(2),
            }));
        }


        setScoreAverage(data.scoreAvg);
        let sum = 0.0;
        for (var i = 0; i < 5; i++) {
            if (lowestVariables[i].name !== "ARG Sand" && lowestVariables[i].name !== 'ARG Fringe') {
                sum += lowestVariables[i].improvement;
            }
        }
        setTotalImprovement((parseFloat(sum).toFixed(2)));
        setNewScoreAverage((parseFloat(data.scoreAvg + sum).toFixed(2)))

        // Get the last five variables with the lowest values
        var highestVariables = variables.slice(variables.length - 5, variables.length);
        for (let i = 0; i < 5; i++) {
            setState((prev) => ({
                ...prev,
                [`strength${i + 1}Name`]: highestVariables[4 - i].name,
                [`strength${i + 1}Total`]: parseFloat(highestVariables[4 - i].value).toFixed(2),
                [`strength${i + 1}Attempts`]: highestVariables[4 - i].attempts,
                [`strength${i + 1}PerShot`]: parseFloat(highestVariables[4 - i].perShot).toFixed(3),
                [`strength${i + 1}Improvement`]: parseFloat(highestVariables[4 - i].improvement).toFixed(2),
            }));
        }
    }
    function fillStats(data) {
        /*    var tables = ["advapproachtable", "tiger5table", "clubstable",
               "teetable", "scoringtable", "approachtable", "goingtable",
               "puttingtable", "argtable", "analysistable", "strengthtable"]
           tables.forEach((name) => {
               removeLoadingAnimation(name);
           })
           console.log("here") */
        Object.keys(data).forEach((key) => {
            setState((prev) => ({
                ...prev,
                [key]: data[key],
            }));
        });
    }

    function removeLoadingAnimation(table) {
        var table = document.getElementById(table);
        var cells = table.getElementsByClassName("loading-cell");

        // Remove the loading class from cells
        while (cells.length) {
            cells[0].classList.remove("loading-cell");
        }
    }

    function easyChipInfo() {
        return (
            <div style={{ display: "inline-flex" }}>
                <Tooltip target=".UDInfo" />
                <div>Easy u&d</div>
                <i className='pi pi-info-circle m-1 UDInfo' data-pr-tooltip="Easy scrambling opportunity is whenever a player is on the fringe inside 23 yards or
                        not short sided on the fairway inside 20 yards or not short sided in the rough inside 13 yards." />
            </div>
        )
    }
    function renderStrengths() {
        return (
            <div className="table-responsive">
                <Table id='strengthtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Total SG</th>
                            <th>Att.</th>
                            <th>SG per Shot</th>
                            <th>Avg. SG per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <tr style={{
                                color: 'white',
                                backgroundColor: green
                            }}>
                                <td className='stats-title'>{index + 1}</td>
                                {analysisOptions.map((option) => (
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        )

    }
    function renderAnalysis() {
        return (
            <div className="table-responsive">
                <Table id='analysistable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Total SG</th>
                            <th>Att.</th>
                            <th>SG per Shot</th>
                            <th>Avg. SG per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <tr style={{
                                color: 'white',
                                backgroundColor: red
                            }}>
                                <td className='stats-title'>{index + 1}</td>
                                {analysisOptions.map((option) => (
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </Table>
                <div>Current Score Avg.: {scoreAverage}</div>
                <div>Total Score Avg. Improvement: {totalImprovement}</div>
                <div>New Score Avg.: {newScoreAverage}</div>
            </div>
        )
    }

    /**
     * Renders table for tiger 5 stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderTiger5() {
        return (
            <div className="table-responsive">
                <Table id='tiger5table' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Per round</th>
                            <th>Count</th>
                            <th>Attempts</th>
                            <th>Tour average</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tiger5stats.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                        (constants.tourAvg[`${stat.name}PerRound`].low ?
                                            (state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].greenValue ||
                                                state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].redValue) && 'white' :
                                            (state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].greenValue ||
                                                state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].redValue) && 'white'),

                                    backgroundColor: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                        (constants.tourAvg[`${stat.name}PerRound`].low === true ?
                                            (state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].greenValue ? green :
                                                state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].redValue ? red : '')
                                            :
                                            (state[`${stat.name}PerRound`] > constants.tourAvg[`${stat.name}PerRound`].greenValue ? green :
                                                state[`${stat.name}PerRound`] < constants.tourAvg[`${stat.name}PerRound`].redValue ? red : ''))
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}PerRound`]}{stat.name === "easyChip" && "%"}</td>
                                <td>{state[`${stat.name}Count`]}</td>
                                <td>{stat.name === "double" ? "--" : state[`${stat.name}Attempts`]}</td>
                                <td>{constants.tourAvg[`${stat.name}PerRound`]?.value}</td>
                            </tr>

                        ))}
                    </tbody>

                </Table>
            </div>
        )
    }
    /**
     * Renders table for scoring stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderScoring() {
        return (
            <div className="table-responsive">
                <Table id='scoringtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Tour average</th>
                        </tr>
                    </thead>
                    <tbody>
                        {scoringStats.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color:
                                        (constants.tourAvg[`${stat.name}`].low ?
                                            (state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].greenValue ||
                                                state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].redValue) && 'white' :
                                            (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ||
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue) && 'white'),

                                    backgroundColor:
                                        (constants.tourAvg[`${stat.name}`].low ?
                                            (state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].greenValue ? green :
                                                state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].redValue ? red : '')
                                            :
                                            (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ? green :
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue ? red : ''))
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}</td>
                                <td>{constants.tourAvg[`${stat.name}`]?.value}</td>
                            </tr>

                        ))}
                    </tbody>
                </Table>
            </div>
        )
    }

    function renderApproachesStrokesGained() {
        return (
            <div className="table-responsive">
                <Table id='approachtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att.</th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sgApp.map((range) => (
                            <tr
                                key={`${range.name}`}
                                style={{
                                    color:
                                        (range.display === "SG" ? (state[`sgApp`] < constants.tourAvg[`sgApp`].redValue ||
                                            state[`sgApp`] > constants.tourAvg[`sgApp`].greenValue)
                                            : (state[`${range.name}`] < constants.tourAvg[`${range.name}`].redValue ||
                                                state[`${range.name}`] > constants.tourAvg[`${range.name}`].greenValue)) &&
                                        'white',
                                    backgroundColor:
                                        (range.display === "SG" ?
                                            (state[`sgApp`] > constants.tourAvg[`sgApp`].greenValue ? green :
                                                state[`sgApp`] < constants.tourAvg[`sgApp`].redValue ? red : '')
                                            : state[`${range.name}`] > constants.tourAvg[`${range.name}`].greenValue ? green :
                                                state[`${range.name}`] < constants.tourAvg[`${range.name}`].redValue ? red : '')
                                }}>
                                <td>{range.display}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat(state[`${range.name}`] * state[`${range.name}Attempts`]).toFixed(2)}</td>
                                <td>{range.name !== 'sgApp' ? state[`${range.name}Attempts`] : '--'}</td>
                                <td>{state[`${range.name}`]}</td>
                                <td>{parseFloat(state[`${range.name}`] * state[`${range.name}Attempts`] / state[`noOfRounds`]).toFixed(2)}</td>
                            </tr>
                        ))}


                    </tbody>
                </Table>
            </div>
        )
    }
    /**
     * Renders table for approach stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderApproaches() {
        return (
            <div>
                {renderApproachesStrokesGained()}
                <div className="table-responsive">
                    <Table id='approachtable' className='stats-table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Value</th>
                                <th>Count</th>
                                <th>Attempts</th>
                                <th>Tour Avg.</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rangesApp.map((range) => (
                                <tr
                                    key={`${range.name}`}
                                    style={{
                                        color:
                                            (state[`${range.name}`] < constants.tourAvg[`${range.name}`].redValue ||
                                                state[`${range.name}`] > constants.tourAvg[`${range.name}`].greenValue) &&
                                            'white',
                                        backgroundColor:
                                            (state[`${range.name}`] > constants.tourAvg[`${range.name}`].greenValue ? green :
                                                state[`${range.name}`] < constants.tourAvg[`${range.name}`].redValue ? red : '')
                                    }}>
                                    <td>{range.display}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]}{range.display.substring(0, 4) === 'GIR ' && '%'}</td>
                                    <td>{range.name.charAt(0) !== 's' ? state[`${range.name}Success`] : '--'}</td>
                                    <td>{range.name !== 'sgApp' ? state[`${range.name}Attempts`] : '--'}</td>
                                    <td>{range.name.charAt(0) !== 's' ? constants.tourAvg[`${range.name}`].value : '--'}</td>
                                </tr>
                            ))}
                            {gfApp.map((range) => (
                                <tr
                                    key={`${range}`}
                                    style={{
                                        color:
                                            (state[`${range.name}PerRound`] < constants.tourAvg[`${range.name}PerRound`].redValue ||
                                                state[`${range.name}PerRound`] > constants.tourAvg[`${range.name}PerRound`].greenValue) &&
                                            'white',
                                        backgroundColor:
                                            (state[`${range.name}PerRound`] > constants.tourAvg[`${range.name}PerRound`].greenValue ? green :
                                                state[`${range.name}PerRound`] < constants.tourAvg[`${range.name}PerRound`].redValue ? red : '')
                                    }}>
                                    <td>{range.display}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}PerRound`]}</td>
                                    <td>{state[`${range.name}Success`]}</td>
                                    <td>{state[`${range.name}Attempts`]}</td>
                                    <td>{constants.tourAvg[`${range.name}PerRound`].value}</td>
                                </tr>
                            ))}
                            {missApp.map((range) => (
                                <tr key={`${range.name}`}>
                                    <td>{range.display}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${range.name}`]}%</td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            ))}
                            {expected.map((e) => (
                                <tr key={`${e.name}`}>
                                    <td>{e.display}*</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`expected`][`average${e.name}`]}</td>
                                    <td>--</td>
                                    <td>{state[`expected`][`attempts${e.name}`]}</td>
                                    <td></td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
            </div>
        )
    }
    function renderAdvancedApproaches() {
        return (
            <div className="table-responsive">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                <Table id='advapproachtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value*</th>
                            {(data && data.noOfRounds > 1) && <th>Per Round</th>}
                            <th>Attempts</th>
                        </tr>
                    </thead>
                    <tbody>
                        {ranges.map((range) => (
                            positions.map((position) => (
                                <tr
                                    key={`${range}${position}`}
                                    style={{
                                        display: state[`${range}${position}Attempts`] > 0 ? '' : 'none',
                                        color: state[`${range}${position}Attempts`] &&
                                            state[`${range}${position}`] < constants.sgPerShotLower
                                            ? 'white'
                                            : state[`${range}${position}Attempts`] &&
                                                state[`${range}${position}`] > constants.sgPerShotUpper
                                                ? 'white'
                                                : '',
                                        backgroundColor: state[`${range}${position}Attempts`] &&
                                            state[`${range}${position}`] < constants.sgPerShotLower
                                            ? red
                                            : state[`${range}${position}Attempts`] &&
                                                state[`${range}${position}`] > constants.sgPerShotUpper
                                                ? green
                                                : '',
                                    }}
                                >
                                    <td>{`SG ${formatDisplayRange(range)} ${position.replace(/([a-z])([A-Z])/g, '$1 $2')}`}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat(state[`${range}${position}`] / state[`${range}${position}Attempts`]).toFixed(3)}</td>
                                    {(data && data.noOfRounds > 1) && <td>{data && state[`${range}${position}Attempts`] !== 0 &&
                                        parseFloat((state[`${range}${position}`] / state[`${range}${position}Attempts`]) * (state[`${range}${position}Attempts`] / data.noOfRounds)).toFixed(3)}</td>}
                                    <td>{state[`${range}${position}Attempts`]}</td>
                                </tr>
                            ))
                        ))}
                    </tbody>
                </Table>
            </div>

        )
    }
    /**
     * Renders table for going for it stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderGoing() {
        return (
            <div className="table-responsive">
                <Table id='goingtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Count</th>
                            <th>Attempts</th>
                            <th>Tour average</th>
                        </tr>
                    </thead>
                    <tbody>
                        {goingForIt.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                        (constants.tourAvg[`${stat.name}Pct`].low ?
                                            (state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].greenValue ||
                                                state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].redValue) && 'white' :
                                            (state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].greenValue ||
                                                state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].redValue) && 'white'),

                                    backgroundColor: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                        (constants.tourAvg[`${stat.name}Pct`].low === true ?
                                            (state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].greenValue ? green :
                                                state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].redValue ? red : '')
                                            :
                                            (state[`${stat.name}Pct`] > constants.tourAvg[`${stat.name}Pct`].greenValue ? green :
                                                state[`${stat.name}Pct`] < constants.tourAvg[`${stat.name}Pct`].redValue ? red : ''))
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}Pct`]}%</td>
                                <td>{state[`${stat.name}Count`]}</td>
                                <td>{state[`${stat.name}Attempts`]}</td>
                                <td>{constants.tourAvg[`${stat.name}Pct`]?.value}</td>
                            </tr>
                        ))}
                        <tr key={`goingDistanceAfter`}
                            style={{
                                color: (state[`goingForItCount`] > 0) &&
                                    (state[`goingDistanceAfter`] < constants.tourAvg[`goingDistanceAfter`].greenValue ||
                                        state[`goingDistanceAfter`] > constants.tourAvg[`goingDistanceAfter`].redValue) && 'white',

                                backgroundColor: (state[`goingForItCount`] > 0) &&
                                    (state[`goingDistanceAfter`] < constants.tourAvg[`goingDistanceAfter`].greenValue ? green :
                                        state[`goingDistanceAfter`] > constants.tourAvg[`goingDistanceAfter`].redValue ? red : '')
                            }}>
                            <td>{`Average Distance after Going for it Shot`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{units === "yards" ? state[`goingDistanceAfter`] + " y" : (parseFloat(state[`goingDistanceAfter`]) * constants.yardsToMeters).toFixed(1) + " m"}</td>
                            <td>--</td>
                            <td>--</td>
                            <td>{constants.tourAvg[`goingDistanceAfter`]?.value}</td>
                        </tr>
                        {goingAvg.map((stat) => (
                            <tr key={`${stat.name}`}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}</td>
                                <td>--</td>
                                <td>--</td>
                                <td>--</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </div>
        )
    }

    const getClubStyle = (club) => {
        return {
            color: (state[`${club}`]?.strokesGainedShot < constants.sgPerShotLower || state[`${club}`]?.strokesGainedShot > constants.sgPerShotUpper) && 'white',
            backgroundColor:
                state[`${club}`]?.strokesGainedShot < constants.sgPerShotLower
                    ? red
                    : state[`${club}`]?.strokesGainedShot > constants.sgPerShotUpper
                        ? green
                        : '',
        };
    };
    /**
     * Renders table for clubs stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderClubs() {
        return (
            <div className="table-responsive">
                <Table id='clubstable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Per Round</th>
                            <th>Attempts</th>
                            <th>Miss Left</th>
                            <th>Miss Right</th>
                            <th>Miss Long</th>
                            <th>Miss Short</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clubsApp.map((club) => (
                            <tr style={{ display: state[`${club}`]?.attempts > 0 ? "" : "none", }}>
                                <td>{club.charAt(0) === "_" ? `${club.substring(1)}` : ` ${club.toUpperCase()}`}</td>
                                <td style={getClubStyle(club)} className={`${data === null && 'loading-cell '} data-cell`}>{state[`${club}`]?.strokesGainedShot}</td>
                                {(data && data.noOfRounds > 1) ? <td style={getClubStyle(club)}>{data && state[`${club}`]?.attempts !== 0 && parseFloat((state[`${club}`]?.strokesGainedShot / state[`${club}`]?.attempts) * (state[`${club}`]?.attempts / data.noOfRounds)).toFixed(3)}</td> : <td>--</td>}
                                <td>{state[`${club}`]?.attempts}</td>
                                <td style={{
                                    color: (state[`${club}`]?.missLeft > state[`${club}`]?.missRight) && 'white',
                                    backgroundColor: (state[`${club}`]?.missLeft > state[`${club}`]?.missRight) && red
                                }}>{state[`${club}`]?.missLeft}%</td>
                                <td style={{
                                    color: (state[`${club}`]?.missLeft < state[`${club}`]?.missRight) && 'white',
                                    backgroundColor: (state[`${club}`]?.missLeft < state[`${club}`]?.missRight) && red
                                }}>{state[`${club}`]?.missRight}%</td>
                                <td style={{
                                    color: (state[`${club}`]?.missLong > state[`${club}`]?.missShort) && 'white',
                                    backgroundColor: (state[`${club}`]?.missLong > state[`${club}`]?.missShort) && red
                                }}>{state[`${club}`]?.missLong}%</td>
                                <td style={{
                                    color: (state[`${club}`]?.missLong < state[`${club}`]?.missShort) && 'white',
                                    backgroundColor: (state[`${club}`]?.missLong < state[`${club}`]?.missShort) && red
                                }}>{state[`${club}`]?.missShort}%</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </div >
        )
    }
    function renderARGStrokesGained() {
        return (
            <div className="table-responsive">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                <Table id='argtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att. </th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {argSG.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}Attempts`] > 0) &&
                                        (stat.name === "sgARGShot" ? state[`sgARG`] > constants.tourAvg[`sgARG`].greenValue ||
                                            state[`sgARG`] < constants.tourAvg[`sgARG`].redValue
                                            : state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ||
                                            state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue) && 'white',
                                    backgroundColor: (state[`${stat.name}Attempts`] > 0) &&
                                        (stat.name === "sgARGShot" ? state[`sgARG`] > constants.tourAvg[`sgARG`].greenValue ? green :
                                            state[`sgARG`] < constants.tourAvg[`sgARG`].redValue ? red : ''
                                            : state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ? green :
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue ? red : '')
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat(state[`${stat.name}`] * state[`${stat.name}Attempts`]).toFixed(3)}</td>
                                <td>{state[`${stat.name}Attempts`]}</td>
                                <td>{state[`${stat.name}`]}</td>
                                <td>{parseFloat(state[`${stat.name}`] * state[`${stat.name}Attempts`] / state[`noOfRounds`]).toFixed(3)}</td>
                            </tr>
                        ))}


                    </tbody>
                </Table>
            </div >
        )
    }
    /**
     * Renders table for around the green stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderARG() {
        return (
            <div>
                {renderARGStrokesGained()}
                <div className="table-responsive">
                    <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                    <Table id='argtable' className='stats-table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Value</th>
                                <th>Count</th>
                                <th>Attempts</th>
                                <th>Tour Avg.</th>
                            </tr>
                        </thead>
                        <tbody>

                            <tr key={`expectedARGMiss`}>
                                <td>Expected Strokes when green missed*</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`expected`][`averageMissed`]}</td>
                                <td>--</td>
                                <td>{state[`expected`][`attemptsMissed`]}</td>
                                <td></td>
                            </tr>
                            {argStats.map((stat) => (
                                <tr key={`${stat.name}`}
                                    style={{
                                        color: (state[`${stat.name}Attempts`] > 0) &&
                                            (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ||
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue) && 'white',
                                        backgroundColor: (state[`${stat.name}Attempts`] > 0) &&
                                            (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ? green :
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue ? red : '')
                                    }}>
                                    <td>{`${stat.display}`}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}%</td>
                                    <td>{state[`${stat.name}Success`]}</td>
                                    <td>{state[`${stat.name}Attempts`]}</td>
                                    <td>{constants.tourAvg[`${stat.name}`]?.value}</td>
                                </tr>
                            ))}

                        </tbody>
                    </Table>
                </div >
            </div>
        )
    }

    function renderPuttStrokesGained() {

        return (
            <div className="table-responsive">
                <Table id='puttingtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Total</th>
                            <th>Att.</th>
                            <th>Per Shot</th>
                            <th>Per Round</th>
                        </tr>

                    </thead>
                    <tbody>
                        <tr key={`sgputting`}
                            style={{
                                color:
                                    (state[`sgPutting`] > constants.tourAvg[`sgPutting`].greenValue ||
                                        state[`sgPutting`] < constants.tourAvg[`sgPutting`].redValue) && 'white',
                                backgroundColor:
                                    (state[`sgPutting`] > constants.tourAvg[`sgPutting`].greenValue ? green :
                                        state[`sgPutting`] < constants.tourAvg[`sgPutting`].redValue ? red : '')
                            }}>
                            <td>{`SG per round`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat(state[`sgPuttingShot`] * state[`attemptssgPuttingShot`]).toFixed(2)}</td>
                            <td>{state['attemptssgPuttingShot']}</td>
                            <td>{state[`sgPuttingShot`]}</td>
                            <td>{state[`sgPutting`]}</td>
                        </tr>
                        {sgPutt.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`attempts${stat.name}`] > 0) &&
                                        (state[`sgPutting${stat.name}`] / state[`attempts${stat.name}`] > constants.sgPerShotUpper ||
                                            state[`sgPutting${stat.name}`] / state[`attempts${stat.name}`] < constants.sgPerShotLower) && 'white',

                                    backgroundColor: (state[`attempts${stat.name}`] > 0) &&
                                        (state[`sgPutting${stat.name}`] / state[`attempts${stat.name}`] > constants.sgPerShotUpper ? green :
                                            state[`sgPutting${stat.name}`] / state[`attempts${stat.name}`] < constants.sgPerShotLower ? red : '')
                                }} >
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat(state[`sgPutting${stat.name}`]).toFixed(2)}</td>
                                <td>{state[`attempts${stat.name}`]}</td>
                                <td>{parseFloat(state[`sgPutting${stat.name}`] / state[`attempts${stat.name}`]).toFixed(2)}</td>
                                <td>{parseFloat(state[`sgPutting${stat.name}`] / state[`noOfRounds`]).toFixed(2)}</td>
                            </tr>
                        ))}
                        <tr key={`sgPuttingLagOver30Shot`}
                            style={{
                                color:
                                    (state[`sgPuttingLagOver30Shot`] > constants.sgPerShotUpper ||
                                        state[`sgPuttingLagOver30Shot`] < constants.sgPerShotLower) && 'white',
                                backgroundColor:
                                    (state[`sgPuttingLagOver30Shot`] > constants.sgPerShotUpper ? green :
                                        state[`sgPuttingLagOver30Shot`] < constants.sgPerShotLower ? red : '')
                            }}>
                            <td>{`SG first putt 30'+`}</td>
                            <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`sgPuttingLagOver30`]}</td>
                            <td>{state[`attempts30`]}</td>
                            <td>{state[`sgPuttingLagOver30Shot`]}</td>
                            <td>{parseFloat(state[`sgPuttingLagOver30`] / state[`noOfRounds`]).toFixed(2)}</td>
                        </tr>
                        {puttingSG.map((stat) => (
                            <tr key={`${stat.name}`}
                                /* style={{
                                    color: (state[`attempts${stat.name}`] > 0) &&
                                        (state[`sg${stat.name}`] > constants.sgPerShotUpper ||
                                        state[`sg${stat.name}`] < constants.sgPerShotLower) && 'white',

                                    backgroundColor: (state[`attempts${stat.name}`] > 0) &&
                                        (state[`sg${stat.name}`] > constants.sgPerShotUpper ? green :
                                        state[`sg${stat.name}`] < constants.sgPerShotLower ? red : '')
                                }} */>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{parseFloat(state['sgPutt'][`sg${stat.name}Total`]).toFixed(2)}</td>
                                <td>{state['sgPutt'][`attempts${stat.name}`]}</td>
                                <td>{state['sgPutt'][`sg${stat.name}`]}</td>
                                <td>{parseFloat(state['sgPutt'][`sg${stat.name}Total`] / state[`noOfRounds`]).toFixed(2)}</td>
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        )
    }
    /**
     * Renders table for putting stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderPutt() {
        return (
            <div>
                {renderPuttStrokesGained()}
                <div className="table-responsive">
                    <Table id='puttingtable' className='stats-table'>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Value</th>
                                <th>Count</th>
                                <th>Attempts</th>
                                <th>Tour average</th>
                            </tr>
                        </thead>
                        <tbody>
                            {puttingMake.map((stat) => (
                                <tr key={`${stat.name}`}
                                    style={{
                                        color: (state[`attempts${stat.name}`] > 0) &&
                                            (state[`makeRate${stat.name}`] > constants.tourAvg[`makeRate${stat.name}`].greenValue ||
                                                state[`makeRate${stat.name}`] < constants.tourAvg[`makeRate${stat.name}`].redValue) && 'white',

                                        backgroundColor: (state[`attempts${stat.name}`] > 0) &&

                                            (state[`makeRate${stat.name}`] > constants.tourAvg[`makeRate${stat.name}`].greenValue ? green :
                                                state[`makeRate${stat.name}`] < constants.tourAvg[`makeRate${stat.name}`].redValue ? red : '')
                                    }}>
                                    <td>{`${stat.display}`}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`makeRate${stat.name}`]}%</td>
                                    <td>{state[`success${stat.name}`]}</td>
                                    <td>{state[`attempts${stat.name}`]}</td>
                                    <td>{constants.tourAvg[`makeRate${stat.name}`]?.value}</td>
                                </tr>
                            ))}

                            {puttingFeet.map((stat) => (
                                <tr key={`${stat.name}`}
                                    style={{
                                        color: (state[`${stat.name}`]) &&
                                            (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ||
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue) && 'white',
                                        backgroundColor: (state[`${stat.name}`]) &&
                                            (state[`${stat.name}`] > constants.tourAvg[`${stat.name}`].greenValue ? green :
                                                state[`${stat.name}`] < constants.tourAvg[`${stat.name}`].redValue ? red : '')
                                    }}>
                                    <td>{`${stat.display}`}</td>
                                    <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}'</td>
                                    <td>--</td>
                                    <td>--</td>
                                    <td>{constants.tourAvg[`${stat.name}`]?.value}</td>
                                </tr>
                            ))}
                            {puttingMissDst.map((dst) => (
                                puttingMisses.map((miss) => (
                                    <tr key={`${miss}${dst.name}`}>
                                        <td>{`${miss} ${dst.display}`}</td>
                                        <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${miss.toLowerCase()}${dst.name}`]}%</td>
                                        <td>{state[`count${miss}${dst.name}`]}</td>
                                        <td>{state[`attempts${dst.name}`]}</td>
                                        <td>--</td>
                                    </tr>
                                ))
                            ))}
                        </tbody>

                    </Table>
                </div>
            </div>
        )
    }
    /**
     * Renders table for off the tee stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderTee() {
        return (
            <div className="table-responsive">
                <Table id='teetable' className='stats-table'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Value</th>
                            <th>Count</th>
                            <th>Attempts</th>
                            <th>Tour average</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            <tr>
                                <td>SG per round</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state['sgOTT']}</td>
                                <td>--</td>
                                <td>{state[`teeAttempts`]}</td>
                                <td>{constants.tourAvg[`sgOTT`]?.value}</td>
                            </tr>
                        }
                        {teeStats.map((stat) => (
                            <tr key={`${stat.name}`}
                                style={{
                                    color: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                        (constants.tourAvg[`${stat.name}Hit`].low ?
                                            (state[`${stat.name}Hit`] < constants.tourAvg[`${stat.name}Hit`].greenValue ||
                                                state[`${stat.name}Hit`] > constants.tourAvg[`${stat.name}Hit`].redValue) && 'white' :
                                            (state[`${stat.name}Hit`] > constants.tourAvg[`${stat.name}Hit`].greenValue ||
                                                state[`${stat.name}Hit`] < constants.tourAvg[`${stat.name}Hit`].redValue) && 'white'),

                                    backgroundColor: (state[`${stat.name}Attempts`] > 0 || stat.name === "double") &&
                                        (constants.tourAvg[`${stat.name}Hit`].low === true ?
                                            (state[`${stat.name}Hit`] < constants.tourAvg[`${stat.name}Hit`].greenValue ? green :
                                                state[`${stat.name}Hit`] > constants.tourAvg[`${stat.name}Hit`].redValue ? red : '')
                                            :
                                            (state[`${stat.name}Hit`] > constants.tourAvg[`${stat.name}Hit`].greenValue ? green :
                                                state[`${stat.name}Hit`] < constants.tourAvg[`${stat.name}Hit`].redValue ? red : ''))
                                }}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}Hit`]}%</td>
                                <td>{state[`${stat.name}Count`]}</td>
                                <td>{state[`${stat.name}Attempts`]}</td>
                                <td>{constants.tourAvg[`${stat.name}Hit`]?.value}</td>
                            </tr>
                        ))}
                        {clubsTee.map((stat) => (
                            <tr key={`${stat}`}
                                style={{
                                    display: state[`tee${stat}Attempts`] > 0 ? '' : 'none'
                                }}>
                                <td>{`${stat}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`fairway${stat}Hit`]}%</td>
                                <td>{state[`tee${stat}Success`]}</td>
                                <td>{state[`tee${stat}Attempts`]}</td>
                                <td>--</td>
                            </tr>
                        ))}
                        {teeMiss.map((stat) => (
                            <tr key={`${stat.name}`}>
                                <td>{`${stat.display}`}</td>
                                <td className={`${data === null && 'loading-cell '} data-cell`}>{state[`${stat.name}`]}%</td>
                                <td></td>
                                <td></td>
                                <td></td>
                            </tr>
                        ))}

                    </tbody>

                </Table>
            </div>
        )
    }

    /**
     * Renders table for mistakes stats with a option to change row colors based on 
     * cell values
     * @returns Table
     */
    function renderMistake() {
        return (
            <div className="mistake-container">
                {mistakesOptions.map((option) => (
                    <Table id={`mistaketable${option}`} className='mistake-table'>
                        <thead>
                            <tr>
                                <th>{option}</th>
                                <th>Value</th>
                            </tr>
                        </thead>
                        <tbody>{mistakes.map((mistake) => (
                            <tr>
                                <td>{mistake.display}</td>
                                <td className='data-cell'>{state[`${mistake.name}${option}`]}%</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ))}
            </div>
        )
    }
    function openAll() {
        if (activeTabs.length > 0) {
            setActiveTabs([])
        } else {
            setActiveTabs([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17])
        }
    }
    return (
        < div >
            <>
                <Container className="ps-0">
                    {baseline === "LPGA" &&
                        <Message className='m-3' severity="info" text="*LPGA average/baseline" />}
                    {data && <Message className='m-3' severity="info" text={`${data.noOfRounds} round${data.noOfRounds === 1 ? "" : "s"}`} />}
                    <Row className="mt-3 mb-5">
                        <Col>
                            <Button label={activeTabs.length > 0 ? "Close All" : "Open All"}
                                icon={activeTabs.length > 0 ? "pi pi-minus" : "pi pi-plus"}
                                className="mb-4" onClick={() => openAll()} />
                            <Accordion activeIndex={activeTabs} multiple onTabChange={(e) => setActiveTabs(e.index)}>
                                <AccordionTab header="5 Strengths">
                                    {renderStrengths()}
                                </AccordionTab>
                                <AccordionTab header="5 Elements to Improve">
                                    {renderAnalysis()}
                                </AccordionTab>
                                <AccordionTab header="Tiger 5">
                                    {renderTiger5()}
                                </AccordionTab>
                                <AccordionTab header="Scoring">
                                    {renderScoring()}
                                </AccordionTab>
                                <AccordionTab header="Off the Tee">
                                    {renderTee()}
                                </AccordionTab>
                                <AccordionTab header="Approaches">
                                    {renderApproaches()}
                                </AccordionTab>
                                <AccordionTab header="Advanced Approaches (beta)">
                                    {renderAdvancedApproaches()}
                                </AccordionTab>
                                <AccordionTab header="Clubs">
                                    {renderClubs()}
                                </AccordionTab>
                                <AccordionTab header="Going for It">
                                    {renderGoing()}
                                </AccordionTab>
                                <AccordionTab header="Around the Green">
                                    {renderARG()}
                                </AccordionTab>
                                <AccordionTab header="Putting">
                                    {renderPutt()}
                                </AccordionTab>
                                <AccordionTab header="Mistakes" >
                                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
                                        {renderMistake()}
                                    </div>
                                </AccordionTab >
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </>
        </div >
    );
}

export { IndividualStats }