import { React, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from 'react-router-dom';
import { NavLink } from "reactstrap";


const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};

const ShowTeamRounds = () => {
    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        if (isAuthenticated) {
            setUserRoles(user["https://parfectperformance.com/role"] || []);
            if (userRoles.length > 0) {
                //console.log("The user roles are " + userRoles)
            }
            //console.log("The user roles are" + userRoles) 
        }
    }, [getIdTokenClaims, isAuthenticated, userRoles]);



    return (
        <>
            {isAuthenticated && userRoles.includes("Coach") && (
                <NavLink
                    onClick={() => this.setState({ collapsed: true })}
                    tag={Link}
                    to="/team-rounds"
                    style={{ color: 'black' }}
                >
                    Individual Rounds
                </NavLink>
            )}
        </>
    );
};

export { ShowTeamRounds };

