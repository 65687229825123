import React, { useState, useEffect } from "react";
import {
  Container,
  Row, Col
} from "reactstrap";
import "../css/new-round.css";
import { AddRound } from "./AddRound";
import { useAuth0 } from "@auth0/auth0-react";
import { RoundTypeDropdown } from './dropdowns/RoundTypeDropdown'
import { DateInput } from './DateInput'
import { Dropdown } from 'primereact/dropdown'
import { Button } from "primereact/button";
import { ProgressSpinner } from 'primereact/progressspinner';
export function CreateRound() {
  const { user } = useAuth0();
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  const userId = user.sub.substring(user.sub.indexOf('|') + 1);
  /**Round Id from the databse */
  const [roundId, setRoundId] = useState(null);
  /**Selected round type */
  var [roundType, setRoundType] = useState("");
  /**Was tournament/qualifying selected */
  var [tournamentSelected, setTournamentSelected] = useState(true);
  /**Is add round visible */
  const [visible, setVisibility] = useState(true);
  /**Are all inputs provided allowing to go to next page */
  var [validInput, setValidInput] = useState(false);
  /**Selected date of the round */
  const [selectedDate, setSelectedDate] = useState(new Date());
  /**Selected course for the round */
  var [course, setCourse] = useState("Course");
  /**Was the course selected */
  var [courseSelected, setCourseSelected] = useState(false);
  /**Selected tee */
  var [tee, setTee] = useState("Tee")
  /**Possible round types */
  const roundTypes = ["Tournament", "Practice", "Qualifying"];

  var [courseSelect, setCourseSelect] = useState(null)
  var [teeSelect, setTeeSelect] = useState(null)
  const [courseList, setCourseList] = useState([]);
  const [userBaseline, setUserBaseline] = useState(null);
  const [userUnits, setUserUnits] = useState(null);
  const [userInfoLoaded, setUserInfoLoaded] = useState(true);//change to false if not in localstorage and have to load
  /**
   * Posts the round into the databse
   */
  async function fetchRound() {
    fetch('/api/round', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        userId: userId, type: roundType, date: new Date(selectedDate.toISOString().substring(0, 10)), course: course.courseName, courseId: course.id,
        teeBox: tee.teeName, tournamentId: "123", shots: [], baseline: userBaseline
      })
    })
      .then(response => response.json())
      .then(data => {
        setRoundId(data.id)
      })
      .catch(error => console.error(error));
  }

  useEffect(() => {

    async function fetchCourses() {
      await fetch("/api/course")
        .then((response) => response.json())
        .then((data) => {
          setCourseList(data);
          setWasDataLoaded(true);
        })
        .catch(error => console.error(error));
    }
    fetchCourses();
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:myself'
          },
        });

        const response = await fetch(`api/User/myself?userId=${user.sub}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();
        setUserBaseline(responseData.baseline);
        setUserUnits(responseData.units);
        setUserInfoLoaded(true);
        window.localStorage.setItem("units", responseData.units);
        window.localStorage.setItem("baseline", responseData.baseline);
      } catch (error) {
        console.log(error.error)
        if (error.error === 'login_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          });
        } else if (error.error === 'consent_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          }, {
            appState: { targetUrl: window.location.href }
          });
        } else {
          console.error(error);
        }
      }
    }
    var unitsLocal = window.localStorage.getItem("units");
    var baselineLocal = window.localStorage.getItem("baseline");
    if (unitsLocal && baselineLocal) {
      setUserBaseline(baselineLocal);
      setUserUnits(unitsLocal);
      setUserInfoLoaded(true);
    } else if (userBaseline && userUnits) {
      setUserInfoLoaded(true);
    } else {
      setUserInfoLoaded(false);
      fetchUser();
    }
  }, []);



  /**
   * changes visibility of the next page and
   * posts the round to the database.
   */
  function changeVisibility() {
    setVisibility(!visible);
    fetchRound();
  }
  /**
   * Checks if course was selected, tee, date and round type
   */
  useEffect(() => {
    if (courseSelect && teeSelect && selectedDate && roundType && userBaseline) {
      setValidInput(true);
    }

  }, [courseSelect, teeSelect, selectedDate, roundType, userBaseline]);


  function changeCourse(event) {
    setCourse(event.target.value);
    setCourseSelect(event.target.value);
    setCourseSelected(true);
  }

  function changeTee(event) {
    setTee(event.target.value);
    setTeeSelect(event.target.value);
  }

  /**
   * Renders tee sets to pick from
   */
  const selectedTeeTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center"><div>{option.teeName}</div>
        </div>
      );
    }
    return <span>{props.placeholder}</span>;
  };

  const teeOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center"><div>{option.teeName}</div>
      </div>
    );
  };
  function renderTeeSet() {
    return (
      <div>
        <Dropdown value={tee} onChange={(event) => changeTee(event)} options={courseSelect.teeSet} optionLabel="name" placeholder="Select a Teebox"
          valueTemplate={selectedTeeTemplate} itemTemplate={teeOptionTemplate} className="w-full md:w-14rem" />

      </div>
    )
  }

  const selectedCourseTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center"> <div>{option.courseName}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const courseOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <div>{option.courseName}</div>
      </div>
    );
  };
  return (
    <div>
      {visible && (
        <div>
          <h1>Create New Round</h1>
          <Container className="ps-0">
            <Row className="m-3">
              <Col xs="6" md="2" lg="2">
                <RoundTypeDropdown setTournamentSelected={setTournamentSelected} setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} />
              </Col>
              <Col xs="6" md="2" lg="2">
                {/* {tournamentSelected && <TournamentDropdown />} */}
              </Col>
            </Row>
            <Row className="m-3">
              <Col xs="6" md="6" lg="3">
                <DateInput setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
              </Col>

            </Row>
            <Row className="m-3">
              <Col className="mb-3 mb-md-0" xs="12" md="5" lg="4">
                <Dropdown
                  id="course"
                  value={course}
                  onChange={
                    (event) => changeCourse(event)
                  }
                  options={courseList}
                  optionLabel="courseName"
                  placeholder={wasDataLoaded ? "Select a Course" : "Loading"}
                  dropdownIcon={wasDataLoaded ? "pi pi-chevron-down" : "pi pi-spinner"}
                  filter
                  filterPlaceholder="Search course"
                  valueTemplate={selectedCourseTemplate}
                  itemTemplate={courseOptionTemplate}
                  className="w-full md:w-14rem" />
              </Col>
              <Col xs="4" md="3" lg="3">
                {courseSelected && renderTeeSet()}
              </Col>
            </Row>
            {!userInfoLoaded && <Row className="m-4">
              <Col>
                <p>Loading user data</p>
                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />
              </Col>
            </Row>}
            <Row className="m-4">
              <Col>
                {/* <Button className="mt-3" disabled={!validInput} onClick={changeVisibility}>Start</Button> */}
                <Button label="Start" className="mt-1" disabled={!validInput} onClick={changeVisibility} />
              </Col>
            </Row>


          </Container>
        </div>
      )}
      {!visible && (
        <AddRound
          roundId={roundId}
          teeSelect={teeSelect}
          userBaseline={userBaseline}
          units={userUnits}
        />
      )}
    </div>
  );
}
