import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { RoundTypeDropdown } from "../dropdowns/RoundTypeDropdown";
import { DurationDropdown } from "../dropdowns/DurationDropdown";
import { Button } from "primereact/button";
import { Table } from "reactstrap";
import { Message } from 'primereact/message';
import { useGames } from "../../GamesProvider";
import { calculateTime } from "./PracticeFunctions";
import { getRoundsCallbackList, getRoundsSemesterCallbackList } from "../StatsAPICall";
import { MultiSelectPlayers } from "../dropdowns/MultiSelectPlayers";
import { MultiSelectGames } from "../dropdowns/MultiSelectGames";
const GroupPlanner = (props) => {
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);
    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers, allUserAccounts])
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [elements, setElements] = useState(1);
    const [roundType, setRoundType] = useState("All");
    const [numberOfRounds, setNumberOfRounds] = useState(10);
    const [timeDuration, setTimeDuration] = useState(0);
    const [durationType, setDurationType] = useState("number");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [custom, setCustom] = useState(false);
    const [stats, setStats] = useState(null);
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Fall", "Spring",];
    const options = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement', 'Time'];
    const practiceOptions = ['Name', 'Total', 'Time'];
    const analysisOptions = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement'];

    const gamesConst = useGames();
    const [selectedGames, setSelectedGames] = useState({});
    const [state, setState] = useState(() => {
        const initialState = {};
        for (var i = 1; i < 8; i++) {
            options.forEach((option) => {
                initialState[`analysis${i}${option}`] = '';
                initialState[`strength${i}${option}`] = '';
            })
        }
        return initialState;
    })
    const [numberImprovement, setNumberImprovement] = useState(2);
    const [numberStrength, setNumberStrength] = useState(1);
    const [units, setUnits] = useState("yards")

    const [loadingButton, setLoadningButton] = useState(false)
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"

    const [coachTeam, setCoachTeam] = useState(null)
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:myself'
                    },
                });

                const response = await fetch(`api/User/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response.json())
                const responseData = await response.json();
                window.localStorage.setItem("team", responseData.team);
                if (responseData.bag !== null) {
                    window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
                }
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        var team = window.localStorage.getItem("team");
        if (team) {
            setCoachTeam(team);
        } else {
            fetchUser();
        }
    }, [])
    useEffect(() => {
        switch (elements) {
            case 3:
                setNumberImprovement(2);
                setNumberStrength(1);
                break;
            case 4:
                setNumberImprovement(3);
                setNumberStrength(1);
                break;
            case 5:
                setNumberImprovement(3);
                setNumberStrength(2);
                break;
            case 6:
                setNumberImprovement(4);
                setNumberStrength(2);
                break;
            case 7:
                setNumberImprovement(5);
                setNumberStrength(2);
                break;
            case 8:
                setNumberImprovement(5);
                setNumberStrength(3);
                break;
            case 9:
                setNumberImprovement(6);
                setNumberStrength(3);
                break;
            case 10:
                setNumberImprovement(7);
                setNumberStrength(3);
                break;
        }
    }, [elements])

    function fillAnalysis(data) {
        // Define the variables and their values
        var variables = [
            { name: "PUTT 3'", value: data.sgPutting3, attempts: data.attempts3, perShot: data.sgPutting3 / data.attempts3, improvement: (data.sgPutting3 / data.attempts3) * (data.attempts3 / data.noOfRounds), time: 0 },
            { name: "PUTT 3-5'", value: data.sgPutting35, attempts: data.attempts35, perShot: data.sgPutting35 / data.attempts35, improvement: (data.sgPutting35 / data.attempts35) * (data.attempts35 / data.noOfRounds), time: 0 },
            { name: "PUTT 5-10'", value: data.sgPutting510, attempts: data.attempts510, perShot: data.sgPutting510 / data.attempts510, improvement: (data.sgPutting510 / data.attempts510) * (data.attempts510 / data.noOfRounds), time: 0 },
            { name: "PUTT 10-20'", value: data.sgPutting1020, attempts: data.attempts1020, perShot: data.sgPutting1020 / data.attempts1020, improvement: (data.sgPutting1020 / data.attempts1020) * (data.attempts1020 / data.noOfRounds), time: 0 },
            { name: "PUTT 20-30'", value: data.sgPutting2030, attempts: data.attempts2030, perShot: data.sgPutting2030 / data.attempts2030, improvement: (data.sgPutting2030 / data.attempts2030) * (data.attempts2030 / data.noOfRounds) },
            { name: "Lag Putting 30'+", value: data.sgPuttingLagOver30, attempts: data.attempts30, perShot: data.sgPuttingLagOver30 / data.attempts30, improvement: (data.sgPuttingLagOver30 / data.attempts30) * (data.attempts30 / data.noOfRounds) },
            { name: `ARG ${units === "meters" ? "0-27m" : "0-30y"}`, value: data.sgARG030 * data.sgARG030Attempts, attempts: data.sgARG030Attempts, perShot: data.sgARG030, improvement: (data.sgARG030) * (data.sgARG030Attempts / data.noOfRounds), time: 0 },
            { name: `ARG ${units === "meters" ? "27-46m" : "30-50y"}`, value: data.sgARG3050 * data.sgARG3050Attempts, attempts: data.sgARG3050Attempts, perShot: data.sgARG3050, improvement: (data.sgARG3050) * (data.sgARG3050Attempts / data.noOfRounds), time: 0 },
            { name: 'ARG Sand', value: data.sgARGSand * data.sgARGSandAttempts, attempts: data.sgARGSandAttempts, perShot: data.sgARGSand, improvement: (data.sgARGSand) * (data.sgARGSandAttempts / data.noOfRounds), time: 0 },
/*             { name: 'ARG Fringe', value: data.sgARGFringe * data.sgARGFringeAttempts, attempts: data.sgARGFringeAttempts, perShot: data.sgARGFringe, improvement: (data.sgARGFringe) * (data.sgARGFringeAttempts / data.noOfRounds), time: 0 },
 */            { name: `APP ${units === "meters" ? "46-91m" : "50-100y"}`, value: data.sg50100 * data.sg50100Attempts, attempts: data.sg50100Attempts, perShot: data.sg50100, improvement: (data.sg50100) * (data.sg50100Attempts / data.noOfRounds), time: 0 },
            { name: `APP ${units === "meters" ? "91-137m" : "100-150y"}`, value: data.sg100150 * data.sg100150Attempts, attempts: data.sg100150Attempts, perShot: data.sg100150, improvement: (data.sg100150) * (data.sg100150Attempts / data.noOfRounds), time: 0 },
            { name: `APP ${units === "meters" ? "137-183m" : "150-200y"}`, value: data.sg150200 * data.sg150200Attempts, attempts: data.sg150200Attempts, perShot: data.sg150200, improvement: (data.sg150200) * (data.sg150200Attempts / data.noOfRounds), time: 0 },
            { name: `APP ${units === "meters" ? "183+m" : "200+y"}`, value: data.sg200 * data.sg200Attempts, attempts: data.sg200Attempts, perShot: data.sg200, improvement: (data.sg200) * (data.sg200Attempts / data.noOfRounds), time: 0 },
            { name: 'Tee Shots', value: data.sgOTTTotal, attempts: data.teeAttempts, perShot: data.sgOTTTotal / data.teeAttempts, improvement: (data.sgOTTTotal / data.teeAttempts) * (data.teeAttempts / data.noOfRounds), time: 0 }
        ];

        // Sort the variables based on their values in ascending order
        variables.sort(function (a, b) {
            return a.value - b.value;
        });

        // Get the first five variables with the lowest values
        var numberOfBad = numberImprovement < 5 ? 5 : numberImprovement
        var lowestVariables = variables.slice(0, numberOfBad);

        for (let i = 0; i < numberOfBad; i++) {
            setState((prev) => ({
                ...prev,
                [`analysis${i + 1}Name`]: lowestVariables[i].name,
                [`analysis${i + 1}Total`]: parseFloat(lowestVariables[i].value).toFixed(2),
                [`analysis${i + 1}Attempts`]: lowestVariables[i].attempts,
                [`analysis${i + 1}PerShot`]: parseFloat(lowestVariables[i].perShot).toFixed(3),
                [`analysis${i + 1}Improvement`]: parseFloat(lowestVariables[i].improvement).toFixed(2),
            }));
        }
        let sum = 0.0;
        for (var i = 0; i < 5; i++) {
            if (lowestVariables[i].name !== "ARG Sand" && lowestVariables[i].name !== 'ARG Fringe') {
                sum += lowestVariables[i].improvement;
            }
        }

        var numberOfGood = numberStrength < 5 ? 5 : numberStrength
        // Get the last five variables with the lowest values
        var highestVariables = variables.slice(variables.length - numberOfGood, variables.length);


        for (let i = 0; i < numberOfGood; i++) {
            setState((prev) => ({
                ...prev,
                [`strength${i + 1}Name`]: highestVariables[4 - i].name,
                [`strength${i + 1}Total`]: parseFloat(highestVariables[4 - i].value).toFixed(2),
                [`strength${i + 1}Attempts`]: highestVariables[4 - i].attempts,
                [`strength${i + 1}PerShot`]: parseFloat(highestVariables[4 - i].perShot).toFixed(3),
                [`strength${i + 1}Improvement`]: parseFloat(highestVariables[4 - i].improvement).toFixed(2),
            }));
        }
        calculateTime(variables, numberImprovement, numberStrength, totalMinutes, setState);
    }
    function renderStrengths() {
        return (
            <div className="table-responsive">
                <Table id='strengthtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Total SG</th>
                            <th>Att.</th>
                            <th>SG per Shot</th>
                            <th>Avg. SG per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <tr style={{
                                color: 'white',
                                backgroundColor: green
                            }}>
                                <td className='stats-title'>{index + 1}</td>
                                {analysisOptions.map((option) => (
                                    <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        )

    }
    function renderAnalysis() {
        return (
            <div className="table-responsive">
                <Table id='analysistable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Total SG</th>
                            <th>Att.</th>
                            <th>SG per Shot</th>
                            <th>Avg. SG per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <tr style={{
                                color: 'white',
                                backgroundColor: red
                            }}>
                                <td className='stats-title'>{index + 1}</td>
                                {analysisOptions.map((option) => (
                                    <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        )
    }

    async function getRounds() {
        setLoadningButton(true);
        if (durationType.toLowerCase() === "fall") {
            getRoundsSemesterCallbackList(
                displayedPlayers,
                "2023-08-25",
                "2023-10-29",
                roundType,
                (data) => { setStats(data); setLoadningButton(false) }
            )
        } else if (durationType.toLowerCase() === "spring") {
            getRoundsSemesterCallbackList(
                displayedPlayers,
                "2023-02-10",
                "2023-05-01",
                roundType,
                (data) => { setStats(data); setLoadningButton(false) }
            )
        } else {
            getRoundsCallbackList(
                displayedPlayers,
                durationType,
                timeDuration,
                numberOfRounds,
                roundType, (data) => { setStats(data); setLoadningButton(false) }
            )
        }
    }


    useEffect(() => {
        if (stats !== null)
            fillAnalysis(stats);
    }, [stats])


    useEffect(() => {
        setTotalMinutes(hours * 60 + minutes)
    }, [hours, minutes])

    function sendEmail(players) {
        var emails = []
        players.forEach((p) => {
            emails.push(p.email)
        })
        var subject = `Team Practice`
        var time = ""
        for (var i = 1; i <= numberStrength; i++) {
            time += `${state[`strength${i}Name`]}: ${state[`strength${i}Time`]}min%0D%0A`
        }
        time += "%0D%0A"
        for (var i = 1; i <= numberImprovement; i++) {
            time += `${state[`analysis${i}Name`]}: ${state[`analysis${i}Time`]}min%0D%0A`
        }
        time += "%0D%0A"
        var puttingGames = selectedGames.puttingGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var argGames = selectedGames.argGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var appGames = selectedGames.appGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var teeGames = selectedGames.teeGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var combineGames = selectedGames.combineGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";

        var body = `Time:%0D%0A${time}%0D%0A Putting games:%0D%0A${puttingGames}%0D%0A Arg games:%0D%0A${argGames}%0D%0A Approach games:%0D%0A${appGames}%0D%0A Tee games:%0D%0A${teeGames}%0D%0A Combines:%0D%0A${combineGames}`
        window.open(`mailto:${emails}?subject=${subject} &body=${body}`, '_blank')
    }

    return (
        <div>
            <Card className="mt-2 mb-2" title="Plan your day (beta)" >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div>
                        <label htmlFor="HoursInput">Hours</label>
                        <InputNumber className="m-2" inputId="HoursInput" value={hours} min={0} onValueChange={(e) => setHours(e.value)} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                    <div>
                        <label htmlFor="MinutesInput">Minutes</label>
                        <InputNumber className="m-2" inputId="MinutesInput" value={minutes} onValueChange={(e) => setMinutes(e.value)} showButtons buttonLayout="horizontal"
                            max={59} step={15} min={0}
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                    <div>
                        <label htmlFor="noElem">Number of Elements</label>
                        <InputNumber className="m-2" inputId="noElem" value={elements} min={3} max={10} onValueChange={(e) => setElements(e.value)} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                </div>
                <label htmlFor="dropdowns">Rounds for analysis</label>
                <div id="dropdowns">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="m-2">
                            <RoundTypeDropdown className="m-2" roundTypes={roundTypes} roundType={roundType} setRoundType={setRoundType} setTournamentSelected={() => console.log()} />
                        </div>
                        <div className="m-2">
                            <DurationDropdown className="m-2" durations={durations} setNumberOfRounds={setNumberOfRounds}
                                setDurationType={setDurationType} setTimeDuration={setTimeDuration} setCustom={setCustom} />
                        </div>
                    </div>
                </div>
                <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} durations={durations} coachTeam={coachTeam} />

                <Button loading={loadingButton} className="m-2" disabled={totalMinutes <= 0 && selectedUsers.length <= 0} label="Plan my practice"
                    onClick={() => getRounds()} />
            </Card >
            {
                <div>
                    <Card className="mt-2 mb-2">

                        {renderStrengths()}
                        {renderAnalysis()}
                    </Card>
                    <Card className="mt-2 mb-2" title="Suggested Practice">
                        {stats && <Message className='m-3' severity="info" text={`${stats.noOfRounds} round${stats.noOfRounds === 1 ? "" : "s"}`} />}

                        <div className="table-responsive">
                            <Table id='strengthtable' className='stats-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Total SG</th>
                                        <th>Time (min)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: numberImprovement }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: red
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {Array.from({ length: numberStrength }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: green
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                            <MultiSelectGames games={gamesConst.puttingGames} selectedGames={selectedGames.puttingGames || []}
                                setSelectedGames={(games) => {
                                    setSelectedGames((prev) => ({
                                        ...prev,
                                        puttingGames: games,
                                    }));
                                }} part="Putting" />
                            <MultiSelectGames games={gamesConst.argGames} selectedGames={selectedGames.argGames || []}
                                setSelectedGames={(games) => {
                                    setSelectedGames((prev) => ({
                                        ...prev,
                                        argGames: games,
                                    }));
                                }} part="Arg" />
                            <MultiSelectGames games={gamesConst.appGames} selectedGames={selectedGames.appGames || []}
                                setSelectedGames={(games) => {
                                    setSelectedGames((prev) => ({
                                        ...prev,
                                        appGames: games,
                                    }));
                                }} part="Approach" />
                            <MultiSelectGames games={gamesConst.teeGames} selectedGames={selectedGames.teeGames || []}
                                setSelectedGames={(games) => {
                                    setSelectedGames((prev) => ({
                                        ...prev,
                                        teeGames: games,
                                    }));
                                }} part="Tee" />
                            <MultiSelectGames games={gamesConst.combineGames} selectedGames={selectedGames.combineGames || []}
                                setSelectedGames={(games) => {
                                    setSelectedGames((prev) => ({
                                        ...prev,
                                        combineGames: games,
                                    }));
                                }} part="Combine" />
                            <div>
                                <label>Selected games:</label>
                                {selectedGames.puttingGames?.map((game) => (
                                    <li>{game.name}</li>
                                ))}
                                {selectedGames.argGames?.map((game) => (
                                    <li>{game.name}</li>
                                ))}
                                {selectedGames.appGames?.map((game) => (
                                    <li>{game.name}</li>
                                ))}
                                {selectedGames.teeGames?.map((game) => (
                                    <li>{game.name}</li>
                                ))}
                                {selectedGames.combineGames?.map((game) => (
                                    <li>{game.name}</li>
                                ))}
                            </div>
                            <Button label="Send" onClick={() => sendEmail(selectedUsers)} />

                        </div>
                    </Card></div>}
        </div >
    )

}
export { GroupPlanner }