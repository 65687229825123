import React, { useState } from "react";
import {
    Container, Row
} from "reactstrap";
import { PuttingPractice } from "./PuttingPractice";
import { NewPuttingPractice } from "./NewPuttingPractice";
import { PuttingPracticeResult } from "./PuttingPracticeResults";
import { ApproachPractice } from "./ApproachPractice";
import { NewApproachPractice } from "./NewApproachPractice";
import { ApproachPracticeResult } from "./ApproachPracticeResults";
import { TabMenu } from "primereact/tabmenu"
import { useAuth0 } from "@auth0/auth0-react";
import { Games } from "./Games";
import { Planner } from "./Planner";
const Practice = () => {

    const { user } = useAuth0();
    const userId = user.sub.substring(user.sub.indexOf('|') + 1);

    const [startNewPutting, setStartNewPutting] = useState(false);
    const [showPuttingResult, setShowPuttingResult] = useState(false);

    const [startNewApproach, setStartNewApproach] = useState(false);
    const [showApproachResult, setShowApproachResult] = useState(false);
    const [lowDistance, setLowDistance] = useState(null);
    const [highDistance, setHighDistance] = useState(null);

    const optionsMenu = [{ label: "Practice Planner", icon: "" }, { label: "Putting Combine", icon: "" },
    { label: "Putting Games", icon: "" }, { label: "Short Game Games", icon: "" },
    { label: "Approach", icon: "" }, { label: "Approach Games", icon: "" }, { label: "Tee Games", icon: "" }];
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedPractice, setSelectedPractice] = useState("Practice Planner")
    return (
        <Container >
            <Row >
                <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
                    <TabMenu model={optionsMenu} activeIndex={activeIndex} onTabChange={(e) => { setSelectedPractice(e.value.label); setActiveIndex(e.index) }} />
                </div>
            </Row>
            <Row>
                {selectedPractice === "Practice Planner" && <Planner />}
                {selectedPractice === "Putting Combine" && <PuttingPractice setStartNewPutting={setStartNewPutting} setShowPuttingResult={setShowPuttingResult} />}
                {selectedPractice === "Putting Combine" && startNewPutting && <NewPuttingPractice setStartNewPutting={setStartNewPutting} setShowPuttingResult={setShowPuttingResult} />}
                {selectedPractice === "Putting Combine" && showPuttingResult && <PuttingPracticeResult userId={userId} />}
                {selectedPractice === "Putting Games" && <Games games={"puttinggames"} />}
                {selectedPractice === "Short Game Games" && <Games games={"arggames"} />}
                {selectedPractice === "Tee Games" && <Games games={"teegames"} />}
                {selectedPractice === "Approach Games" && <Games games={"approachgames"} />}
                {selectedPractice === "Approach" && <ApproachPractice setStartNewApproach={setStartNewApproach} setShowApproachResult={setShowApproachResult} setLowDistance={setLowDistance} setHighDistance={setHighDistance} />}
                {selectedPractice === "Approach" && startNewApproach && <NewApproachPractice setStartNewApproach={setStartNewApproach} setShowApproachResult={setShowApproachResult} setLowDistance={setLowDistance} lowDistance={lowDistance} highDistance={highDistance} />}
                {selectedPractice === "Approach" && showApproachResult && <ApproachPracticeResult lowDistance={lowDistance} highDistance={highDistance} userId={userId} />}
            </Row>
        </Container >
    )
}

export { Practice }