import { React } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { LoginButton } from "./LoginButton";
import { LogoutButton } from "./LogoutButton";
import { ProgressSpinner } from "primereact/progressspinner";
import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const LogNavButton = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();

  return (
    <>
      <motion.div
        initial="hidden"
        animate="visible"
        variants={variants}
        transition={{ duration: 0.1 }}
      >
        {isAuthenticated ? (
          <>
            <LogoutButton></LogoutButton>
          </>
        ) : !isAuthenticated ? (
          <>
            <LoginButton></LoginButton>
          </>
        ) : (
          <></>
        )}
      </motion.div>
    </>
  );
};

export { LogNavButton };
