import React, { useEffect, useState, useRef } from "react";
import { DisplayCourses } from "./course_components/DisplayCourses";
import { CourseDetails } from "./course_components/CourseDetails";
import { Card } from "primereact/card";


import { Toast } from 'primereact/toast';
const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

function CourseRefractor({ direction, ...args }) {
  // Course Data
  const [courseData, setCourseData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const toast = useRef(null);
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  // Course Page States
  const [showCourseHomepage, setShowCourseHomepage] = useState(true);
  const [showCourseDetails, setShowCourseDetails] = useState(false);
  useEffect(() => {
    async function fetchCourses() {
      const result = await fetch("/api/course")
        .then((response) => response.json())
        .then((data) => {
          setCourseData(data);
          setSubmitButtonClicked(false);
        });
    }
    fetchCourses();
  }, [submitButtonClicked]);


  const handleCourseClk = (courseSelect) => {
    if (courseSelect != null) {
      setSelectedCourse(courseSelect);
      setShowCourseHomepage(false);
      setShowCourseDetails(true);
    }
  };

  const handleBackBtn = () => {
    setShowCourseHomepage(true);
    setShowCourseDetails(false);
  };


  const handleSubmitClick = () => {
    setSubmitButtonClicked(true);
    setCourseData([])
  }

  return (
    <div>
      {showCourseHomepage && (
        <div>

          <Toast ref={toast} />
          <Card title="Courses">
            <DisplayCourses onClick={handleCourseClk} onClick2={handleSubmitClick} courseData={courseData} toast={toast} />

          </Card>
        </div>
      )}
      {showCourseDetails &&
        <CourseDetails onClick={handleBackBtn} course={selectedCourse} />

      }
    </div>
  );
}

export { CourseRefractor };
