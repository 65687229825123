import React from "react";
import { useState, useEffect } from "react";
import { Form, Row, Col, Label, Input, FormGroup, Button } from "reactstrap";
/** Form to add a new tournament*/
function AddTournament({ setNewTournament }) {

    const [name, setName] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [isInputValid, setIsInputValid] = useState(false);

    var tournamentJSON = {
        name: "",
        startDate: "",
        endDate: "",
        userIds: []
    }

    function fillJSON() {
        tournamentJSON.name = name;
        tournamentJSON.startDate = startDate;
        tournamentJSON.endDate = endDate;
    }

    useEffect(() => {
        if (name !== "" && startDate !== null && endDate !== null) {
            setIsInputValid(true);
        } else {
            setIsInputValid(false);
        }
    }, [name, startDate, endDate])

    function postTournament() {
        fillJSON();
        fetch('/api/Tournament', {
            method: 'POST',
            body: JSON.stringify(tournamentJSON),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                console.log(data)
                setNewTournament(data)
            })
            .catch(error => console.error(error));
    }

    return (
        <div style={{ marginTop: "10px" }}>
            <Form>
                <Row className="justify-content-center align-items-center">
                    <Col>
                        <Form>
                            <FormGroup>
                                <Label>Tournament Name</Label>
                                <Input
                                    id="exampleCourseName"
                                    name="course-name"
                                    placeholder="name"
                                    type="text"
                                    onChange={(event) => setName(event.target.value)}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Label>Start Date</Label>
                                <Input
                                    id="roundDate"
                                    name="date"
                                    placeholder="Start Date"
                                    type="date"
                                    onChange={(event) => setStartDate(event.target.value)}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col>
                        <Form>
                            <FormGroup>
                                <Label>End Date</Label>
                                <Input
                                    id="roundDate"
                                    name="date"
                                    placeholder="End Date"
                                    type="date"
                                    onChange={(event) => setEndDate(event.target.value)}
                                />
                            </FormGroup>
                        </Form>
                    </Col>
                    <Col>
                        <Button disabled={!isInputValid} color="primary" onClick={() => postTournament()}>Add new tournament</Button>
                    </Col>
                </Row>
            </Form>
        </div>
    );
}

export { AddTournament };
