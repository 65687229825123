import React, { useEffect, useRef } from "react";
import { Table } from "reactstrap";
import '../css/loading.css'
/**Table with strokes gained from last 10 rounds */
const HomeStrokesGained = (props) => {
    /**Strokes gained off the tee per round in last 10 rounds */
    const sgOTT = useRef(null);
    /**Strokes gained approach per round in last 10 rounds */
    const sgAPP = useRef(null);
    /**Strokes gained around the green per round in last 10 rounds */
    const sgARG = useRef(null);
    /**Strokes gained putting per round in last 10 rounds */
    const sgPUTT = useRef(null);


    useEffect(() => {
        if (props.noRounds) {
            var table = document.getElementById("sghometable");
            var cells = table.getElementsByClassName("loading-cell");

            // Show the loading animation
            while (cells.length) {
                cells[0].classList.remove("loading-cell");
            }
        }
        else if (props.data) {
            fillQuickStats(props.data)
            var table = document.getElementById("sghometable");
            var cells = table.getElementsByClassName("loading-cell");

            // Show the loading animation
            while (cells.length) {
                cells[0].classList.remove("loading-cell");
            }
        }
    }, [props.data, props.noRounds]);

    /**Fills out the table with strokes gained in last 10 rounds */
    function fillQuickStats(data) {
        sgOTT.current.textContent = data.sgOTT;
        sgAPP.current.textContent = data.sgApp;
        sgARG.current.textContent = data.sgARG;
        sgPUTT.current.textContent = data.sgPutting;
    }

    return (
        <Table id="sghometable">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>OTT</td>
                    <td className="loading-cell" ref={sgOTT}></td>
                </tr >
                <tr>
                    <td>APP</td>
                    <td className="loading-cell" ref={sgAPP}></td>
                </tr>
                <tr>
                    <td>ARG</td>
                    <td className="loading-cell" ref={sgARG}></td>
                </tr>
                <tr>
                    <td>PUTT</td>
                    <td className="loading-cell" ref={sgPUTT}></td>
                </tr>
            </tbody >

        </Table>
    );
};

export default HomeStrokesGained;
