
export function calculateTime(variables, numberImprovement, numberStrength, totalMinutes, setState) {
    var weaknesses = variables.slice(0, numberImprovement);
    var strengths = variables.slice(variables.length - numberStrength, variables.length);
    var totalLost = 0
    var totalGain = 0
    weaknesses.forEach(function (val, i) {
        totalLost += Math.abs(val.value)
    })
    strengths.forEach(function (val, i) {
        totalGain += Math.pow(Math.abs(val.value), 1 / 3)
    })
    var mult = numberImprovement / (numberImprovement + numberStrength) * 1.2;

    var timeStrength = (totalMinutes * (1 - mult)) / (Math.abs(totalGain))
    var timeWeak = (totalMinutes * mult) / (Math.abs(totalLost))

    weaknesses.forEach((val, i) => {
        var t = timeWeak * Math.abs(weaknesses[i].value);
        setState((prev) => ({
            ...prev,
            [`analysis${i + 1}Time`]: parseFloat(t).toFixed(0),
        }));
    })
    strengths.forEach((val, i) => {
        var t = timeStrength * Math.pow(Math.abs(strengths[numberStrength - 1 - i].value), 1 / 3);
        setState((prev) => ({
            ...prev,
            [`strength${i + 1}Time`]: parseFloat(t).toFixed(0),
        }));
    })
}
