import React, { useState, useEffect, useRef } from "react";
import { Table, Row, Col } from "reactstrap";
import { Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Message } from 'primereact/message';

import '../css/loading.css'
/**Table with all user's rounds. Only last 10 will show then can be expanded by another 10. */
const RoundTable = (props) => {
    /**Array of rounds */
    const [rounds, setRounds] = useState([]);
    /**How many rounds should be shown on the table */
    const [numRoundsToShow, setNumRoundsToShow] = useState(10);
    const [deletedRound, setDeletedRound] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const toast = useRef(null);
    const [noRounds, setNoRounds] = useState(false)
    /**Gets all rounds. */
    useEffect(() => {
        if (props.userId !== null) {
            if (props.home === false) {
                fetch(`/api/Round/user?userId=${props.userId}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setRounds(data);
                        props.setWasRoundDeleted(false);
                        if (data.length <= 0) {
                            setNoRounds(true);
                        } else {
                            setNoRounds(false);
                        }
                    })
                    .catch((error) => console.log(error));
            } else {
                fetch(`/api/Round/user-last?userId=${props.userId}&numberOfRounds=10&roundType=All`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setRounds(data);
                        if (data.length <= 0) {
                            setNoRounds(true);
                        } else {
                            setNoRounds(false);
                        }
                    }
                    )
                    .catch((error) => { console.log(error); });
            }

        }

    }, [props, deletedRound]);
    /**Formats the datae to mm/dd/yyyy */
    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        if (isNaN(month)) {
            return "...";
        }
        return `${month}/${day}/${year}`;
    };
    function handleDelete(round) {
        const confirmDelete = window.confirm(`Are you sure you want to delete the ${round.type} round from ${formatDate(round.date)} with score ${round.score}?`)
        if (confirmDelete) {

            fetch(`/api/Round/${round.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                })
                .then(data => {
                    setRounds([])
                    setDeletedRound(data);
                    props.setWasRoundDeleted(true);
                    toast.current.show({ severity: 'success', summary: 'Deleted', detail: `${round.type} round from ${formatDate(round.date)} with score ${round.score}` });
                })
                .catch(error => console.error(error));
        }

    }

    function openContinue(round) {
        const encodedTeeValue = encodeURIComponent(round.teeBox);
        window.location.href = `/finish-round?roundId=${round.id}&courseId=${round.courseId}&tee=${encodedTeeValue}`
    }

    /**Will increse the number of rounds shown in the table */
    const handleLoadMore = () => {
        setNumRoundsToShow(numRoundsToShow + 10);
    };
    useEffect(() => {
        if (rounds.length > 0) {
            setLoaded(true);
        } else {
            if (noRounds) {
                setLoaded(true);
            } else {
                setLoaded(false);
            }
        }
    }, [rounds])
    return (
        <Row>
            <Toast ref={toast} />
            {noRounds && <Message className="mt-2 mb-2" text="No rounds were loaded" />}
            <Col lg="12">
                <div className="table-responsive">
                    <Table>
                        <thead>
                            <tr>
                                <th>Course</th>
                                <th>Round Type</th>
                                <th>Date</th>
                                <th>Score</th>
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {!loaded ? (
                                <tr>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                    <td className="loading-cell">
                                        .
                                    </td>
                                </tr>
                            ) :
                                (rounds.slice(0, numRoundsToShow).map((round) => (
                                    <tr key={round.id}>
                                        <td>{round.course}</td>
                                        <td>{round.type}</td>
                                        <td>{formatDate(round.date)}</td>
                                        <td>{round.score}</td>
                                        {round.score !== null && <td>
                                            <Button severity="secondary" label="Stats" size="small" icon="pi pi-chart-bar" onClick={() => window.open(`/round-stats/${round.id}`, "_self")} />
                                        </td>}
                                        {round.score !== null && <td>
                                            <Button severity="secondary" label="Edit" size="small" icon="pi pi-file-edit" onClick={() => window.open(`/round-edit/${round.id}`, "_self")} />
                                        </td>}
                                        {round.score === null && <td>
                                            <Button label="Continue" severity="secondary" size="small" icon="pi pi-play" onClick={() => openContinue(round)} />
                                        </td>}
                                        {round.score === null && <td>
                                        </td>}
                                        <td>
                                            <Button severity="danger" size="small" icon="pi pi-trash" onClick={() => handleDelete(round)} />
                                        </td>
                                    </tr>
                                )))}
                        </tbody>
                    </Table>
                </div>
            </Col>
            <Col lg="12">
                {numRoundsToShow < rounds.length && (
                    <div className="d-flex justify-content-center">
                        <Button style={{ width: 300 }} color="secondary" onClick={handleLoadMore}>
                            Load More
                        </Button>
                    </div>
                )}
            </Col>
        </Row >
    );


};

export default RoundTable;
