import { useState, useEffect } from 'react';
import { Card } from 'primereact/card';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { Checkbox } from 'primereact/checkbox';
import { Tag } from 'primereact/tag';
import { SelectButton } from 'primereact/selectbutton';
import '../css/Calculator.css'

import { useAuth0 } from "@auth0/auth0-react";
const SGcalculator = () => {

    const { user } = useAuth0();
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [startLie, setStartLie] = useState("");
    const [endLie, setEndLie] = useState("");
    const startLieOptions = ["bunker", "fairway", "fringe", "green", "recovery", "rough", "tee"];
    const endLieOptions = ["bunker", "fairway", "fringe", "green", "recovery", "rough"];
    const [units, setUnits] = useState("yards");
    const unitsOptions = ["yards", "meters"];
    const [baseline, setBaseline] = useState("PGA");
    const baselines = ["PGA", "LPGA"]
    const [startDst, setStartDst] = useState("");
    const [endDst, setEndDst] = useState("")
    const [isITH, setIsINH] = useState(false);
    const [sg, setSG] = useState(null);
    const [startVal, setStartVal] = useState("");
    const [endVal, setEndVal] = useState("");
    const [penalty, setPenalty] = useState("");


    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:myself'
                    },
                });

                const response = await fetch(`api/user/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setBaseline(responseData.baseline);
                setUnits(responseData.units);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        let units = window.localStorage.getItem("units");
        let baseline = window.localStorage.getItem("baseline");
        if (units && baseline) {
            setBaseline(baseline);
            setUnits(units);
        } else {

            fetchUser();
        }
    }, [loginWithRedirect, getAccessTokenSilently]);
    useEffect(() => {
        if (startDst && startLie && endDst >= 0 && endLie) {
            fetch(`/api/Calculation/one-shot-sg`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                }, body: JSON.stringify({
                    userId: "",
                    type: "",
                    date: new Date().toISOString(),
                    course: "",
                    courseId: "",
                    teeBox: "",
                    tournamentId: "",
                    baseline: baseline,
                    score: 0,
                    shots: [
                        {
                            shotTotal: 1,
                            shotHole: 1,
                            parHole: 4,
                            holeNo: 1,
                            shotDst: parseInt(units === "yards" ? startDst : startDst * 1.09361),
                            lie: startLie,
                            club: "",
                            mental: "",
                            missDst: "",
                            missDir: "",
                            penalty: false,
                            layup: false
                        },
                        {
                            shotTotal: 2,
                            shotHole: 2,
                            parHole: 4,
                            holeNo: 1,
                            shotDst: parseInt(units === "yards" ? endDst : endDst * 1.09361),
                            lie: endLie,
                            club: "",
                            mental: "",
                            missDst: "",
                            missDir: "",
                            penalty: false,
                            layup: false
                        }
                    ]
                })
            })
                .then((response) => { return response.json() })
                .then((data) => {
                    setSG((data[0] - penalty).toFixed(2))
                    setStartVal((data[1] + penalty).toFixed(2))
                    setEndVal((data[2]).toFixed(2))
                })
                .catch((error) => console.error(error))
        }
    }, [startDst, startLie, endDst, endLie, baseline, units, sg, penalty])

    const footer = () => {
        return (
            <div className="gap-2" style={{ display: "flex", flexFlow: "wrap", alignItems: "center", justifyContent: "center" }}>
                <div>Strokes gained:</div>
                <Tag style={{ fontSize: "1.25rem" }} value={sg} severity={sg > 0 ? "success" : (sg < 0 ? "danger" : "info")} />
            </div>
        )
    }

    const footerStart = () => {
        return (
            <div className="gap-2" style={{ display: "flex", flexFlow: "wrap", alignItems: "center", justifyContent: "center" }}>
                <div>Average: {startVal}</div>
            </div>
        )
    }

    const footerEnd = () => {
        return (
            <div className="gap-2" style={{ display: "flex", flexFlow: "wrap", alignItems: "center", justifyContent: "center" }}>
                <div>Average: {endVal}</div>
            </div>
        )
    }
    const header = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                    <div className='m-2'>
                        <SelectButton value={units} onChange={(e) => setUnits(e.value)} options={unitsOptions} />
                    </div>
                    <div className='m-2'>
                        <SelectButton value={baseline} onChange={(e) => setBaseline(e.value)} options={baselines} />
                    </div>
                </div>
            </div>
        )
    }
    return (
        <Card title="Strokes Gained Calculator" footer={footer} subTitle={header}>
            <div style={{ display: "flex", justifyContent: "center", flexDirection: "row" }}>

                <div className='m-3 ' style={{ width: "50%" }}>
                    <Card title="Shot" footer={footerStart}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px" }}>
                            <Dropdown
                                value={startLie}
                                onChange={(e) => { setStartLie(e.value) }}
                                options={startLieOptions}
                                placeholder='Select lie'
                            />
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "15px" }}>
                                <InputNumber
                                    id='calcIn'
                                    placeholder="Distance"
                                    onValueChange={(e) => {
                                        setStartDst(e.value);
                                    }}
                                    value={startDst}
                                    step={startLie === "green" ? (startDst < 20 ? 1 : (startDst >= 30 ? 3 : 2)) : 5}
                                    suffix={startLie === "green" ? " feet" : (units === "yards" ? " yards" : " meters")}
                                    showButtons buttonLayout="vertical"
                                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                    decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                    min={0}
                                    max={700}
                                />
                            </div>
                            <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "15px" }}>
                                <InputNumber
                                    id='calcIn'
                                    placeholder="Penalties"
                                    inputId='Penaltycheck'
                                    onValueChange={(e) => setPenalty(e.value)}
                                    value={penalty}
                                    showButtons buttonLayout="vertical"
                                    incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                    decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                    min={0}
                                    maxLength={1}
                                />

                            </div>
                        </div>
                    </Card>
                </div>
                <div className='m-3 ' style={{ width: "50%" }}>
                    <Card title="Result" footer={footerEnd}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "15px" }}>
                            <Dropdown
                                value={endLie}
                                onChange={(e) => { setEndLie(e.value) }}
                                options={endLieOptions}
                                placeholder='Select lie'
                            />

                            <InputNumber
                                id='calcIn'
                                placeholder="Distance"
                                aria-describedby="distance-help"
                                disabled={isITH}
                                value={endDst}
                                step={endLie === "green" ? (endDst < 20 ? 1 : (endDst >= 30 ? 3 : 2)) : 5}
                                onValueChange={(e) => setEndDst(e.value)}
                                suffix={endLie === "green" ? " feet" : (units === "yards" ? " yards" : " meters")}
                                showButtons buttonLayout="vertical"
                                incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                min={0}
                                max={700} />

                            <div >
                                <Checkbox onChange={e => {
                                    setIsINH(e.checked);
                                    if (e.checked) {
                                        setEndDst("0")
                                    } else {
                                        setEndDst("")
                                    }
                                }} checked={isITH}
                                    inputId='INHcheck' />
                                <label className='m-1' htmlFor='INHcheck'>In the Hole?</label>

                            </div>

                        </div>
                    </Card>
                </div>
            </div>
        </Card>
    )
}

export default SGcalculator;