import React, { useEffect, useState } from 'react';
import '../custom.css'
import {
    Container, Form, FormGroup, Input, Label, Row, Col, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { Message } from 'primereact/message';
import { PlayersDropdown } from './dropdowns/PlayersDropdown';
import { RoundTypeDropdown } from './dropdowns/RoundTypeDropdown';
import { DurationDropdown } from './dropdowns/DurationDropdown';
import { Scorecard } from './Scorecard';
import { IndividualStats } from './IndividualStats';
import { DateInput } from './DateInput';

export function StatsPage({ areIndividualStats }) {

    const [round, setRound] = useState(null);
    const { user } = useAuth0();
    const userId = user.sub.substring(user.sub.indexOf('|') + 1);
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [selectedUser, setSelectedUser] = useState(null)
    /**Selected type of the round */
    const [roundType, setRoundType] = useState("All");
    /**Is custom range selected */
    const [custom, setCustom] = useState(false);
    /**Is tournament selected */
    const [selectTournament, setSelectTournament] = useState(false);
    /**What torunament is selected */
    const [tournament, setTournament] = useState("tournament");
    /**Start of custom date range */
    const [startDate, setStartDate] = useState(null);
    /**End of custom date range */
    const [endDate, setEndDate] = useState(null);
    /**Type of selected duration */
    var [durationType, setDurationType] = useState('number');
    /**Selected time duration in months */
    var [timeDuration, setTimeDuration] = useState('');
    /**Selected last number of rounds */
    var [numberOfRounds, setNumberOfRounds] = useState(10);
    /**Possible round types */
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"];
    /**List of tournaments */
    const listTournaments = ["tournament1", "tournament2"];
    /**Good switch*/
    const [bottom10, setBottom10] = useState(false);
    /**Bad switch */
    const [top20, setTop20] = useState(false);
    /**Possible durations to filter rounds */
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "This year", "Fall", "Spring", "Custom"];


    const [data, setData] = useState(null);
    const [baseline, setBaseline] = useState("");
    const [userUnits, setUserUnits] = useState("");
    const [roundsFound, setRoundsFound] = useState(true);
    const [requestProcessed, setRequestProcessed] = useState(false);
    const [coachTeam, setCoachTeam] = useState(window.localStorage.getItem("team"))
    function fillOutTable(data) {
        setData(data);
    }
    /**
     * Gets rounds from database depending on which page user is at
     * @param {string} durationType - wheter time/number of rounds/custom/tournament
     * @param {int} timeDuration - how many months back
     * @param {int} numberOfRounds - how many last rounds
     * @param {string} roundType - type of rounds to get
     */
    function getRounds(durationType, timeDuration, numberOfRounds, roundType) {
        if (areIndividualStats) {
            getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType)
        } else {
            getMyRounds(durationType, timeDuration, numberOfRounds, roundType);
            if (durationType.toLowerCase() === "number" && numberOfRounds === 1) {
                getRoundShots(roundType)
            }
        }
    }



    function getRoundShots(roundType) {
        fetch(`/api/Round/user-last?numberOfRounds=${1}&userId=${userId}&roundType=${roundType}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setRound(data[0]);
            })
            .catch((error) => console.log(error));
    }

    /**
     * Gets rounds from the logged in user database depending on user's selection. 
     * @param {string} durationType - wheter time/number of rounds/custom/tournament
     * @param {int} timeDuration - how many months back
     * @param {int} numberOfRounds - how many last rounds
     * @param {string} roundType - type of rounds to get
     */
    function getMyRounds(durationType, timeDuration, numberOfRounds, roundType) {
        setRequestProcessed(false);
        if (durationType.toLowerCase() === "time") {
            fetch(`/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);
                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);
                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "number") {
            fetch(`/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);
                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);
                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "custom" && startDate !== null && endDate !== null) {
            fetch(`/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "fall") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2024-08-25"}&endDate=${"2024-11-01"}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "spring") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2023-02-10"}&endDate=${"2023-05-01"}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "this year") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2024-01-01"}&endDate=${"2024-12-31"}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        }
    }
    /**
     * Gets rounds from selected user's database depending on user's selection.
     * @param {string} durationType - wheter time/number of rounds/custom/tournament
     * @param {int} timeDuration - how many months back
     * @param {int} numberOfRounds - how many last rounds
     * @param {string} roundType - type of rounds to get
     */
    function getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType) {
        setRequestProcessed(false);
        if (durationType.toLowerCase() === "time") {
            fetch(`/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);
                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "number") {
            fetch(`/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRequestProcessed(true);
                    setRoundsFound(false);
                });
        } else if (durationType.toLowerCase() === "custom" && startDate !== null && endDate !== null) {
            fetch(`/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);
                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);
                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "fall") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2024-08-25"}&endDate=${"2024-10-29"}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "spring") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2023-02-10"}&endDate=${"2023-05-01"}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        } else if (durationType.toLowerCase() === "this year") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2024-01-01"}&endDate=${"2024-12-31"}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setRoundsFound(true);

                    setRequestProcessed(true);
                    fillOutTable(data);
                })
                .catch(error => {
                    console.error(error);
                    setRoundsFound(false);

                    setRequestProcessed(true);
                });
        }
    }

    useEffect(() => {
        if (selectedUser)
            setBaseline(selectedUser.baseline);
    }, [selectedUser])

    useEffect(() => {
        if (!areIndividualStats) {
            var baseline = window.localStorage.getItem("baseline");
            if (baseline) {
                setBaseline(baseline);
            } else {
                fetch(`/api/User/userBaseline?userId=${user.sub}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(response => {
                        return response.text();
                    })
                    .then(data => {
                        setBaseline(data);
                    })
                    .catch(error => {
                        console.error(error);
                    });
            }
        }
    }, [])
    /**
     * Everytime filter is changed it will try to get the rounds.
     */
    useEffect(() => {
        setData(null);

        getRounds(durationType, timeDuration, numberOfRounds, roundType);
    }, [durationType, timeDuration, numberOfRounds, roundType, startDate, endDate, custom, selectedUserId]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: "read:myself"
                    },
                });

                const response = await fetch(`api/user/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setUserUnits(responseData.units);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        var units = window.localStorage.getItem("units");
        var team = window.localStorage.getItem("team")
        if (units && team) {
            setUserUnits(units);
            setCoachTeam(team)
        } else {
            fetchUser();
        }

    }, [loginWithRedirect, getAccessTokenSilently]);


    /**
     * Renders dropdowns for filtering out rounds.
     * If custom is selected it will render two date inputs.
     * If tournament is selected it will render dropdown with tournaments played.
     */
    function renderFilters() {
        if (!custom && !selectTournament) {
            return (
                <div >
                    <Row >
                        <Col xs="6" sm="4" md="3" lg="3">
                            <RoundTypeDropdown setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} setTournamentSelected={() => console.log()} />
                        </Col>
                        <Col xs="6" sm="2" md="2" lg="1">
                            <DurationDropdown setNumberOfRounds={setNumberOfRounds}
                                setTimeDuration={setTimeDuration} setCustom={setCustom}
                                setDurationType={setDurationType} durations={durations} />
                        </Col>
                        <Col className='mt-3 mt-md-0' xs={{ offset: 0, size: 5 }} sm={{ offset: 2, size: 5 }} md="2" lg="2">
                            {areIndividualStats &&
                                <PlayersDropdown setSelectedUserId={setSelectedUserId} setSelectedUser={setSelectedUser} selectedUser={selectedUser} coachTeam={coachTeam} />
                            }
                        </Col>
                    </Row>
                </div >
            )
        } else if (custom) {
            return (
                <div >
                    <Row>
                        <Col xs="4" md="2" lg="2">
                            <Form>
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={bottom10}
                                        style={{ backgroundColor: bottom10 === true ? 'red' : '' }}
                                        onClick={() => {
                                            setBottom10(!bottom10);
                                        }}
                                    />
                                    <Label>Bad</Label>
                                </FormGroup>
                                <FormGroup switch >
                                    <Input
                                        type="switch"
                                        checked={top20}
                                        style={{ backgroundColor: top20 === true ? 'green' : '' }}
                                        onClick={() => {
                                            setTop20(!top20);
                                        }}
                                    />
                                    <Label>Good</Label>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col xs="8" md="2" lg="2">
                            <RoundTypeDropdown setRoundType={setRoundType} setSelectedUser={setSelectedUser} roundType={roundType} roundTypes={roundTypes} setTournamentSelected={() => console.log()} />
                        </Col>
                        <Col lg="8">
                            <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                <label htmlFor='startDate'>Start Date</label>
                                <DateInput id="startDate" selectedDate={startDate} setSelectedDate={setStartDate} />
                                <label htmlFor='endDate'>End Date</label>
                                <DateInput id="endDate" selectedDate={endDate} setSelectedDate={setEndDate} />
                            </div>
                        </Col>

                        {areIndividualStats && <Col className='mt-0 mt-sm-3'>
                            <PlayersDropdown setSelectedUserId={setSelectedUserId} setSelectedUser={setSelectedUser} selectedUser={selectedUser} />

                        </Col>
                        }

                    </Row>
                </div>
            )
        } else if (selectTournament) {
            return (
                <div>
                    <Row>
                        <Col xs="3" md="2" lg="2">
                            <Form>
                                <FormGroup switch>
                                    <Input
                                        type="switch"
                                        checked={bottom10}
                                        style={{ backgroundColor: bottom10 === true ? 'red' : '' }}
                                        onClick={() => {
                                            setBottom10(!bottom10);
                                        }}
                                    />
                                    <Label>Bad</Label>
                                </FormGroup>
                                <FormGroup switch >
                                    <Input
                                        type="switch"
                                        checked={top20}
                                        style={{ backgroundColor: top20 === true ? 'green' : '' }}
                                        onClick={() => {
                                            setTop20(!top20);
                                        }}
                                    />
                                    <Label>Good</Label>
                                </FormGroup>
                            </Form>
                        </Col>
                        <Col xs="8" md="2" lg="2">
                            <UncontrolledDropdown value={roundType}>
                                <DropdownToggle
                                    caret
                                    color="primary">
                                    {tournament}
                                </DropdownToggle>
                                <DropdownMenu>
                                    {listTournaments.map((tour) => (
                                        <DropdownItem key={tour} value={tour} onClick={(event) => setTournament(event.target.value)}>
                                            {tour}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Col>
                        {areIndividualStats && <Col>
                            <PlayersDropdown setSelectedUserId={setSelectedUserId} setSelectedUser={setSelectedUser} selectedUser={selectedUser} />
                        </Col>}
                    </Row>
                </div>
            )
        }
    }


    return (
        <Container>
            <div className='table-responsive'>
                {round && durationType.toLowerCase() === "number" && numberOfRounds === 1 && <Scorecard round={round} />}
            </div>
            {renderFilters()}
            {areIndividualStats ? selectedUser === null && <Message className='m-1 m-sm-3' severity="warn" text="No player was selected" />
                : ""}
            {!roundsFound && <Message className='m-1 m-sm-3' severity="warn" text="No rounds found" />}
            <IndividualStats data={data} top20={top20} bottom10={bottom10} baseline={baseline} requestProcessed={requestProcessed} units={userUnits} />
        </Container>
    )
};