import { StatsPage } from "./components/StatsPage";
import { CreateRound } from "./components/CreateRound";
import Home2 from "./components/Home2";
import { CourseRefractor } from "./components/CourseRefractor";
import { Auth0Example } from "./components/auth0/Auth0Example";
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { TournamentsRefractor } from "./components/TournamentsRefractor";
import { CoachStats } from "./components/CoachStats";
import { EditRound } from "./components/edit_components/EditRound";
import { Practice } from "./components/practice_components/Practice";
import { TeamPractice } from "./components/practice_components/TeamPractice";
import { RoundStats } from "./components/RoundStats";
import { AddRound } from "./components/AddRound";
import { TeamRounds } from "./components/TeamRounds";
import { MyRounds } from "./components/MyRounds"
import UserProfile from "./components/UserProfile";
import SGcalculator from "./components/SGcalculator";
import { ToDateRounds } from "./components/ToDateRounds";

const ProtectedRoute = ({ component, ...args }) => {
  const Component = withAuthenticationRequired(component);
  return <Component {...args} />;
};


const AppRoutes = [
  {
    index: true,
    element: <Home2 />
  },
  {
    path: '/stats',
    element: <ProtectedRoute component={StatsPage} areIndividualStats={false} />
  },
  {
    path: '/stats-individual',
    element: <ProtectedRoute component={StatsPage} areIndividualStats={true} />
  },
  {
    path: '/round',
    element: <ProtectedRoute component={CreateRound} />
  },
  {
    path: '/courses',
    element: <ProtectedRoute component={CourseRefractor} />
  },
  {
    path: '/auth0',
    element: <ProtectedRoute component={Auth0Example} />
  },
  {
    path: '/tournaments',
    element: <ProtectedRoute component={TournamentsRefractor} />
  },
  {
    path: '/coach-stats',
    element: <ProtectedRoute component={CoachStats} />
  },
  {
    path: '/round-edit/:id',
    element: <ProtectedRoute component={EditRound} />
  },
  {
    path: '/round-stats/:id',
    element: <ProtectedRoute component={RoundStats} />
  },
  {
    path: '/practice',
    element: <ProtectedRoute component={Practice} />
  },
  {
    path: '/team-practice',
    element: <ProtectedRoute component={TeamPractice} />
  },
  {
    path: '/finish-round',
    element: <ProtectedRoute component={AddRound} />
  },
  {
    path: '/team-rounds',
    element: <ProtectedRoute component={TeamRounds} />
  },
  {
    path: '/my-rounds',
    element: <ProtectedRoute component={MyRounds} />
  },
  {
    path: '/user-profile',
    element: <ProtectedRoute component={UserProfile} />
  },
  {
    path: '/sg-calculator',
    element: <ProtectedRoute component={SGcalculator} />
  },
  {
    path: '/to-date',
    element: <ProtectedRoute component={ToDateRounds} />
  },
];

export default AppRoutes;
