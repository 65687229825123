import React, { useState, useEffect } from "react";
import {
    Table
} from "reactstrap";
import '../css/scorecard.css'
const Scorecard = ({ round }) => {
    const [isMobile, setIsMobile] = useState(false);
    const [holePars, setHolePars] = useState([]);
    const [holeScores, setHoleScores] = useState([]);
    const [parFront, setParFront] = useState(0);
    const [parBack, setParBack] = useState(0);
    const [scoreFront, setScoreFront] = useState(0);
    const [scoreBack, setScoreBack] = useState(0);
    const [holePutts, setHolePutts] = useState(0);
    const [puttsFront, setPuttsFront] = useState(0);
    const [puttsBack, setPuttsBack] = useState(0);

    /** Colors of cells for the scorecard*/
    const [cellStyles, setCellStyles] = useState(Array(18).fill({ backgroundColor: "white" }));
    useEffect(() => {
        // Function to check and update screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set breakpoint as per your requirement
        };

        // Initial check for screen size
        handleResize();

        // Add event listener to check screen size on resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount to avoid memory leaks
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    /**
   * Changes color on the scorecard and saves it
   */
    function getCellStyle() {
        var styles = Array(18).fill({ backgroundColor: "white" });
        for (var h = 0; h < 18; h++) {
            const currentValue = holeScores[h];
            const aboveValue = holePars[h];
            let color = "white";
            let text = "black";

            if (aboveValue != null) {
                const diff = currentValue - aboveValue;
                if (diff === 1) {
                    color = "green";
                } else if (diff === 2) {
                    color = "blue";
                    text = "white";
                } else if (diff >= 3) {
                    color = "black";
                    text = "white";
                } else if (diff === -1) {
                    color = "red";
                    text = "white";
                } else if (diff <= -2) {
                    color = "yellow";
                }
            }
            styles[h] = { backgroundColor: color, color: text };
        }
        setCellStyles(styles);
    }
    useEffect(() => {
        if (holeScores.length > 0 && holePars.length > 0) {
            getCellStyle();
        }
    }, [holeScores, holePars]);

    function setPars(round) {
        var pars = Array(18).fill(0);
        for (var s in round.shots) {
            pars[round.shots[s].holeNo - 1] = round.shots[s].parHole;
        }
        setHolePars(pars);
        setParFront(pars.slice(0, 9).reduce((acc, curr) => acc + curr, 0));
        setParBack(pars.slice(9).reduce((acc, curr) => acc + curr, 0));
    }

    function setScores(round) {
        var scores = Array(18).fill(0);
        for (var s in round.shots) {
            scores[round.shots[s].holeNo - 1] = round.shots[s].shotHole;
        }
        setHoleScores(scores);
        setScoreFront(scores.slice(0, 9).reduce((acc, curr) => acc + curr, 0));
        setScoreBack(scores.slice(9).reduce((acc, curr) => acc + curr, 0));
    }

    function setPutts(round) {
        var putts = Array(18).fill(0);
        for (var s in round.shots) {
            if (round.shots[s].lie === "green") {
                putts[round.shots[s].holeNo - 1] += 1;
            }

        }
        setHolePutts(putts);
        setPuttsFront(putts.slice(0, 9).reduce((acc, curr) => acc + curr, 0));
        setPuttsBack(putts.slice(9).reduce((acc, curr) => acc + curr, 0));
    }


    useEffect(() => {
        setPars(round);
        setScores(round);
        setPutts(round);
    }, [round])
    if (holeScores.length > 0) {
        return (
            <div>
                {isMobile ?
                    <div>
                        <Table className="scorecard-table">
                            <thead>
                                <tr>
                                    <th>Hole</th>
                                    <th>1</th>
                                    <th>2</th>
                                    <th>3</th>
                                    <th>4</th>
                                    <th>5</th>
                                    <th>6</th>
                                    <th>7</th>
                                    <th>8</th>
                                    <th>9</th>
                                    <th>F</th>
                                </tr>
                                <tr>
                                    <th>PAR</th>
                                    {
                                        Object.keys(holePars).slice(0, 9).map((rowy, indy) => (
                                            <td>
                                                {holePars[indy]}
                                            </td>
                                        ))
                                    }
                                    <td style={{ fontWeight: "bold" }} >{parFront}</td>

                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th id="score">Score</th>
                                    <td style={cellStyles[0]}>{holeScores[0]}</td>
                                    <td style={cellStyles[1]}>{holeScores[1]}</td>
                                    <td style={cellStyles[2]}>{holeScores[2]}</td>
                                    <td style={cellStyles[3]}>{holeScores[3]}</td>
                                    <td style={cellStyles[4]}>{holeScores[4]}</td>
                                    <td style={cellStyles[5]}>{holeScores[5]}</td>
                                    <td style={cellStyles[6]}>{holeScores[6]}</td>
                                    <td style={cellStyles[7]}>{holeScores[7]}</td>
                                    <td style={cellStyles[8]}>{holeScores[8]}</td>
                                    <td style={{ fontWeight: "bold" }} id="front">{scoreFront}</td>
                                </tr>
                                <tr>
                                    <th id="score">Putts</th>
                                    <td>{holePutts[0]}</td>
                                    <td>{holePutts[1]}</td>
                                    <td>{holePutts[2]}</td>
                                    <td>{holePutts[3]}</td>
                                    <td>{holePutts[4]}</td>
                                    <td>{holePutts[5]}</td>
                                    <td>{holePutts[6]}</td>
                                    <td>{holePutts[7]}</td>
                                    <td>{holePutts[8]}</td>
                                    <td style={{ fontWeight: "bold" }} id="front">{puttsFront}</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Table className="scorecard-table">
                            <thead>
                                <tr>
                                    <th>Hole</th>
                                    <th>10</th>
                                    <th>11</th>
                                    <th>12</th>
                                    <th>13</th>
                                    <th>14</th>
                                    <th>15</th>
                                    <th>16</th>
                                    <th>17</th>
                                    <th>18</th>
                                    <th>B</th>
                                    <th>T</th>
                                </tr>
                                <tr>
                                    <th>PAR</th>
                                    {
                                        Object.keys(holePars).slice(9, 18).map((rowy, indy) => (
                                            <td>
                                                {holePars[indy + 9]}
                                            </td>
                                        ))
                                    }
                                    <td style={{ fontWeight: "bold" }} >{parBack}</td>
                                    <td style={{ fontWeight: "bold" }} >{parBack + parFront}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th id="score">Score</th>
                                    <td style={cellStyles[9]}>{holeScores[9]}</td>
                                    <td style={cellStyles[10]}>{holeScores[10]}</td>
                                    <td style={cellStyles[11]}>{holeScores[11]}</td>
                                    <td style={cellStyles[12]}>{holeScores[12]}</td>
                                    <td style={cellStyles[13]}>{holeScores[13]}</td>
                                    <td style={cellStyles[14]}>{holeScores[14]}</td>
                                    <td style={cellStyles[15]}>{holeScores[15]}</td>
                                    <td style={cellStyles[16]}>{holeScores[16]}</td>
                                    <td style={cellStyles[17]}>{holeScores[17]}</td>
                                    <td style={{ fontWeight: "bold" }} id="back">{scoreBack}</td>
                                    <td style={{ fontWeight: "bold" }} id="total">{holeScores.reduce((sum, value) => sum + value)}</td>
                                </tr>
                                <tr>
                                    <th id="score">Putts</th>
                                    <td>{holePutts[9]}</td>
                                    <td >{holePutts[10]}</td>
                                    <td >{holePutts[11]}</td>
                                    <td >{holePutts[12]}</td>
                                    <td >{holePutts[13]}</td>
                                    <td >{holePutts[14]}</td>
                                    <td >{holePutts[15]}</td>
                                    <td >{holePutts[16]}</td>
                                    <td >{holePutts[17]}</td>
                                    <td style={{ fontWeight: "bold" }} id="back">{puttsBack}</td>
                                    <td style={{ fontWeight: "bold" }} id="total">{holePutts.reduce((sum, value) => sum + value)}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>

                    : <Table className="scorecard-table">
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>1</th>
                                <th>2</th>
                                <th>3</th>
                                <th>4</th>
                                <th>5</th>
                                <th>6</th>
                                <th>7</th>
                                <th>8</th>
                                <th>9</th>
                                <th>Front</th>
                                <th>10</th>
                                <th>11</th>
                                <th>12</th>
                                <th>13</th>
                                <th>14</th>
                                <th>15</th>
                                <th>16</th>
                                <th>17</th>
                                <th>18</th>
                                <th>Back</th>
                                <th>Total</th>
                            </tr>
                            <tr>
                                <th>PAR</th>
                                {
                                    Object.keys(holePars).slice(0, 9).map((rowy, indy) => (
                                        <td>
                                            {holePars[indy]}
                                        </td>
                                    ))
                                }
                                <td>{parFront}</td>
                                {
                                    Object.keys(holePars).slice(9, 18).map((rowy, indy) => (
                                        <td>
                                            {holePars[indy + 9]}
                                        </td>
                                    ))
                                }
                                <td>{parBack}</td>
                                <td>{parBack + parFront}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th id="score">Score</th>
                                <td style={cellStyles[0]}>{holeScores[0]}</td>
                                <td style={cellStyles[1]}>{holeScores[1]}</td>
                                <td style={cellStyles[2]}>{holeScores[2]}</td>
                                <td style={cellStyles[3]}>{holeScores[3]}</td>
                                <td style={cellStyles[4]}>{holeScores[4]}</td>
                                <td style={cellStyles[5]}>{holeScores[5]}</td>
                                <td style={cellStyles[6]}>{holeScores[6]}</td>
                                <td style={cellStyles[7]}>{holeScores[7]}</td>
                                <td style={cellStyles[8]}>{holeScores[8]}</td>
                                <td id="front">{scoreFront}</td>
                                <td style={cellStyles[9]}>{holeScores[9]}</td>
                                <td style={cellStyles[10]}>{holeScores[10]}</td>
                                <td style={cellStyles[11]}>{holeScores[11]}</td>
                                <td style={cellStyles[12]}>{holeScores[12]}</td>
                                <td style={cellStyles[13]}>{holeScores[13]}</td>
                                <td style={cellStyles[14]}>{holeScores[14]}</td>
                                <td style={cellStyles[15]}>{holeScores[15]}</td>
                                <td style={cellStyles[16]}>{holeScores[16]}</td>
                                <td style={cellStyles[17]}>{holeScores[17]}</td>
                                <td id="back">{scoreBack}</td>
                                <td id="total">{holeScores.reduce((sum, value) => sum + value)}</td>
                            </tr>
                            <tr>
                                <th id="score">Putts</th>
                                <td>{holePutts[0]}</td>
                                <td>{holePutts[1]}</td>
                                <td>{holePutts[2]}</td>
                                <td>{holePutts[3]}</td>
                                <td>{holePutts[4]}</td>
                                <td>{holePutts[5]}</td>
                                <td>{holePutts[6]}</td>
                                <td>{holePutts[7]}</td>
                                <td>{holePutts[8]}</td>
                                <td id="front">{puttsFront}</td>
                                <td>{holePutts[9]}</td>
                                <td >{holePutts[10]}</td>
                                <td >{holePutts[11]}</td>
                                <td >{holePutts[12]}</td>
                                <td >{holePutts[13]}</td>
                                <td >{holePutts[14]}</td>
                                <td >{holePutts[15]}</td>
                                <td >{holePutts[16]}</td>
                                <td >{holePutts[17]}</td>
                                <td id="back">{puttsBack}</td>
                                <td id="total">{holePutts.reduce((sum, value) => sum + value)}</td>
                            </tr>
                        </tbody>
                    </Table>}

            </div>
        )
    }
};

export { Scorecard };
