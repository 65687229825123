import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { DisplayTournaments } from "./tournaments_components/DisplayTournaments";
import { TournamentDetails } from "./tournaments_components/TournamentDetails";

const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
};
/**Holds all tournament components */
function TournamentsRefractor({ direction, ...args }) {

    //Tournament Data
    //const [tournamentData, setTournamentData] = useState([]);
    const [selectedTournament, setSelectedTournament] = useState(null);



    return (
        < div >

            <div>
                <motion.div
                    initial="hidden"
                    animate="visible"
                    variants={variants}
                    transition={{ duration: 0.1 }}
                >
                    <DisplayTournaments />
                </motion.div>
            </div>

        </div >
    );
}

export { TournamentsRefractor };
