import { React, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { NavLink } from "reactstrap";
import { Link } from 'react-router-dom';


const variants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const ShowCoachPages = () => {
  const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0();
  const [userRoles, setUserRoles] = useState([]);

  //const roles = user["https://parfectperformance.com/role"] || [];

  /* useEffect(() => {
  const getRole = async () => {
    const idTokenClaims = await getIdTokenClaims();
    setRole(idTokenClaims['https://parfectperformance.com/role']);
    console.log(idTokenClaims)
    console.log(idTokenClaims['https://parfectperformance.com/role'])
  };
  if (isAuthenticated) {
    getRole();
    console.log(user);
    setUserId(user.sub.substring(user.sub.indexOf('|') + 1));
  }
}, [getIdTokenClaims, isAuthenticated]); */

  useEffect(() => {
    if (isAuthenticated) {
      setUserRoles(user["https://parfectperformance.com/role"] || []);
      if (userRoles.length > 0) {
        //console.log("The user roles are " + userRoles)
      }
      //console.log("The user roles are" + userRoles) 
    }
  }, [getIdTokenClaims, isAuthenticated, userRoles]);



  return (
    <>
      {isAuthenticated && userRoles.includes("Coach") && (
        <NavLink
          onClick={() => this.setState({ collapsed: true })}
          tag={Link}
          to="/coach-stats"
          style={{ color: 'black' }}
        >
          Group Stats
        </NavLink>
      )}
    </>
  );
};

export { ShowCoachPages };

