import React, { useState, useEffect } from "react";
import "../../css/new-round.css";
import { Row, Col } from 'reactstrap'
import { ToggleButton } from "primereact/togglebutton";
import { MultiSelect } from 'primereact/multiselect';
export function MultiSelectPlayers({ players, selectedUsers, setSelectedUsers, coachTeam }) {
    const [loading, setLoading] = useState(true);
    const [groupedPlayers, setGroupedPlayers] = useState(null);
    const [isAllMen, setIsAllMen] = useState(false);
    const [isAllWomen, setIsAllWomen] = useState(false);
    const coachesIds = ["google-oauth2|107153675129420164686", "google-oauth2|111751767583089427570", "google-oauth2|103519004092922203859", "google-oauth2|109182936532771609243", "google-oauth2|104069399016484953210", "google-oauth2|101828915672761203306"]
    useEffect(() => {
        if (players.length > 0) {
            setLoading(false);
            var group = [{
                groupName: "Women",
                baseline: "LPGA",
                players: []
            },
            {
                groupName: "Men",
                baseline: "PGA",
                players: []
            },
            {
                groupName: "Other Players",
                baseline: "",
                players: []
            }]
            for (const p of players) {
                const match = group.find(grp => grp.baseline === p.baseline);
                if (match && coachTeam === p.team && !coachesIds.includes(p.userId)) {
                    match.players.push(p);
                } else if (coachTeam !== p.team || coachesIds.includes(p.userId)) {
                    group.find(grp => grp.groupName === "Other Players")?.players.push(p)
                }
            }
            setGroupedPlayers(group);
        } else {
            setLoading(true)
        }
    }, [players])



    const groupedPlayerTemplate = (option) => {
        return (
            <div className="flex align-items-center" let-group>
                <div>{option.groupName}</div>
            </div>
        );
    };

    function allWomen(value) {
        setIsAllWomen(value);
        if (value) {
            const match = groupedPlayers.find(grp => grp.baseline === "LPGA");
            if (match) {
                let arr = selectedUsers.concat(match.players)
                setSelectedUsers(arr);
            }
        } else {
            const updatedArr = selectedUsers.filter(user => user.baseline !== "LPGA");
            setSelectedUsers(updatedArr)
        }
    }

    function allMen(value) {
        setIsAllMen(value);
        if (value) {
            const match = groupedPlayers.find(grp => grp.baseline === "PGA");
            if (match) {
                let arr = selectedUsers.concat(match.players)
                setSelectedUsers(arr);
            }
        } else {
            const updatedArr = selectedUsers.filter(user => user.baseline !== "PGA");
            setSelectedUsers(updatedArr)
        }
    }

    return (
        <div>
            <Row>

                <MultiSelect
                    value={selectedUsers}
                    onChange={(e) => setSelectedUsers(e.value)}
                    options={groupedPlayers}
                    optionLabel="name"
                    optionGroupLabel="groupName"
                    optionGroupChildren="players"
                    optionGroupTemplate={groupedPlayerTemplate}
                    display="chip"
                    filter
                    filterPlaceholder="Search Player"
                    placeholder={loading ? "Loading" : "Select Players"}
                    className={"w-full md:w-14rem"}
                    dropdownIcon={loading ? "pi pi-spinner" : "pi pi-chevron-down"}
                />

            </Row>
            <Row>
                <Col className="mt-2" lg="3">
                    <ToggleButton checked={isAllWomen} onChange={(e) => allWomen(e.value)}
                        onLabel="Show All Women" offLabel="Show All Women"
                        onIcon="pi pi-check" offIcon="pi pi-times" />
                </Col>
                <Col className="mt-2" lg="3">
                    <ToggleButton checked={isAllMen} onChange={(e) => allMen(e.value)}
                        onLabel="Show All Men" offLabel="Show All Men"
                        onIcon="pi pi-check" offIcon="pi pi-times" />
                </Col>

            </Row>
        </div >
    )
}