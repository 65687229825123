import { React, useState, useEffect } from "react";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Table,
  Container,
  Row,
  Col,
  UncontrolledDropdown,
} from "reactstrap";
import { getRoundsCallback, getRoundsCallbackList, getRoundsCustomCallback, getRoundsCustomCallbackList, getRoundsSemesterCallback, getRoundsSemesterCallbackList } from "./StatsAPICall";
import "../custom.css";
import { useAuth0 } from "@auth0/auth0-react";
import { DurationDropdown } from './dropdowns/DurationDropdown';
import { RoundTypeDropdown } from "./dropdowns/RoundTypeDropdown";
import { MultiSelectPlayers } from "./dropdowns/MultiSelectPlayers";
import { Message } from "primereact/message";
import { InputSwitch } from 'primereact/inputswitch';
import { Slider } from 'primereact/slider';
import { ProgressBar } from 'primereact/progressbar';
import { ToggleButton } from 'primereact/togglebutton';
import { Accordion, AccordionTab } from 'primereact/accordion';
import '../css/loading.css'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from "primereact/button";
import { DateInput } from "./DateInput";
import { useConstant } from '../ConstantContext';
export function CoachStats() {

  const [isMobile, setIsMobile] = useState(false);
  const [wasDataLoaded, setWasDataLoaded] = useState(false);
  /**Selected type of the round */
  const [roundType, setRoundType] = useState("All");
  /**Is custom range selected */
  const [custom, setCustom] = useState(false);
  /**Is tournament selected */
  const [selectTournament, setSelectTournament] = useState(false);
  /**What torunament is selected */
  const [tournament, setTournament] = useState("tournament");
  /**Start of custom date range */
  const [startDate, setStartDate] = useState(null);
  /**End of custom date range */
  const [endDate, setEndDate] = useState(null);
  /**Type of selected duration */
  var [durationType, setDurationType] = useState("number");
  /**Selected time duration in months */
  var [timeDuration, setTimeDuration] = useState(0);
  /**Selected last number of rounds */
  var [numberOfRounds, setNumberOfRounds] = useState(10);
  //TODO: Set up coach user id if auth
  //const [allUsers, setAllUsers] = useState(["118339364062165403224", "108255300905790625711"]);
  const [allUserAccounts, setAllUserAccounts] = useState([]);
  /**users selected in the dropdown */
  const [selectedUsers, setSelectedUsers] = useState([]);
  const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [activeTabs, setActiveTabs] = useState([0, 1, 2, 3, 4, 6, 7, 8])

  const [showAttempts, setShowAttempts] = useState(false);
  //Tournament List
  const listTournaments = ["tournament1", "tournament2"];
  //Custom Date Selected
  //const [customDate, setCustomDate] = useState(false);
  //Start of custom date range
  //const [startDate, setStartDate] = useState(null);
  //End of custom date range
  //const [endDate, setEndDate] = useState(null);
  //Type of selected duration
  const roundTypes = ["All", "Tournament", "Qualifying", "Practice"];
  /**Possible durations to filter rounds */
  const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
    "Last 20 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Fall", "Spring", "Custom"];

  const [displayedPlayers, setDisplayedPlayers] = useState([]);

  const [primeTableSize, setPrimeTableSize] = useState(isMobile ? 1 : 50)
  const [loading, setLoading] = useState(false);

  const [coachTeam, setCoachTeam] = useState(null)
  useEffect(() => {
    // Function to check and update screen size on resize
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Set breakpoint as per your requirement
      setPrimeTableSize(window.innerWidth <= 768 ? 0 : 50)
    };

    // Initial check for screen size
    handleResize();

    // Add event listener to check screen size on resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount to avoid memory leaks
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:myself'
          },
        });

        const response = await fetch(`api/User/myself?userId=${user.sub}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log(response.json())
        const responseData = await response.json();
        window.localStorage.setItem("team", responseData.team);
        if (responseData.bag !== null) {
          window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
        }
      } catch (error) {
        console.log(error.error)
        if (error.error === 'login_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          });
        } else if (error.error === 'consent_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          }, {
            appState: { targetUrl: window.location.href }
          });
        } else {
          console.error(error);
        }
      }
    }
    var team = window.localStorage.getItem("team");
    if (team) {
      setCoachTeam(team);
    } else {
      fetchUser();
    }
  }, [])
  const constants = useConstant();

  useEffect(() => {
    if (selectedUsers.length > 0) {
      setDisplayedPlayers(selectedUsers);
    }

  }, [selectedUsers])


  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:users'
          },
        });

        const response = await fetch('api/user', {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();
        setAllUserAccounts(responseData);
        //setDisplayedPlayers(responseData)
        setWasDataLoaded(true);
        //console.log(responseData)
      } catch (error) {
        //console.log(error.error)
        if (error.error === 'login_required') {
          loginWithRedirect();
        } else if (error.error === 'consent_required') {

          const token = await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          }, {
            appState: { targetUrl: window.location.href }
          });
        } else {
          //console.error(error);
        }
      }
    }

    fetchAllUsers();

  }, []);

  const generateTourAvgGeneralJSON = (tourAvg) => {
    const tourAvgGeneralJSON = {
      name: "Tour Avg.",
    };
    // Iterate over the fields in tourAvg and include them in tourAvgGeneralJSON
    for (const field in tourAvg) {
      if (tourAvg.hasOwnProperty(field)) {
        for (const field2 in tourAvg[field]) {
          if (tourAvg[field][field2].hasOwnProperty("value")) {
            if (tourAvgGeneralJSON[field]) {
              tourAvgGeneralJSON[field][field2] = tourAvg[field][field2].value;
            } else {
              tourAvgGeneralJSON[field] = {};
              tourAvgGeneralJSON[field][field2] = tourAvg[field][field2].value;
            }
          }
          else {
            tourAvgGeneralJSON[field] = tourAvg[field].value
          }
        }
      }
    }
    return tourAvgGeneralJSON;
  };

  // ...

  // Inside your component where you want to use the generated JSON
  const tourA = generateTourAvgGeneralJSON(constants.tourAvg);

  const generalColumns = [
    { field: 'noOfRounds', header: "# Rounds" },
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgPutting', header: "SG PUTT" },
    { field: 'sgARG', header: "SG ARG" },
    { field: 'sgApp', header: "SG APP" },
    { field: 'sgOTT', header: "SG OTT" },
  ]
  const [generalDataPrime, setGeneralDataPrime] = useState([tourA]);
  function getGeneral(name, data) {
    return {
      name: name,
      noOfRounds: data.noOfRounds,
      scoreAvg: data.scoreAvg,
      sgPutting: data.sgPutting,
      sgARG: data.sgARG,
      sgApp: data.sgApp,
      sgOTT: data.sgOTT
    }
  }

  //SCORING TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgScore = [
    "Tour Avg",
    71.09,
    3.07,
    4.04,
    4.66,
    3.61,
    2.67,
    0.32,
    0.04,
  ];
  const [scoreData, setScoreData] = useState([tourA]);
  var sampleScore = {
    headers: [
      "Name",
      "Score Avg.",
      "Par 3",
      "Par 4",
      "Par 5",
      "Birdies",
      "Bogeys",
      "Doubles",
      "Other",
    ],
    rows: scoreData,
  };
  //Organizes data from database to match headers
  function getScoreRow(name, data) {
    return [
      name,
      data.scoreAvg,
      data.par3Avg,
      data.par4Avg,
      data.par5Avg,
      data.birdiesPerRound,
      data.bogeysPerRound,
      data.doublesPerRound,
      data.otherPerRound,
    ];
  }

  const scoringColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'par3Avg', header: "Par 3" },
    { field: 'par4Avg', header: "Par 4" },
    { field: 'par5Avg', header: "Par 5" },
    { field: 'birdiesPerRound', header: "Birdies" },
    { field: 'bogeysPerRound', header: "Bogeys" },
    { field: 'doublesPerRound', header: "Doubles" },
    { field: 'otherPerRound', header: "Other" },
  ]

  var [scoringDataPrime, setScoringDataPrime] = useState([tourA])

  function getScoring(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      par3Avg: data.par3Avg,
      par4Avg: data.par4Avg,
      par5Avg: data.par5Avg,
      birdiesPerRound: data.birdiesPerRound,
      bogeysPerRound: data.bogeysPerRound,
      doublesPerRound: data.doublesPerRound,
      otherPerRound: data.otherPerRound,
    }
  }

  //APPROACH TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgApproach = [
    "Tour Avg.",
    "--",
    "--",
    "--",
    "--",
    "--",
    "11.8",
    "12.9",
    // "88%",
    // "78%",
    // "75%",
    // "70%",
    // "64%",
    // "55%",
    "85%",
    "72%",
    "59%",
    "44%",
    10.7,
    7,
    2.7,
    "--",
    "--",
    "--",
    "--",

  ];
  const [approachData, setApproachData] = useState([tourAvgApproach]);
  var sampleApproach = {
    headers: [
      "Name",
      "SG Total",
      "SG 50-100*",
      "SG 100-150*",
      "SG 150-200*",
      "SG 200+*",
      "GIR",
      "G/FIR",
      "GIR <100",
      "GIR 100-150",
      "GIR 150-200",
      "GIR 200+",
      "GIR to ≤40ft",
      "GIR to ≤20ft",
      "GIR to ≤8ft",
      "Miss left (%)",
      "Miss right (%)",
      "Miss long (%)",
      "Miss short (%)",
    ],
    rows: approachData,
  };
  //Organizes data from database to match headers
  function getApproachRow(name, data) {
    return [
      name,
      data.sgApp, //"Strokes Gained Total per Round"
      [data.sg50100Attempts, "--", data.sg50100], //"Strokes Gained 50-100 per Shot"
      [data.sg100150Attempts, "--", data.sg100150], //"Strokes Gained 100-150 per Shot"
      [data.sg150200Attempts, "--", data.sg150200], //"Strokes Gained 150-200 per Shot"
      [data.sg200Attempts, "--", data.sg200], //"Strokes Gained 200+ per Shot"
      [data.girAttempts, data.girSuccess, data.gir],
      [data.gOrfirAttempts, data.gOrfirSuccess, data.gOrfir], //gorFIR
      [data.gir100Attempts, data.gir100Success, data.gir100 + "%"], //"GIR <100"
      [data.gir100150Attempts, data.gir100150Success, data.gir100150 + "%"], //"GIR 100-150"
      [data.gir150200Attempts, data.gir150200Success, data.gir150200 + "%"], //"GIR 150-200"
      [data.gir200Attempts, data.gir200Success, data.gir200 + "%"], //"GIR 200+"
      [data.eGAttempts, data.eGSuccess, data.eGPerRound], //"Shots hit to 40ft or Less"
      [data.iPAttempts, data.iPSuccess, data.iPPerRound], //"Shots hit to 20ft or Less"
      [data.bjKAttempts, data.bjKSuccess, data.bjKPerRound], //"Shots hit to 8ft or Less"
      data.appMissLeft + "%", //"Miss left (%)"
      data.appMissRight + "%", //"Miss right (%)"
      data.appMissLong + "%", //"Miss long (%)"
      data.appMissShort + "%", //"Miss short (%)"
    ];
  }

  const approachColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgApp', header: 'SG' },
    { field: 'sg50100', header: 'SG 50-100*' },
    { field: 'sg100150', header: 'SG 100-150*' },
    { field: 'sg150200', header: 'SG 150-200*' },
    { field: 'sg200', header: 'SG 200+*' },
    { field: 'gir', header: 'GIR' },
    { field: 'gOrfir', header: 'G/FIR' },
    { field: 'gir100', header: 'GIR <100' },
    { field: 'gir100150', header: 'GIR 100-150' },
    { field: 'gir150200', header: 'GIR 150-200' },
    { field: 'gir200', header: 'GIR 200+' },
    { field: 'eGPerRound', header: 'GIR to ≤40ft' },
    { field: 'iPPerRound', header: 'GIR to ≤20ft' },
    { field: 'bjKPerRound', header: 'GIR to ≤8ft' },
    { field: 'appMissLeft', header: 'Miss left' },
    { field: 'appMissRight', header: 'Miss Right' },
    { field: 'appMissLong', header: 'Miss Long' },
    { field: 'appMissShort', header: 'Miss Short' },
    { field: 'easyMissPct', header: 'Miss to easy' },
    { field: 'mediumMissPct', header: 'Miss to medium' },
    { field: 'hardMissPct', header: 'Miss to hard' },
    { field: 'expected.average', header: 'Expected Strokes afer app' },
    { field: 'expected.hit', header: 'Expected Strokes afer app (green hit)' },
    { field: 'expected.missed', header: 'Expected Strokes afer app (green miss)' }


  ]
  var [approachDataPrime, setApproachDataPrime] = useState([tourA])
  function getApproach(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      sgApp: data.sgApp,
      sg50100: data.sg50100,
      sg100150: data.sg100150,
      sg150200: data.sg150200,
      sg200: data.sg200,
      gir: data.gir,
      gOrfir: data.gOrfir,
      gir100: data.gir100 + '%',
      gir100150: data.gir100150 + '%',
      gir150200: data.gir150200 + '%',
      gir200: data.gir200 + '%',
      eGPerRound: data.eGPerRound,
      iPPerRound: data.iPPerRound,
      bjKPerRound: data.bjKPerRound,
      appMissLeft: data.appMissLeft + '%',
      appMissRight: data.appMissRight + '%',
      appMissLong: data.appMissLong + '%',
      appMissShort: data.appMissShort + '%',
      easyMissPct: data.easyMissPct + '%',
      mediumMissPct: data.mediumMissPct + '%',
      hardMissPct: data.hardMissPct + '%',
      "expected.average": data.expected.average,
      "expected.hit": data.expected.averageHit,
      "expected.missed": data.expected.averageMissed,
    }

  }


  //SHORT GAME/ AROUND THE GREEN TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgSg = ["Tour Avg", "--", "--", "--", "58.3%", "50%", "67%",];
  const [sgData, setSgData] = useState([tourAvgSg]);
  var sampleSg = {
    headers: [
      "Name",
      "SG",
      "SG Fringe*",
      "SG Sand*",
      "Scrambling",
      "Chips Hit to ≤6ft",
      "Chips Hit to ≤12ft"
    ],
    rows: sgData,
  };
  //Organizes data from database to match headers
  function getSgRow(name, data) {
    return [
      name,
      data.sgARG, //"Strokes Gained Total per Round"
      [data.sgARGFringeAttempts, "--", data.sgARGFringe], //"Strokes Gained from Fringe per Shot"
      [data.sgARGSandAttempts, "--", data.sgARGSand], //"Strokes Gained from Sand per Shot"
      [data.scramblingAttempts, data.scramblingSuccess, data.scrambling + "%"], //"Scrambling"
      [data.p6Attempts, data.p6Success, data.p6 + "%"], //"Chips Hit to 6ft or Less"
      [data.p12Attempts, data.p12Success, data.p12 + "%"], //"Chips Hit to 12ft or Less"
    ];
  }

  const argColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgARG', header: 'SG' },
    { field: 'sgARG030', header: 'SG 0-30' },
    { field: 'sgARG3050', header: 'SG 30-50' },
    { field: 'sgARGFringe', header: 'SG Fringe*' },
    { field: 'sgARGSand', header: 'SG Sand*' },
    { field: 'easyChipARG', header: 'SG easy chip' },
    { field: 'scrambling', header: 'Scrambling' },
    { field: 'p6', header: 'Chips Hit to ≤6ft' },
    { field: 'p12', header: 'Chips Hit to ≤12ft' },
  ]

  var [argDataPrime, setArgDataPrime] = useState([tourA]);

  function getArg(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      sgARG: data.sgARG,
      sgARG030: data.sgARG030,
      sgARG3050: data.sgARG3050,
      sgARGFringe: data.sgARGFringe,
      sgARGSand: data.sgARGSand,
      easyChipSG: data.easyChipSG,
      scrambling: data.scrambling + '%',
      p6: data.p6 + '%',
      p12: data.p12 + '%',
    }
  }

  //PUTT TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgPutt = [
    "Tour Avg",
    "--",
    "99.53%",
    "87.87%",
    "56.29%",
    "45%",
    "45%",
    "10%",
    "--",
    `72'9"`,
  ];
  const [puttData, setPuttData] = useState([tourAvgPutt]);
  var samplePutt = {
    headers: [
      "Name",
      "SG",
      "Make Rate 3'",
      "Make Rate 3-5'",
      "Make Rate 5-10'",
      "SG inside 20' for Birdie",
      "SG inside 20' for Par",
      "Prox. of the First Putt >30'",
      "Total ft of 1st Putts",
      "Total ft of Putts Made",
    ],
    rows: puttData,
  };
  //Organizes data from database to match headers
  function getPuttRow(name, data) {
    return [
      name,
      data.sgPutting, //"Strokes gained"
      [data.attempts3, data.success3, data.makeRate3 + "%"], //"Make Rate 3'"
      [data.attempts35, data.success35, data.makeRate35 + "%"], //"Make Rate 3-5'"
      [data.attempts510, data.success510, data.makeRate510 + "%"], //"Make Rate 5-10'"
      [
        data.attempts515Birdie,
        data.success515Birdie,
        data.makeRate515Birdie + "%",
      ], //"Make Rate 5-15' for Birdie"
      [data.attempts515Par, data.success515Par, data.makeRate515Par + "%"], //"Make Rate 5-15' for Par"
      data.puttsOver30Proximity + "%", //"Proximity of the First Putt >20'"
      data.totalFirstPuttPerRound + "'", //"Total ft of First Putts Per Round"
      data.totalFeetMadePerRound + "'", //"Total ft of Putts Made"
    ];
  }


  const puttingColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgPutting', header: 'SG' },
    { field: 'makeRate3', header: "Make 3'" },
    { field: 'makeRate35', header: "Make 3-5'" },
    { field: 'makeRate510', header: "Make 5-10'" },
    { field: 'sgPutt.sg20Birdie', header: "SG <20' for Birdie" },
    { field: 'sgPutt.sg20Par', header: "SG <20' for Par" },
    { field: 'sgPuttingLagOver30Shot', header: "SG first putt 30'+*" },
    { field: 'totalFirstPuttPerRound', header: "Total ft of 1st Putts" },
    { field: 'totalFeetMadePerRound', header: "Total ft of Putts Made" },
  ]
  var [puttingDataPrime, setPuttingDataPrime] = useState([tourA]);

  function getPutting(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      sgPutting: data.sgPutting,
      makeRate3: data.makeRate3 + '%',
      makeRate35: data.makeRate35 + '%',
      makeRate510: data.makeRate510 + '%',
      "sgPutt.sg20Birdie": data.sgPutt.sg20Birdie,
      "sgPutt.sg20Par": data.sgPutt.sg20Par,
      sgPuttingLagOver30Shot: data.sgPuttingLagOver30Shot,
      totalFirstPuttPerRound: data.totalFirstPuttPerRound + "'",
      totalFeetMadePerRound: data.totalFeetMadePerRound + "'",
    }
  }

  //TEE TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgTee = [
    "Tour Avg",
    "--",
    "60%",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "--",
    "5.4%",
    "5.8%",
    "--",
    "--",
  ];
  const [teeData, setTeeData] = useState([tourAvgTee]);
  var sampleTee = {
    headers: [
      "Name",
      "SG",
      "Fairway Hit",
      "Fairway Hit Driver",
      "Fairway Hit 3w",
      "Fairway Hit 5w",
      "Fairway hit 3h",
      "Fairway hit 4h",
      "Fairway Hit 2i",
      "Fairway Hit 3i",
      "Fairway Hit 4i",
      "Tee Shots in Recovery",
      "Tee Shots in Sand",
      "Miss Right",
      "Miss Left",
    ],
    rows: teeData,
  };
  //Organizes data from database to match headers
  function getTeeRow(name, data) {
    return [
      name,
      data.sgOTT, //"Strokes gained per round"
      [data.teeAttempts, data.teeCount, data.teeHit + "%"], //"Fairway Hit"
      [
        data.teeDriverAttempts,
        data.teeDriverSuccess,
        data.fairwayDriverHit + "%",
      ], //"Fairway Hit Driver"
      [data.tee3wAttempts, data.tee3wSuccess, data.fairway3wHit + "%"], //"Fairway Hit 3w"
      [data.tee5wAttempts, data.tee5wSuccess, data.fairway5wHit + "%"], //"Fairway Hit 5w"
      [data.tee7wAttempts, data.tee7wSuccess, data.fairway7wHit + "%"], //"Fairway Hit 7w"
      [data.tee3hAttempts, data.tee3hSuccess, data.fairway3hHit + "%"], //"Fairway hit 3h"
      [data.tee4hAttempts, data.tee4hSuccess, data.fairway4hHit + "%"], //"Fairway hit 4h"
      [data.tee2iAttempts, data.tee2iSuccess, data.fairway2iHit + "%"], //"Fairway Hit 2i"
      [data.tee3iAttempts, data.tee3iSuccess, data.fairway3iHit + "%"], //"Fairway Hit 3i"
      [data.tee4iAttempts, data.tee4iSuccess, data.fairway4iHit + "%"], //"Fairway Hit 4i"
      [
        data.teeRecoveryAttempts,
        data.teeRecoveryCount,
        data.teeRecoveryHit + "%",
      ], //"Tee Shots in Recovery"
      [data.teeSandAttempts, data.teeSandCount, data.teeSandHit + "%"], //"Tee Shots in Sand"
      data.teeMissRight + "%", //"Miss Right"
      data.teeMissLeft + "%", //"Miss Left"
    ];
  }


  const ottColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'sgOTT', header: 'SG' },
    { field: 'teeHit', header: 'Fairway Hit' },
    { field: 'teeRecoveryHit', header: 'Tee Shots in Rec/Pen' },
    { field: 'teeSandHit', header: 'Tee Shots in Sand' },
    { field: 'fairwayDriverHit', header: 'Fairway Hit Driver' },
    { field: 'fairway3wHit', header: 'Fairway Hit 3w' },
    { field: 'fairway5wHit', header: 'Fairway Hit 5w' },
    { field: 'fairway7wHit', header: 'Fairway Hit 7w' },
    { field: 'fairway3hHit', header: 'Fairway Hit 3h' },
    { field: 'fairway4hHit', header: 'Fairway Hit 4h' },
    { field: 'fairway2iHit', header: 'Fairway Hit 2i' },
    { field: 'fairway3iHit', header: 'Fairway Hit 3i' },
    { field: 'fairway4iHit', header: 'Fairway Hit 4i' },
    { field: 'teeMissLeft', header: 'Miss Left' },
    { field: 'teeMissRight', header: 'Miss Right' }
  ]

  var [ottDataPrime, setOttDataPrime] = useState([tourA])

  function getOtt(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      sgOTT: data.sgOTT,
      teeHit: data.teeHit + '%',
      fairwayDriverHit: data.fairwayDriverHit + '%',
      fairway3wHit: data.fairway3wHit + '%',
      fairway5wHit: data.fairway5wHit + '%',
      fairway7wHit: data.fairway7wHit + '%',
      fairway3hHit: data.fairway3hHit + '%',
      fairway4hHit: data.fairway4hHit + '%',
      fairway2iHit: data.fairway2iHit + '%',
      fairway3iHit: data.fairway3iHit + '%',
      fairway4iHit: data.fairway4iHit + '%',
      teeRecoveryHit: data.teeRecoveryHit + '%',
      teeSandHit: data.teeSandHit + '%',
      teeMissLeft: data.teeMissLeft + '%',
      teeMissRight: data.teeMissRight + '%'
    }

  }
  //TIGER5 TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgTiger = ["Tour Avg", 0.16, 0.32, "90%", 0.53, 1.15];
  const [tigerData, setTigerData] = useState([tourAvgTiger]);
  var sampleTiger = {
    headers: [
      "Name",
      "Bogeys on Par 5",
      "Doubles+",
      "Easy u&d",
      "3-Putts",
      "Bogeys inside 150yds",
    ],
    rows: tigerData,
  };
  //Organizes data from database to match headers
  function getTigerRow(name, data) {
    return [
      name,
      [data.bogeyPar5Attempts, data.bogeyPar5Count, data.bogeyPar5PerRound], //"Bogeys on Par 5"
      ["--", data.doubleCount, data.doublePerRound], //"Doubles Bogeys"
      [data.easyChipAttempts, data.easyChipCount, data.easyChipPerRound + "%"], //"Easy chips"
      [data.threePuttAttempts, data.threePuttCount, data.threePuttPerRound], //"3-Putts"
      [data.bogey150Attempts, data.bogey150Count, data.bogey150PerRound], //"Bogeys inside 150yds"
    ];
  }
  const tigerColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'bogeyPar5PerRound', header: 'Bogeys on par 5' },
    { field: 'doublePerRound', header: 'Doubles+' },
    { field: 'easyChipPerRound', header: 'Easy u&d' },
    { field: 'threePuttPerRound', header: '3-putts' },
    { field: 'bogey150PerRound', header: `Bogeys inside ${constants.pgaBase ? "150" : "125y"}` },
  ]
  var [tigerDataPrime, setTigerDataPrime] = useState([tourA]);

  function getTiger(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      bogeyPar5PerRound: data.bogeyPar5PerRound,
      doublePerRound: data.doublePerRound,
      easyChipPerRound: data.easyChipPerRound + '%',
      threePuttPerRound: data.threePuttPerRound,
      bogey150PerRound: data.bogey150PerRound
    }
  }

  //MISTAKE TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  //No tour avg for mistake data
  const [mistakeData, setMistakeData] = useState([]);
  var sampleMistake = {
    headers: [
      "Name",
      "Good: OTT",
      "Technique: OTT",
      "Mental State: OTT",
      "Decision: OTT",
      "Good: APP",
      "Technique: APP",
      "Mental State: APP",
      "Decision: APP",
      "Good: ARG",
      "Technique: ARG",
      "Mental State: ARG",
      "Decision: ARG",
      "Good: PUTT",
      "Technique: PUTT",
      "Mental State: PUTT",
      "Decision: PUTT",
    ],
    rows: mistakeData,
  };
  //Organizes data from database to match headers
  function getMistakeRow(name, data) {
    return [
      name,
      data.goodTee + "%", //"Good Shots: Tee"
      data.techniqueTee + "%", //"Technique Error: Tee"
      data.mentalTee + "%", //"Mental State Error: Tee"
      data.decisionTee + "%", //"Decision Error: Tee"
      data.goodApp + "%", //"Good Shots: App"
      data.techniqueApp + "%", //"Technique Error: App"
      data.mentalApp + "%", //"Mental State Error: App"
      data.decisionApp + "%", //"Decision Error: App"
      data.goodArg + "%", //"Good Shots: Arg"
      data.techniqueArg + "%", //"Technique Error: Arg"
      data.mentalArg + "%", //"Mental State Error: Arg"
      data.decisionArg + "%", //"Decision Error: Arg"
      data.goodPutting + "%", //"Good Shots: Putting"
      data.techniquePutting + "%", //"Technique Error: Putting"
      data.mentalPutting + "%", //"Mental State Error: Putting"
      data.decisionPutting + "%", //"Decision Error: Putting"
    ];
  }

  //CLUB TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  //No tour avg for mistake data
  const [clubData, setClubData] = useState([]);
  var sampleClubs = {
    headers: [
      "Name",
      "SG LW*",
      "SG SW*",
      "SG GW*",
      "SG PW*",
      "SG 9i*",
      "SG 8i*",
      "SG 7i*",
      "SG 6i*",
      "SG 5i*",
      "SG 4i*",
      "SG 3i*",
      "SG 2i*",
      "SG 4h*",
      "SG 3h*",
      "SG 5w*",
      "SG 3w*",
    ],
    rows: clubData,
  };
  //Organizes data from database to match headers
  function getClubRow(name, data) {
    return [
      name,
      [data.attemptsLW, data.sgLW], //"SG LW per Shot"
      [data.attemptsSW, data.sgSW], //"SG SW per Shot"
      [data.attemptsGW, data.sgGW], //"SG GW per Shot"
      [data.attemptsPW, data.sgPW], //"SG PW per Shot"
      [data.attempts9i, data.sg9i], //"SG 9i per Shot"
      [data.attempts8i, data.sg8i], //"SG 8i per Shot"
      [data.attempts7i, data.sg7i], //"SG 7i per Shot"
      [data.attempts6i, data.sg6i], //"SG 6i per Shot"
      [data.attempts5i, data.sg5i], //"SG 5i per Shot"
      [data.attempts4i, data.sg4i], //"SG 4i per Shot"
      [data.attempts3i, data.sg3i], //"SG 3i per Shot"
      [data.attempts2i, data.sg2i], //"SG 2i per Shot"
      [data.attempts4h, data.sg4h], //"SG 4h per Shot"
      [data.attempts3h, data.sg3h], //"SG 3h per Shot"
      [data.attempts5w, data.sg5w], //"SG 5w per Shot"
      [data.attempts3w, data.sg3w], //"SG 3w per Shot"
    ];
  }

  //GFI TABLE HANDLER
  //Headers are hardcoded as they never change
  //Rows are generated when pulled from db, tourAvg is always the top row
  const tourAvgGfi = ["Tour Avg", "57%", "22%", "57%", "--", "24.5 yds"];
  const [gfiData, setGfiData] = useState([tourAvgGfi]);
  var sampleGfi = {
    headers: [
      "Name",
      "Going for the Green",
      "Green Hit Pct",
      "Birdie or Better",
      "Bogey or Worse",
      "Avg. Dst. after Going for it",
    ],
    rows: gfiData,
  };
  //Organizes data from database to match headers
  function getGfiRow(name, data) {
    return [
      name,
      [data.goingForItAttempts, data.goingForItCount, data.goingForItPct + "%"], //"Going for the Green"
      [
        data.goingGreenHitAttempts,
        data.goingGreenHitCount,
        data.goingGreenHitPct + "%",
      ], //"Going for the Green - Green Hit Pct"
      [
        data.goingBirdieAttempts,
        data.goingBirdieCount,
        data.goingBirdiePct + "%",
      ], //"Going for the Green - Birdie or Better"
      [data.goingBogeyAttempts, data.goingBogeyCount, data.goingBogeyPct + "%"], //"Going for the Green - Bogey or Worse"
      data.goingDistanceAfter + "yds", //"Average Distance after Going for it Shot"
    ];
  }


  const goingColumns = [
    { field: 'scoreAvg', header: "Score Avg." },
    { field: 'goingForItPct', header: 'Going for the Green' },
    { field: 'goingGreenHitPct', header: 'Green Hit %' },
    { field: 'goingBirdiePct', header: 'Birdie or Better %' },
    { field: 'goingBogeyPct', header: 'Bogey or Worse %' },
    { field: 'goingDistanceAfter', header: 'Avg. Dst. after Going for it' },
    { field: 'avgGoing', header: 'Going for it avg.' },
    { field: 'avgLayUp', header: 'Lay up avg.' },

  ]

  var [goingDataPrime, setGoingDataPrime] = useState([tourA]);
  function getGoing(name, data) {
    return {
      name: name,
      scoreAvg: data.scoreAvg,
      goingForItPct: data.goingForItPct + '%',
      goingGreenHitPct: data.goingGreenHitPct + '%',
      goingBirdiePct: data.goingBirdiePct + '%',
      goingBogeyPct: data.goingBogeyPct + '%',
      goingDistanceAfter: data.goingDistanceAfter + " yds",
      avgGoing: data.avgGoing,
      avgLayUp: data.avgLayUp
    }

  }

  function getDataType(cell, isClubs) {
    if (showAttempts) {
      if (!isClubs) {
        return <div><li>{cell[1]}/{cell[0]}</li><li>{cell[2]}</li></div>
      } else {
        return <div><li>Att: {cell[0]}</li><li>SG: {cell[1]}</li></div>
      }
    } else {
      if (!isClubs) {
        return <div><li>{cell[2]}</li></div>
      } else {
        return <div><li>{cell[1]}</li></div>
      }
    }

  }

  function getUserName(userName) {
    return userName;
  }

  //Pulls data from database and updates data rows for each table
  function processRounds(userId, data, userName) {
    var name = getUserName(userName);
    setScoreData((scoreData) => [...scoreData, getScoreRow(name, data)]);
    setApproachData((approachData) => [
      ...approachData,
      getApproachRow(name, data),
    ]);
    setSgData((sgData) => [...sgData, getSgRow(name, data)]);
    setPuttData((puttData) => [...puttData, getPuttRow(name, data)]);
    setTeeData((teeData) => [...teeData, getTeeRow(name, data)]);
    setTigerData((tigerData) => [...tigerData, getTigerRow(name, data)]);

    setMistakeData((mistakeData) => [
      ...mistakeData,
      getMistakeRow(name, data),
    ]);
    setGfiData((gfiData) => [...gfiData, getGfiRow(name, data)]);
    setClubData((clubData) => [...clubData, getClubRow(name, data)]);

    //prime setters
    setTigerDataPrime((tigerDataPrime) => [...tigerDataPrime, getTiger(name, data)])
    setScoringDataPrime((scoringDataPrime) => [...scoringDataPrime, getScoring(name, data)])
    setApproachDataPrime((approachDataPrime) => [...approachDataPrime, getApproach(name, data)])
    setArgDataPrime((argDataPrime) => [...argDataPrime, getArg(name, data)]);
    setPuttingDataPrime((puttingDataPrime) => [...puttingDataPrime, getPutting(name, data)])
    setOttDataPrime((ottDatePrime) => [...ottDatePrime, getOtt(name, data)]);
    setGoingDataPrime((goingDataPrime) => [...goingDataPrime, getGoing(name, data)]);
    setGeneralDataPrime((generalDataPrime) => [...generalDataPrime, getGeneral(name, data)])

    setWasDataLoaded(true);
  }

  //Creates a table based on data and if it is visible or not
  function generateTable(data, isClubs) {
    return (
      <div>
        <div className="table-container" style={{ overflowX: "scroll" }}>
          <Table className="table" style={{ whiteSpace: "nowrap" }}>
            <thead>
              <tr>
                {data.headers.map((header) => (
                  <th
                    key={header}
                    className={header === "Name" ? "name" : "header"}
                    style={{ textAlign: "center" }}
                  >
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.rows.map((row, rowIndex) => (
                <tr
                  key={rowIndex}
                  className={` ${rowIndex % 2 === 0 ? "even" : "odd"}`}
                >
                  {row.map((cell, cellIndex) => (
                    <td
                      key={cellIndex}
                      className={!wasDataLoaded ?
                        `loading-cell ${cellIndex === 0
                          ? rowIndex % 2 === 0
                            ? "name1"
                            : "name2"
                          : "data"}` : ` ${cellIndex === 0
                            ? rowIndex % 2 === 0
                              ? "name1"
                              : "name2"
                            : "data"}`
                      }
                      style={
                        cellIndex === 0
                          ? {
                            position: "sticky",
                            top: "0",
                            zIndex: "15",
                            width: "auto",
                            padding: "9px",
                            left: "0",
                            backgroundColor: "white",
                            textAlign: "center",
                          }
                          : { textAlign: "center" }
                      }
                    >
                      {Array.isArray(cell) ? (
                        // If the cell is an array of data values, map through it
                        <ul
                          className="uList"
                          style={{
                            listStyleType: "none",
                            justifyContent: "center",
                            margin: "0",
                            padding: "0",
                          }}
                        >

                          {getDataType(cell, isClubs)}
                        </ul>
                      ) : (
                        // Otherwise, render the cell as a regular table cell
                        cell
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }

  const cellClass = (data, obj) => {
    if (obj.field !== "name") {
      var nameArr = obj.field.split('.')
      var objName = "";
      var name1 = obj.field.split('.')[0];
      if (nameArr.length > 1) {
        var name2 = obj.field.split('.')[1];
        objName = `constants.tourAvg["${name1}"]["${name2}"]`
      } else {
        objName = `constants.tourAvg["${name1}"]`
      }
      if (parseFloat(data) < parseFloat(constants.tourAvg[obj.field]?.redValue) && !constants.tourAvg[obj.field]?.low) {
        return {
          'bg-danger bg-gradient text-white': true
        }
      } else if (parseFloat(data) < parseFloat(constants.tourAvg[obj.field]?.greenValue) && constants.tourAvg[obj.field]?.low) {
        return {
          'bg-success bg-gradient text-white': true
        }
      } else if (parseFloat(data) > parseFloat(constants.tourAvg[obj.field]?.redValue) && constants.tourAvg[obj.field]?.low) {
        return {
          'bg-danger bg-gradient text-white': true
        }
      } else if (parseFloat(data) > parseFloat(constants.tourAvg[obj.field]?.greenValue) && !constants.tourAvg[obj.field]?.low) {
        return {
          'bg-success bg-gradient text-white': true
        }
      }
    }
  }

  const rowClass = (data) => {
    return {
      'fw-bold': data.name === "Tour Avg." || data.name === "Team Avg."
    }
  }


  function generatePrimeTables(data, columns) {
    return (
      <div>
        <DataTable size={primeTableSize < 20 ? "small" : primeTableSize > 80 ? "large" : "normal"} scrollable
          value={data} rowClassName={rowClass} cellClassName={cellClass}
          removableSort reorderableColumns>
          <Column className="font-weight-bold" field="name" header="Name" frozen></Column>
          {columns.map((col, i) => (
            <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ textAlign: "center" }} bodyStyle={{ textAlign: "center" }}></Column>
          ))}
        </DataTable>
        {!wasDataLoaded && <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>}
      </div>
    )
  }

  function loadData() {
    setLoading(true);
    //For each user active
    //userId, durationType, timeDuration, numberOfRounds, roundType, callback
    setScoreData([tourAvgScore]);
    setApproachData([tourAvgApproach]);
    setSgData([tourAvgSg]);
    setPuttData([tourAvgPutt]);
    setTeeData([tourAvgTee]);
    setTigerData([tourAvgTiger]);

    setMistakeData([]);
    setClubData([]);
    setGfiData([tourAvgGfi]);

    //prime setters
    setTigerDataPrime([tourA])
    setScoringDataPrime([tourA])
    setApproachDataPrime([tourA])
    setArgDataPrime([tourA])
    setPuttingDataPrime([tourA])
    setOttDataPrime([tourA])
    setGoingDataPrime([tourA])
    setGeneralDataPrime([tourA])


    if (custom && startDate !== null && endDate !== null) {
      getRoundsCustomCallbackList(
        displayedPlayers,
        startDate,
        endDate,
        roundType,
        (data) => processRounds(1, data, "Team Avg.")
      )
      displayedPlayers.map((user) =>
        getRoundsCustomCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          startDate,
          endDate,
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name)
        )
      );
    } else if (durationType.toLowerCase() === "fall") {
      getRoundsSemesterCallbackList(
        displayedPlayers,
        "2024-08-25",
        "2024-10-29",
        roundType,
        (data) => processRounds(1, data, "Team Avg.")
      )
      displayedPlayers.map((user) =>
        getRoundsSemesterCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          "2023-08-25",
          "2023-10-29",
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name)
        )
      );
    } else if (durationType.toLowerCase() === "spring") {
      getRoundsSemesterCallbackList(
        displayedPlayers,
        "2023-02-10",
        "2023-05-01",
        roundType,
        (data) => processRounds(1, data, "Team Avg.")
      )
      displayedPlayers.map((user) =>
        getRoundsSemesterCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          "2023-02-10",
          "2023-05-01",
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name)
        )
      );
    } else {
      getRoundsCallbackList(
        displayedPlayers,
        durationType,
        timeDuration,
        numberOfRounds,
        roundType,
        (data) => processRounds(1, data, "Team Avg.")
      )
      displayedPlayers.map((user) =>
        getRoundsCallback(
          user.userId.substring(user.userId.indexOf('|') + 1),
          durationType,
          timeDuration,
          numberOfRounds,
          roundType,
          (data) => processRounds(user.userId.substring(user.userId.indexOf('|') + 1), data, user.name)
        )
      );
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }


  /**
   * Renders dropdowns for filtering out rounds.
   * If custom is selected it will render two date inputs.
   * If tournament is selected it will render dropdown with tournaments played.
   */
  function renderFilters() {
    if (!custom && !selectTournament) {
      return (
        <div>
          <Row>
            <Col xs="2" lg="2">
              <div>Show attempts</div>
              <InputSwitch checked={showAttempts} onChange={(e) => setShowAttempts(e.value)} />
            </Col>
            <Col xs="5" lg="1">
              <ToggleButton checked={constants.pgaBase} onChange={(e) => { constants.setPgaBase(e.value); }} onLabel="PGA" offLabel="LPGA" />
            </Col>
            <Col xs="8" lg="2" className="mb-2">
              <div>{primeTableSize < 20 ? "Small" : primeTableSize > 80 ? "Large" : "Normal"}</div>
              <Slider value={primeTableSize} step={50} onChange={(e) => setPrimeTableSize(e.value)} />
            </Col>
            <Col xs="4" md="2" lg="2">
              <RoundTypeDropdown setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} setTournamentSelected={() => console.log()} />
            </Col>
            <Col xs="4" md="3" lg="2">
              <DurationDropdown setNumberOfRounds={setNumberOfRounds}
                setTimeDuration={setTimeDuration} setCustom={setCustom} resetSwitches={() => console.log("no switches")}
                setDurationType={setDurationType} durations={durations} />
            </Col>
            <Col className="mt-3" xs="12" md="12" lg="12">
              <MultiSelectPlayers players={allUserAccounts} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} coachTeam={coachTeam} />
            </Col>
          </Row>
        </div>
      );
    } else if (custom) {
      return (
        <div>
          <Row>
            <Col xs="2" lg="2">
              <div>Show attempts</div>
              <InputSwitch checked={showAttempts} onChange={(e) => setShowAttempts(e.value)} />
            </Col>
            <Col xs="5" lg="1">
              <ToggleButton checked={constants.pgaBase} onChange={(e) => { constants.setPgaBase(e.value); }} onLabel="PGA" offLabel="LPGA" />
            </Col>
            <Col xs="8" lg="2" className="mb-2">
              <div>{primeTableSize < 20 ? "Small" : primeTableSize > 80 ? "Large" : "Normal"}</div>
              <Slider value={primeTableSize} step={50} onChange={(e) => setPrimeTableSize(e.value)} />
            </Col>
            <Col xs="8" md="2" lg="2">
              <RoundTypeDropdown setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} setTournamentSelected={() => console.log()} />
            </Col>
            <Col xs="4" md="3" lg="2">
              <DurationDropdown setNumberOfRounds={setNumberOfRounds}
                setTimeDuration={setTimeDuration} setCustom={setCustom} resetSwitches={() => console.log("no switches")}
                setDurationType={setDurationType} durations={durations} />
            </Col>
          </Row>
          <Row className="mt-3 mb-4">
            <Col lg="10">
              <div style={{ display: "flex", justifyContent: "left", gap: "10px" }}>
                <label htmlFor='startDate'>Start Date</label>
                <DateInput id="startDate" selectedDate={startDate} setSelectedDate={setStartDate} />
                <label htmlFor='endDate'>End Date</label>
                <DateInput id="endDate" selectedDate={endDate} setSelectedDate={setEndDate} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12" className="mt-3">
              <MultiSelectPlayers players={allUserAccounts} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} coachTeam={coachTeam} />
            </Col>
          </Row>
        </div>
      );
    } else if (selectTournament) {
      return (
        <div>
          <Row>
            <Col xs="4" md="2" lg="2">
            </Col>
            <Col xs="8" md="2" lg="2">
              <UncontrolledDropdown value={roundType}>
                <DropdownToggle caret color="primary">
                  {tournament}
                </DropdownToggle>
                <DropdownMenu>
                  {listTournaments.map((tour) => (
                    <DropdownItem
                      key={tour}
                      value={tour}
                      onClick={(event) => setTournament(event.target.value)}
                    >
                      {tour}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>
      );
    }
  }
  function openAll() {
    if (activeTabs.length > 0) {
      setActiveTabs([])
    } else {

      setActiveTabs([0, 1, 2, 3, 4, 5, 6, 7, 8])
    }
  }
  useEffect(() => {
    if (selectedUsers.length > 0) {
      loadData();
    }
  }, [constants.pgaBase])

  return (
    <div>
      <Container className="ps-0">

        {renderFilters()}
        <Row className="mt-3">
          <Col lg="1">
            <Button label="Go" icon="pi pi-check" onClick={() => loadData()} loading={loading} />
          </Col>
        </Row>
        <Row className="mt-3 mb-5">
          <Col>
            <Button label={activeTabs.length > 0 ? "Close All" : "Open All"}
              icon={activeTabs.length > 0 ? "pi pi-minus" : "pi pi-plus"}
              className="mb-4" onClick={() => openAll()} />
            <Accordion activeIndex={activeTabs} multiple onTabChange={(e) => setActiveTabs(e.index)}>
              <AccordionTab header="General"  >
                {generatePrimeTables(generalDataPrime, generalColumns)}
              </AccordionTab>
              <AccordionTab header="Tiger 5"  >
                {showAttempts ? generateTable(sampleTiger, false) : generatePrimeTables(tigerDataPrime, tigerColumns)}
              </AccordionTab>
              <AccordionTab header="Scoring">
                {generatePrimeTables(scoringDataPrime, scoringColumns)}
              </AccordionTab>
              <AccordionTab header="Off the Tee">
                {showAttempts ? generateTable(sampleTee, false) : generatePrimeTables(ottDataPrime, ottColumns)}
              </AccordionTab>
              <AccordionTab header="Approaches">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                {showAttempts ? generateTable(sampleApproach, false) : generatePrimeTables(approachDataPrime, approachColumns)}
              </AccordionTab>
              <AccordionTab header="Clubs">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                {generateTable(sampleClubs, true)}
              </AccordionTab>
              <AccordionTab header="Going for It">
                {showAttempts ? generateTable(sampleGfi, false) : generatePrimeTables(goingDataPrime, goingColumns)}
              </AccordionTab>
              <AccordionTab header="Around the Green">
                <Message className='mb-0 mt-0 m-1' severity="info" text="*per shot" />
                {showAttempts ? generateTable(sampleSg, false) : generatePrimeTables(argDataPrime, argColumns)}
              </AccordionTab>
              <AccordionTab header="Putting">
                {showAttempts ? generateTable(samplePutt, false) : generatePrimeTables(puttingDataPrime, puttingColumns)}
              </AccordionTab>
              <AccordionTab header="Mistakes">
                {generateTable(sampleMistake, false)}
              </AccordionTab>
            </Accordion>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
