import React, { useState, useEffect } from 'react';
import '../custom.css'

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
const StrokesGainedHolesTable = ({ data, header }) => {

    const [isMobile, setIsMobile] = useState(false);
    const getCellStyles = (value) => {
        let backgroundColor = '';
        let fontColor = '';


        if (value > 0) {
            backgroundColor = `rgba(0, 128, 0, ${value + 0.2})`;

        }
        else if (value < 0) {
            backgroundColor = `rgba(255, 0, 0, ${Math.abs(value) + 0.2})`;

        }
        if (Math.abs(value) + 0.2 > 0.7) {
            fontColor = 'white'
        }

        return { backgroundColor, color: fontColor };
    };

    useEffect(() => {
        // Function to check and update screen size on resize
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Set breakpoint as per your requirement
        };

        // Initial check for screen size
        handleResize();

        // Add event listener to check screen size on resize
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount to avoid memory leaks
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return (
        <div>
            {isMobile ?
                <div>
                    <DataTable value={[data]}
                        size='small'
                        tableStyle={{ maxWidth: '1rem' }}
                        showGridlines
                        id='holes-table'
                        className='mb-2'
                    >
                        {[...Array(9)].map((_, index) => (
                            <Column
                                key={index}
                                field={(index).toString()}
                                header={index + 1}
                                alignHeader={'center'}
                                style={{ padding: "0.1rem" }}
                                body={(rowData) => {
                                    const value = rowData[(index).toString()];
                                    const cellStyles = getCellStyles(value);
                                    return (
                                        <span id="hole-cell"
                                            style={{
                                                backgroundColor: cellStyles.backgroundColor,
                                                color: cellStyles.color,
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                textAlign: 'center'

                                            }}>
                                            {value}
                                        </span>
                                    );
                                }} />
                        ))}
                    </DataTable>
                    <DataTable value={[data]}
                        size='small'
                        tableStyle={{ maxWidth: '1rem' }}
                        showGridlines
                        id='holes-table'
                    >
                        {[...Array(9)].map((_, index) => (
                            <Column
                                key={index + 9}
                                field={(index + 9).toString()}
                                header={index + 10}
                                alignHeader={'center'}
                                style={{ padding: "0.1rem" }}
                                body={(rowData) => {
                                    const value = rowData[(index + 9).toString()];
                                    const cellStyles = getCellStyles(value);
                                    return (
                                        <span id="hole-cell"
                                            style={{
                                                backgroundColor: cellStyles.backgroundColor,
                                                color: cellStyles.color,
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                textAlign: 'center'

                                            }}>
                                            {value}
                                        </span>
                                    );
                                }} />
                        ))}
                    </DataTable>
                </div>
                :
                <DataTable value={[data]}
                    size='small'
                    tableStyle={{ maxWidth: '1rem' }}
                    showGridlines
                    id='holes-table'
                >
                    {[...Array(18)].map((_, index) => (
                        <Column
                            key={index}
                            field={(index).toString()}
                            header={index + 1}
                            alignHeader={'center'}
                            body={(rowData) => {
                                const value = rowData[(index).toString()];
                                const cellStyles = getCellStyles(value);
                                return (
                                    <span id="hole-cell"
                                        style={{
                                            backgroundColor: cellStyles.backgroundColor,
                                            color: cellStyles.color,
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            textAlign: 'center'

                                        }}>
                                        {value}
                                    </span>
                                );
                            }} />
                    ))}
                </DataTable>}
        </div>
    )
}

export { StrokesGainedHolesTable }