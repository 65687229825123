import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
const GetScoresPutting = ({ player, setSeeDetails, setUserDetailsSelected }) => {
    const [lastScore, setLastScore] = useState(null);
    const [last5Score, setLast5Score] = useState(null);
    const [last10Score, setLast10Score] = useState(null);
    const [last30Score, setLast30Score] = useState(null);

    const tourAvg = 28.5;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const id = player.userId.substring(player.userId.indexOf('|') + 1);
                const response = await fetch(`/api/puttingpractice/user-last?userId=${id}&numberOfRounds=30`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const data = await response.json();

                if (data) {
                    let sum30 = 0;
                    let sum10 = 0;
                    let sum5 = 0;
                    let lastOne = data[0].scores.reduce((sum1, score) => sum1 + score, 0);
                    let noR10 = 0;
                    let noR5 = 0;
                    let noR = 0;

                    for (let i = 0; i < data.length; i++) {
                        sum30 += data[i].scores.reduce((sum, score) => sum + score, 0);
                        if (i < 10) {
                            sum10 += data[i].scores.reduce((sum1, score) => sum1 + score, 0);
                            noR10 = i;
                            if (i < 5) {
                                sum5 += data[i].scores.reduce((sum2, score) => sum2 + score, 0);
                                noR5 = i;
                            }
                        }
                        noR = i;


                    }
                    setLast30Score((tourAvg - sum30 / (noR + 1)).toFixed(2));
                    setLast10Score((tourAvg - sum10 / (noR10 + 1)).toFixed(2));
                    setLast5Score((tourAvg - sum5 / (noR5 + 1)).toFixed(2));
                    setLastScore((tourAvg - lastOne).toFixed(2));
                }
            } catch (error) {
                setLast30Score("--");
                setLast10Score("--");
                setLast5Score("--");
                setLastScore("--");
            }
        };

        fetchData();
    }, [player]);

    function selectDetails(player) {
        setSeeDetails(true);
        setUserDetailsSelected(player);
    }

    return (
        <tr key={player.userId}>
            <td>{player.name}</td>
            <td style={{ color: lastScore < 0 ? "red" : lastScore > 0 ? "green" : "" }}>{lastScore}</td>
            <td style={{ color: last5Score < 0 ? "red" : last5Score > 0 ? "green" : "" }}>{last5Score}</td>
            <td style={{ color: last10Score < 0 ? "red" : last10Score > 0 ? "green" : "" }}>{last10Score}</td>
            <td style={{ color: last30Score < 0 ? "red" : last30Score > 0 ? "green" : "" }}>{last30Score}</td>
            <td><Button size="small" severity="secondary" label="See Details" onClick={() => selectDetails(player)} /></td>
        </tr>
    );
};

export { GetScoresPutting };
