import React from "react"

const Footer = () => <footer className="page-footer font-small blue pt-4">
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-6 mt-md-0 mt-3">
                <h5 className="text-uppercase">Parfect Performance</h5>
                <p>Built with equal parts caffeine and code! </p>
            </div>

            <hr className="clearfix w-100 d-md-none pb-0"/>

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">Policies</h5>
                <ul className="list-unstyled">
                    <li><a href="#!">TERM OF USE</a></li>
                    <li><a href="#!">PRIVACY NOTICE</a></li>
                    <li><a href="#!">COOKIE POILICY</a></li>
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase">About</h5>
                <ul className="list-unstyled">
                    <li><a href="#!">CONTACT</a></li>
                    <li><a href="#!">ABOUT</a></li>
    
                </ul>
            </div>
        </div>
    </div>

    <div className="footer-copyright text-center py-2">© 2023 Copyright:
        <a href="https://parfectperformance.com/"> ParfectPerformance.com </a>
        <p>ALL RIGHTS RESERVED</p>
    </div>

</footer>

export default Footer