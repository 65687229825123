import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Col,
  Container,
  Row
} from "reactstrap";

import { AddTee } from "./AddTee";
import { DisplayTees } from "./DisplayTees";

function CourseDetails(props) {

  // Data
  const [selectedCourse, setSelectedCourse] = useState(props.course);
  const teeNameRef = useRef("");

  // Course Details Page Helper
  const [clickedAddTee, setClickedAddTee] = useState(false);

  // Course Details Page States
  const [showCourseDetailsPage, setshowCourseDetailsPage] = useState(true);




  const handleAddTeeBtn = () => {

    setClickedAddTee(true)
    console.log("CourseDetails.js - You clicked the add tee button!");
  }

  const handleCancelTeeBtn = () => {
    console.log("CourseDetails.js - You clicked the cancel tee button!");
    setClickedAddTee(false)
  }
  function sanitizeInput(str) {
    while (str.charAt(0) === " ") {
      str = str.substring(1);
    }
    while (str.charAt(str.length - 1) === " ") {
      str = str.substring(0, str.length - 1);
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const handleSubmitTeeBtn = (parData, disData) => {
    console.log("CourseDetails.js - You clicked the submit tee button!");

    const parObj = parData.reduce((acc, cur, i) => {
      acc[i + 1] = cur;
      return acc;
    }, {});

    const disObj = disData.reduce((acc, cur, i) => {
      acc[i + 1] = cur;
      return acc;
    }, {});


    const data = {
      teeName: sanitizeInput(teeNameRef.current.value),
      holePar: parObj,
      holeDist: disObj
    };

    console.log(data);

    // Send a PATCH request to the API endpoint
    fetch(`/api/Course/tee/${selectedCourse.id}`, {
      method: 'PATCH',
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      }
    })


  }

  // Course Logger
  useEffect(() => {
    setSelectedCourse(props.course);
    if (selectedCourse != null) {
      console.log("CourseDetails.js - selectedCourse is:", selectedCourse);
      console.log("CourseDetails.js - The course name is:", selectedCourse.courseName);
      if (selectedCourse.teeSet != null) {
        console.log("CourseDetails.js - The teeSet is:", selectedCourse.teeSet);
        console.log("CourseDetails.js - The first tee in the teeSet is:", selectedCourse.teeSet[0]);
        console.log("CourseDetails.js - The second tee in the teeSet is:", selectedCourse.teeSet[1]);
      }
      console.log("CourseDetails.js - The number of tees in the teeSet is:", selectedCourse.numOfTees);
    }
  }, [selectedCourse]);


  return (
    <div>
      <Container className="ps-0">
        <Row className="no-gutters">
          <Col>
            <h2 className="mb-3">{selectedCourse?.courseName ?? "Loading..."}</h2>
            <p>{selectedCourse?.city + ", " + selectedCourse?.country ?? "Loading..."}</p>
          </Col>
          <Col>
            <div className="d-flex justify-content-end mb-3">
              <Button onClick={props.onClick} className="btn-danger float-right">
                Back To Courses
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
      <hr />


      {!clickedAddTee && showCourseDetailsPage ? (
        <div>
          {/* <AllTees onClick={handleTeeButton} teeName={teeName}/> */}
          <DisplayTees allTees={selectedCourse?.teeSet} onClick={handleAddTeeBtn} />
          {selectedCourse?.numOfTees == 0 ? (
            <h3>You have no Tees</h3>
          ) : (
            <></>
          )}
        </div>
      ) : clickedAddTee ? (
        <div className="group-components">
          <AddTee teeNameRef={teeNameRef} SelectedCourse={selectedCourse} onClick1={handleCancelTeeBtn} onClick2={handleSubmitTeeBtn} />
        </div>
      ) : (
        <></>
      )}


    </div>
  );
}

export { CourseDetails };