import React, { useState, useEffect } from "react";
import {
    Container, Table, Row, Col
} from "reactstrap";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { useAuth0 } from "@auth0/auth0-react";
const NewApproachPractice = ({ lowDistance, highDistance, setStartNewApproach, setShowApproachResult, setLowDistance }) => {
    const rows = Array.from({ length: 20 }, (_, i) => i + 1);
    const [currentRow, setCurrentRow] = useState(0);
    const { user } = useAuth0();
    const userId = user.sub.substring(user.sub.indexOf('|') + 1);
    const [distance, setDistance] = useState([]);
    var [missDir, setMissDir] = useState(Array(20).fill(0));
    var [missDst, setMissDst] = useState(Array(20).fill(0));
    const [practiceId, setPracticeId] = useState(null);

    function shuffleArray() {
        let array = Array(20).fill(0);
        let temp_low = lowDistance;
        for (let i = 0; i < 20; i++) {
            array[i] = Math.floor(temp_low + Math.random() * 10);
            temp_low += 10;
            if (temp_low >= highDistance) {
                temp_low = lowDistance;
            }
        }
        setDistance(array)
    }

    const updateDistanceArray = (index, value) => {
        const newArray = [...missDst];
        newArray[index - 1] = Number(value);
        setMissDst(newArray);
    };
    const updateDirectionArray = (index, value) => {
        const newArray = [...missDir];
        newArray[index - 1] = Number(value);
        setMissDir(newArray);
    };


    useEffect(() => {
        shuffleArray();
    }, [lowDistance, highDistance])

    useEffect(() => {
        if (distance.length > 0)
            fetch('/api/approachpractice', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ userId: userId, date: new Date().toISOString(), lowDistance: lowDistance, highDistance: highDistance, distances: distance, missDir: missDir, missDst: missDst })
            })
                .then(response => response.json())
                .then(data => {
                    setPracticeId(data.id)
                })
                .catch(error => console.error(error));
    }, [distance])

    useEffect(() => {
        fetch(`/api/approachpractice?id=${practiceId}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ missDir: missDir, missDst: missDst })
        })
            .then(response => response.json())
            .then(data => {
            })
            .catch(error => console.error(error));
    }, [missDir, missDst])

    function submitApproach() {
        setShowApproachResult(true);
        setStartNewApproach(false);
    }

    return (
        <Container >
            <Row>
                <h1>{lowDistance}-{highDistance} Combine</h1>
            </Row>
            <Row>
                <Col md="12" lg="12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>Distance (yds)</th>
                                <th>Miss direction (L/R)</th>
                                <th>Miss distance (S/L)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>
                                    {currentRow + 1}
                                </td>
                                <td>
                                    {distance[currentRow]}
                                </td>
                                <td>
                                    <InputNumber
                                        onValueChange={(e) => updateDirectionArray(currentRow + 1, e.target.value)}
                                        value={missDir[currentRow]}
                                        showButtons buttonLayout="vertical"
                                        className="p-fluid"
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                        maxLength={2}
                                        min={0}
                                    />
                                </td>
                                <td>
                                    <InputNumber
                                        onValueChange={(e) => updateDistanceArray(currentRow + 1, e.target.value)}
                                        value={missDst[currentRow]}
                                        showButtons buttonLayout="vertical"
                                        className="p-fluid"
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                        maxLength={2}
                                        min={0}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    <span className="p-buttonset" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button className="m-1" label="Previous" icon="pi pi-arrow-circle-left" severity="secondary" onClick={() => { setCurrentRow(currentRow - 1) }} style={{ width: "45%" }} />
                        {currentRow === 19 ? <Button className="m-1" label="Submit" icon="pi pi-check" iconPos="right" severity="success" onClick={() => { submitApproach(); }} style={{ width: "45%" }} /> :
                            <Button className="m-1" icon="pi pi-arrow-circle-right" iconPos="right" label="Next" severity="secondary" onClick={() => { setCurrentRow(currentRow + 1) }} style={{ width: "45%" }} />
                        }
                    </span>
                </Col>
            </Row>
            <Row className="table-container mt-3">
                <Col md="12" lg="12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>Distance</th>
                                <th>Miss direction (Left/Right)</th>
                                <th>Miss distance (Short/Long)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr style={{ backgroundColor: currentRow + 1 === row ? 'red' : '' }} key={row}>
                                    <td>
                                        {row}
                                    </td>
                                    <td>
                                        {distance[row - 1]}
                                    </td>
                                    <td>
                                        {missDir[row - 1]}
                                    </td>
                                    <td>
                                        {missDst[row - 1]}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container >
    )
}

export { NewApproachPractice }