import React, { useState, useEffect } from "react";
import { Row, Col, Container, Alert, UncontrolledAlert } from "reactstrap";
import RoundTable from "./RoundTable";
import HomeStrokesGained from "./HomeStrokesGained";
import HomeTiger5 from "./HomeTiger5";
import { useAuth0 } from "@auth0/auth0-react";
import Footer from "./Footer";
import { Button } from "primereact/button";
import { Badge } from 'primereact/badge';
import { Card } from 'primereact/card';



export default function Home2() {

  const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently, loginWithRedirect } = useAuth0();
  var [userId, setUserId] = useState(null);
  const [wasRoundDeleted, setWasRoundDeleted] = useState(false);
  const [userUnits, setUserUnits] = useState("");
  var [data, setData] = useState(null);
  const [noRounds, setNoRounds] = useState(false);
  const [baseline, setBaseline] = useState("");
  /**gets last 10 rounds from tha database for stats from last 10 rounds*/
  useEffect(() => {
    if (isAuthenticated && userId !== null) {
      fetch(`/api/Calculation/user-last-quick?userId=${userId}&numberOfRounds=10&roundType=All`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          setData(data);
          setWasRoundDeleted(false);
        }
        )
        .catch((error) => { console.log(error); setNoRounds(true); });
    }
  }, [userId, wasRoundDeleted]);

  const [role, setRole] = useState('');

  useEffect(() => {
    const getRole = async () => {
      const idTokenClaims = await getIdTokenClaims();
      setRole(idTokenClaims['https://parfectperformance.com/role']);
    };
    if (isAuthenticated) {
      getRole();
      setUserId(user.sub.substring(user.sub.indexOf('|') + 1));
    }
    const fetchUser = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:myself'
          },
        });

        const response = await fetch(`api/User/myself?userId=${user.sub}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();
        setUserUnits(responseData.units);
        setBaseline(responseData.baseline);
        window.localStorage.setItem("units", responseData.units);
        window.localStorage.setItem("baseline", responseData.baseline);
        window.localStorage.setItem("team", responseData.team);
        if (responseData.bag !== null) {
          window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
        }
      } catch (error) {
        console.log(error.error)
        if (error.error === 'login_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          });
        } else if (error.error === 'consent_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          }, {
            appState: { targetUrl: window.location.href }
          });
        } else {
          console.error(error);
        }
      }
    }
    var units = window.localStorage.getItem("units");
    var baseline = window.localStorage.getItem("baseline");
    var team = window.localStorage.getItem("team");
    if (units && baseline && team) {
      setUserUnits(units);
      setBaseline(baseline);
      fetchUser();
    } else {
      fetchUser();
    }
  }, [isAuthenticated]);




  return (
    <>
      {!isAuthenticated ? (
        <>

          <UncontrolledAlert color="warning">
            BETA DISCLAIMER. 1.1. THE BETA SOFTWARE LICENSED HEREUNDER IS STILL IN THE TESTING PHASE AND IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS AND IS BELIEVED TO CONTAIN DEFECTS AND A PRIMARY PURPOSE OF THIS BETA TESTING LICENSE IS TO OBTAIN FEEDBACK ON SOFTWARE PERFORMANCE AND THE IDENTIFICATION OF DEFECTS.
            LICENSEE IS ADVISED TO SAFEGUARD IMPORTANT DATA, TO USE CAUTION AND NOT TO RELY IN ANY WAY ON THE CORRECT FUNCTIONING OR PERFORMANCE OF THE BETA LICENSED SOFTWARE AND/OR ACCOMPANYING MATERIALS.
          </UncontrolledAlert>

          <Alert color="secondary">
            <h1>Welcome to Parfect Performance!</h1>
            <p>
              Parfect Performance is the ultimate web app for college golf teams! Whether you want to track your scores, analyze your performance, compare your stats with other players, or share your achievements with your coach and teammates, Parfect has it all.
              Parfect is easy to use, fun to explore, and designed to help you improve your game. Join Parfect today and discover the power of golf statistics!
            </p>

            <p>
              Features:
            </p>
            <ul>
              <li>
                Create rounds, courses, and tournaments
              </li>
              <li>
                Generate stats
              </li>
              <li>
                Coach and player dashboards
              </li>

            </ul>
          </Alert>
          <Footer />

        </>
      ) : isAuthenticated ? (
        <>
          <Container>
            <Row>
              <Col lg="10">
                <div className="mb-4">
                  <h2>
                    Welcome, {user.name} <Badge value={role} size={"xlarge"} />
                  </h2>
                </div>
              </Col>
              <Col>
                <Button label="Watch Tutorials" link onClick={() => window.open("https://www.youtube.com/watch?v=pPl9XrII1sc&list=PL24tN7qIdr7XI6fEbKd8l1y97w3vtu-7w&index=1", "_blank")} />

              </Col>
            </Row>
            <Row className="mt-5">
              <Col
                xs={{ offset: 2, size: 8 }}
                md={{ offset: 0, size: 3 }}
                lg={{ offset: 0, size: 3 }}
              >
                <h4 className="text-center mb-3 level">Last 10 Rounds</h4>
                <Card body title="Tiger 5" className="mb-3">
                  <HomeTiger5 data={data} noRounds={noRounds} units={userUnits} baseline={baseline}></HomeTiger5>
                </Card>
                <Card body title="Strokes Gained" className="mb-2">
                  <HomeStrokesGained data={data} noRounds={noRounds}></HomeStrokesGained>
                </Card>
              </Col>
              <Col xs="12" md="9" lg="9">
                <Card
                  body
                  className=""
                  style={{
                    marginBottom: "10px",
                    marginRight: "5px",
                    marginLeft: "10px",
                  }}
                  title="Rounds"
                >
                  <RoundTable home={true} userId={userId} setWasRoundDeleted={setWasRoundDeleted} ></RoundTable>
                </Card>
              </Col>
            </Row>
          </Container>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
