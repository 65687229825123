import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import './custom.css';
import { ConstantProvider } from './ConstantContext';


//theme
import "primereact/resources/themes/lara-light-teal/theme.css";
//core
import "primereact/resources/primereact.min.css";
import { GamesProvider } from './GamesProvider';


export default class App extends Component {
  static displayName = App.name;

  render() {
    return (
      <ConstantProvider>
        <GamesProvider>
          <Layout>
            <Routes>
              {AppRoutes.map((route, index) => {
                const { element, ...rest } = route;
                return <Route key={index} {...rest} element={element} />;
              })}
            </Routes>
          </Layout>
        </GamesProvider>
      </ConstantProvider>
    );
  }
}
