import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState, useRef } from "react";
import { InputText } from "primereact/inputtext";
import { SelectButton } from 'primereact/selectbutton';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { ProgressSpinner } from 'primereact/progressspinner';
import { InputNumber } from 'primereact/inputnumber';

import { Tooltip } from 'primereact/tooltip';
const UserProfile = () => {

    const { user, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const [userInfo, setUserInfo] = useState(null);
    const [baseline, setBaseline] = useState("");
    const [name, setName] = useState(null);
    const [units, setUnits] = useState(null);
    const [wasDataLoaded, setWasDataLoaded] = useState(false);
    const possibleClubs = ['LW', 'SW', 'GW', 'PW', '9i', '8i', '7i', '6i', '5i', '4i', '3i', '2i', '4h', '3h', '7w', '5w', '3w', 'Driver'];
    const nullBag = {
        "LW": 0,
        "SW": 0,
        "GW": 0,
        "PW": 0,
        "9i": 0,
        "8i": 0,
        "7i": 0,
        "6i": 0,
        "5i": 0,
        "4i": 0,
        "3i": 0,
        "2i": 0,
        "4h": 0,
        "3h": 0,
        "7w": 0,
        "5w": 0,
        "3w": 0,
        "Driver": 0
    };
    const [bag, setBag] = useState(nullBag);

    const [isMobile, setIsMobile] = useState(false);

    const toast = useRef(null);
    useEffect(() => {
        const fetchMyself = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:myself'
                    },
                });

                const response = await fetch(`api/User/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                const responseData = await response.json();
                setUserInfo(responseData);
                setName(responseData.name);
                setBaseline(responseData.baseline);
                setUnits(responseData.units);
                if (responseData.bag === null) {
                    setBag(nullBag)
                } else {
                    setBag(responseData.bag)
                }
                setWasDataLoaded(true);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:myself'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:myself'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        fetchMyself();
    }, [])
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    async function submitChanges() {
        try {
            const token = await getAccessTokenSilently({
                authorizationParams: {
                    audience: 'https://parfectperformance.com/api',
                    scope: 'read:myself'
                },
            });
            fetch(`/api/User/myself`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    id: userInfo.id,
                    name: name,
                    baseline: baseline,
                    units: units,
                    bag: bag
                })
            }).then(response => {
                return response;
            }).then(data => {
                window.localStorage.setItem("units", units);
                window.localStorage.setItem("baseline", baseline);
                window.localStorage.setItem("bag", JSON.stringify(bag));
                toast.current.show({ severity: 'success', summary: 'Updated', detail: `Name: ${name}, Baseline: ${baseline}, Units: ${units}` });
            })
        } catch (error) {
            console.log(error.error)
        }
    }
    // Handler function to update the bag when a checkbox or input changes
    const handleClubChange = (club, distance) => {
        if (distance !== null) {
            setBag((prevBag) => ({
                ...prevBag,
                [club]: parseInt(distance),
            }));
        } else {
            setBag((prevBag) => ({
                ...prevBag,
                [club]: 0,
            }));
        }

    };

    function bagInfo() {
        return (
            <div style={{ display: "inline-flex" }}>
                <Tooltip target=".UDInfo" />
                <div>Bag:</div>
                <i className='pi pi-info-circle m-1 UDInfo' data-pr-tooltip="Use the same units as you use to input the stats. If you don't use a particular club, enter distance of 0." />
            </div>
        )
    }


    return (
        <div className="justify-content-center  align-items-center card flex gap-3">
            <Toast ref={toast} />
            {!wasDataLoaded && <ProgressSpinner className="mt-3" style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" />}

            <div className="mt-7 flex justify-content-center  align-items-center gap-3">
                <div className="m-3">
                    <label htmlFor="username">Name</label>
                    <div>
                        <InputText id="username" placeholder={userInfo ? userInfo.name : "..."} onChange={(e) => setName(e.target.value)} />
                    </div>
                </div>
                <div className=" m-3">
                    <label htmlFor="username">Baseline</label>
                    <div>
                        <SelectButton value={baseline} onChange={(e) => setBaseline(e.value)} options={["PGA", "LPGA"]} />
                    </div>
                </div>
                <div className=" m-3">
                    <label htmlFor="username">Units</label>
                    <div>
                        <SelectButton value={units} onChange={(e) => setUnits(e.value)} options={["yards", "meters"]} />
                    </div>
                </div>
            </div>
            <div className="mt-2 flex justify-content-center  align-items-center gap-3" >
                <label>{bagInfo()}</label>
                {possibleClubs.map((club) => (
                    <div className="mt-2 mb-2" style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                        <label>{club}</label>
                        <InputNumber value={bag[club]}
                            onValueChange={(e) => {
                                handleClubChange(club, e.value)
                            }} />
                    </div>
                ))}
            </div>
            <div className="m-5">
                <Button label="Submit" severity="success" onClick={() => submitChanges()} />
            </div>

        </div >
    )
}

export default UserProfile;