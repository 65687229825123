import React, { useState, useEffect } from "react";
import "../../css/new-round.css";
import { Row, Col } from 'reactstrap'
import { ToggleButton } from "primereact/togglebutton";
import { MultiSelect } from 'primereact/multiselect';
export function MultiSelectGames({ games, setSelectedGames, selectedGames, part }) {



    return (
        <MultiSelect
            value={selectedGames}
            onChange={(e) => setSelectedGames(e.value)}
            options={games}
            optionLabel="name"
            display="chip"
            filter
            filterPlaceholder="Search Game"
            placeholder={`Select ${part} Games`}
            className={"w-full md:w-14rem m-2"}
            dropdownIcon="pi pi-chevron-down"
        />

    )
}