import { useEffect, useState } from "react"
import { InputNumber } from "primereact/inputnumber";
import { Table } from "reactstrap";
import { Button } from "primereact/button";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog } from 'primereact/dialog';
import { GamesDropdown } from "../dropdowns/GamesDropdown";
import { useGames } from "../../GamesProvider";
const Games = ({ games }) => {
    const [selectedGame, setSelectedGame] = useState(null);
    const [score, setScore] = useState(null);
    const [visible, setVisible] = useState(false);
    const [rounds, setRounds] = useState([]);
    const [isSubmit, setIsSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [lastScore, setLastScore] = useState(null);
    const [last5Score, setLast5Score] = useState(null);
    const [last10Score, setLast10Score] = useState(null);
    const [last30Score, setLast30Score] = useState(null);

    const [numRoundsToShow, setNumRoundsToShow] = useState(10);
    const { user, } = useAuth0();
    const userId = user.sub.substring(user.sub.indexOf('|') + 1);

    const gamesConst = useGames();

    /**Formats the datae to mm/dd/yyyy */
    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    /**Will increse the number of rounds shown in the table */
    const handleLoadMore = () => {
        setNumRoundsToShow(numRoundsToShow + 10);
    };

    function submitScore() {
        setLoading(true);
        fetch(`/api/${games}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ userId: userId, date: new Date().toISOString(), gameName: selectedGame.name, score: score, })
        })
            .then(response => response.json())
            .then(data => {
                setScore(null);
                setIsSubmit(!isSubmit);
            })
            .catch(error => console.error(error));
    }

    function deleteGame(id) {
        const confirmDelete = window.confirm(`Are you sure you want to delete this game?`);
        if (confirmDelete) {
            fetch(`/api/${games}/${id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => { })
                .then(data => {
                    setScore(null);
                    setIsSubmit(!isSubmit);
                })
                .catch(error => console.error(error));
        }
    }

    useEffect(() => {
        if (selectedGame) {
            fetch(`/api/${games}/user?userId=${userId}&name=${selectedGame.name}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => response.json())
                .then(data => {
                    setRounds(data);
                    setLastScore(data[0].score);
                    setLast5Score((data.slice(0, 5).map((round) => round.score).reduce((sum, score) => sum + score, 0) / (data.length >= 5 ? 5 : data.length)).toFixed(2))
                    setLast10Score((data.slice(0, 10).map((round) => round.score).reduce((sum, score) => sum + score, 0) / (data.length >= 10 ? 10 : data.length)).toFixed(2))
                    setLast30Score((data.slice(0, 30).map((round) => round.score).reduce((sum, score) => sum + score, 0) / (data.length >= 30 ? 30 : data.length)).toFixed(2))

                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                })
                .catch(error => console.error(error));
        }
    }, [selectedGame, isSubmit])

    return (
        <div>
            {games === "puttinggames" && <GamesDropdown selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.puttingGames} />}
            {games === "arggames" && <GamesDropdown selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.argGames} />}
            {games === "teegames" && <GamesDropdown selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.teeGames} />}
            {games === "approachgames" && <GamesDropdown selectedGame={selectedGame} setSelectedGame={setSelectedGame} games={gamesConst.appGames} />}
            {selectedGame !== null &&
                <div>
                    <Table striped bordered hover className="mt-3">
                        <thead>
                            <tr>
                                <th>Info</th>
                                <th>Score</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr >
                                <td>
                                    <div className="card flex justify-content-center">
                                        <Button label="Description" icon="pi pi-info-circle" onClick={() => setVisible(true)} />
                                        <Dialog header={selectedGame.name} visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)}>
                                            <p className="m-0">
                                                {selectedGame.description}
                                            </p>
                                        </Dialog>
                                    </div>
                                </td>
                                <td>
                                    <InputNumber
                                        onValueChange={(e) => setScore(e.target.value)}
                                        value={score}
                                        showButtons buttonLayout="vertical"
                                        incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                        decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                        className="p-fluid"
                                        maxLength={2}
                                    />
                                </td>
                                <td>
                                    <Button className="m-1" label="Submit" icon="pi pi-check" iconPos="right" severity="success" loading={loading} onClick={() => submitScore()} />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table striped bordered >
                        <thead>
                            <tr>
                                <th>Last </th>
                                <th>Last 5 avg. </th>
                                <th>Last 10 avg.</th>
                                <th>Last 30 avg.</th>
                            </tr>
                        </thead>
                        {rounds.length > 0 ? (<tbody>
                            <tr >
                                <td className={rounds ? "" : "loading-cell"}
                                    style={{
                                        color: selectedGame ? (selectedGame.isLow ?
                                            (lastScore > selectedGame.low ? "red" : lastScore < selectedGame.high ? "green" : "") :
                                            (lastScore < selectedGame.low ? "red" : lastScore > selectedGame.high ? "green" : "")) : ""
                                    }}>
                                    {lastScore}
                                </td>
                                <td className={rounds ? "" : "loading-cell"} style={{
                                    color: selectedGame ? (selectedGame.isLow ?
                                        (last5Score > selectedGame.low ? "red" : last5Score < selectedGame.high ? "green" : "") :
                                        (last5Score < selectedGame.low ? "red" : last5Score > selectedGame.high ? "green" : "")) : ""
                                }}>
                                    {last5Score}
                                </td>
                                <td className={rounds ? "" : "loading-cell"} style={{
                                    color: selectedGame ? (selectedGame.isLow ?
                                        (last10Score > selectedGame.low ? "red" : last10Score < selectedGame.high ? "green" : "") :
                                        (last10Score < selectedGame.low ? "red" : last10Score > selectedGame.high ? "green" : "")) : ""
                                }}>
                                    {last10Score}
                                </td>
                                <td className={rounds ? "" : "loading-cell"} style={{
                                    color: selectedGame ? (selectedGame.isLow ?
                                        (last30Score > selectedGame.low ? "red" : last30Score < selectedGame.high ? "green" : "") :
                                        (last30Score < selectedGame.low ? "red" : last30Score > selectedGame.high ? "green" : "")) : ""
                                }}>
                                    {last30Score}
                                </td>
                            </tr>
                        </tbody>) : (<tbody>
                            <tr >
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                                <td className="loading-cell"></td>
                            </tr>
                        </tbody>)}
                    </Table>
                    <div className="table-responsive">
                        <Table striped hover>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Score</th>
                                    <th></th>
                                </tr>
                            </thead>
                            {rounds.length > 0 ? (
                                <tbody>
                                    {rounds.slice(0, numRoundsToShow).map((round) => (
                                        <tr key={round.id}>
                                            <td>{formatDate(round.date)}</td>
                                            <td style={{
                                                color: selectedGame.isLow ?
                                                    (round.score > selectedGame.low ? "red" : round.score < selectedGame.high ? "green" : "")
                                                    : (round.score < selectedGame.low ? "red" : round.score > selectedGame.high ? "green" : "")
                                            }}>{round.score}</td>
                                            <td>
                                                <Button icon="pi pi-trash" label="Delete" severity="danger" onClick={() => { deleteGame(round.id) }} size="small" />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>) : (<tbody>
                                    <tr >
                                        <td className="loading-cell"></td>
                                        <td className="loading-cell"></td>
                                        <td className="loading-cell"></td>
                                    </tr>
                                </tbody>)}

                        </Table>
                        {numRoundsToShow < rounds.length && (
                            <div className="d-flex justify-content-center">
                                <Button label="Load More" style={{ width: 300 }} severity="secondary" outlined onClick={handleLoadMore} className="mb-5" />
                            </div>
                        )}
                    </div>
                </div>
            }
        </div>
    )
}

export { Games }