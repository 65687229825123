import React from "react";
import "../../css/new-round.css";
import { Dropdown } from "primereact/dropdown";

export function RoundTypeDropdown({ setTournamentSelected, setRoundType, roundType, roundTypes }) {




    /**
 * Changes the type of the selected round.
 * If tournament or qualifying was selected it will
 * render dropdown with tournaments to pick from.
 * @param {*} event 
 */
    const changeType = (event) => {
        setRoundType(event.target.value);
        if (event.target.value.toLowerCase() === "tournament" || event.target.value.toLowerCase() === "qualifying") {
            setTournamentSelected(true);
        } else {
            setTournamentSelected(false);
        }
    };
    const selectedTypeTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center"> <div>{option}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryTypeTemplate = (option) => {
        return (
            <div className="flex align-items-center"> <div>{option}</div>
            </div>
        );
    };
    return (
        <div>
            <Dropdown color="primary" value={roundType} onChange={(event) => changeType(event)} options={roundTypes} optionLabel="name" placeholder="Select Round Type"
                valueTemplate={selectedTypeTemplate} itemTemplate={countryTypeTemplate} className="w-full md:w-14rem" />

        </div>
    )
}