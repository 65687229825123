import React, { useState, useEffect } from "react";
import {
  Container, Table,
  Row, Col
} from "reactstrap";
import "../css/ButtonGroup.css";
import "../css/add-round.css"
import { Tooltip } from 'primereact/tooltip';
import { useAuth0 } from "@auth0/auth0-react";
import { Button } from 'primereact/button';
import { InputNumber } from 'primereact/inputnumber';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from 'primereact/checkbox';
import { Badge } from 'primereact/badge';

import { SelectButton } from 'primereact/selectbutton';

import { useConstant } from '../ConstantContext';
export function AddRound({ roundId, teeSelect, userBaseline, units }) {

  const { user } = useAuth0();
  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const urlParams = new URLSearchParams(window.location.search);

  const [localRoundId, setLocalRoundId] = useState(roundId);
  useEffect(() => {
    console.log(localRoundId)
    if (urlParams.get('roundId') !== null)
      setLocalRoundId(urlParams.get('roundId'))
    else if (roundId)
      setLocalRoundId(roundId)

  }, [roundId]);

  const { yardsToMeters, metersToYards } = useConstant();
  /** selected tee from the database*/
  const [selectedTee, setSelectedTee] = useState(teeSelect ? teeSelect : "");
  /** JSON that will be sent to database*/
  var shotJSON = {
    shotTotal: "",
    shotHole: "",
    parHole: "",
    holeNo: "",
    shotDst: "",
    lie: "",
    club: "",
    mental: "",
    missDst: "",
    missDir: "",
    penalty: "",
    layup: "",
    shortSided: ""
  };
  var tapInJSON = {
    shotTotal: "",
    shotHole: "",
    parHole: "",
    holeNo: "",
    shotDst: "",
    lie: "",
    club: "",
    mental: "",
    missDst: "",
    missDir: "",
    penalty: "",
    layup: "",
    shortSided: ""
  };
  /** Values of cells on the scorecard*/
  const [cellValues, setCellValues] = useState(Array(18).fill(0));
  /**  Current cell on the scorecard*/
  const [currentCell, setCurrentCell] = useState(0);
  /** total score on front 9 for the scorecard*/
  const frontTotal = cellValues.slice(0, 9).reduce((a, b) => a + b, 0);
  /** total score on back 9 for the scorecard*/
  const backTotal = cellValues.slice(9).reduce((a, b) => a + b, 0);
  /** Colors of cells for the scorecard*/
  const [cellStyles] = useState(Array(18).fill({ backgroundColor: "white" }));
  /** Clubs for the beggining of the round*/
  const clubs = ["Driver", "3w", "5w", "3h", "4h", "2i", "3i", "4i", "5i", "6i",
    "7i", "8i", "9i", "PW", "GW", "SW", "LW",];
  /**Possible clubs o hit off the tee*/
  const clubsTee = ["Driver", "3w", "5w", "3h", "4h", "2i", "3i", "4i", "other"]
  /**Possible clubs for approach */
  const clubsApp = ["3w", "5w", "3h", "4h", "2i", "3i", "4i", "5i", "6i", "7i", "8i",
    "9i", "PW", "GW", "SW", "LW",]
  /**Selected club 
   * @type {string|null}
  */
  var [club, setClub] = useState(clubs[0]);
  /**Possible mistakes */
  const mentals = ["Good", "Technique", "Mental State", "Decision"];
  /** Boolean determiming if yards are used (true) or meters (false)*/
  const [yards, setYards] = useState(units === "meters" ? false : true);
  /**Value of distance input */
  const [inputValue, setInputValue] = useState(teeSelect ? (units === "meters" ? parseInt(teeSelect.holeDist[1] * yardsToMeters) : teeSelect.holeDist[1]) : "");
  /**Mistake selectes */
  var [mental, setMental] = useState(mentals[0]);
  /**Possible next lie for approaches */
  const liesApp = ["Fairway", "Recovery", "Rough", "Fringe", "Bunker", "Green"];
  /**Possible misses off the tee */
  const dirTee = [
    { miss: "Left", icon: "pi pi-arrow-left", pos: "left" },
    { miss: "Long/Short", icon: "pi pi-arrows-v", pos: "top" },
    { miss: "Right", icon: "pi pi-arrow-right", pos: "right" }];
  /**Possible misses for approaches */
  const dirApp = [
    { miss: "Long Left", icon: "pi pi-arrow-up-left", pos: "left" },
    { miss: "Long", icon: "pi pi-arrow-up", pos: "top" },
    { miss: "Long Right", icon: "pi pi-arrow-up-right", pos: "right" },
    { miss: "Left", icon: "pi pi-arrow-left", pos: "left" },
    { miss: "In the Hole", icon: "pi pi-flag", pos: "bottom" },
    { miss: "Right", icon: "pi pi-arrow-right", pos: "right" },
    { miss: "Short Left", icon: "pi pi-arrow-down-left", pos: "left" },
    { miss: "Short", icon: "pi pi-arrow-down", pos: "bottom" },
    { miss: "Short Right", icon: "pi pi-arrow-down-right", pos: "right" }
  ];
  /**Possible misses for putting */
  const dirPutt = [
    { miss: "High", icon: "pi pi-align-justify", pos: "left" },
    { miss: "In the Hole", icon: "pi pi-flag", pos: "bottom" },
    { miss: "Low", icon: "pi pi-minus", pos: "right" },
    { miss: "High Short", icon: "pi pi-align-justify", pos: "left" },
    { miss: "Short", icon: "pi pi-arrow-down", pos: "bottom" },
    { miss: "Low Short", icon: "pi pi-minus", pos: "right" }
  ];

  /**Possible pin positions */
  const pinPos = [
    { miss: "Back Left", icon: "pi pi-arrow-up-left", pos: "left" },
    { miss: "Back Center", icon: "pi pi-arrow-up", pos: "top" },
    { miss: "Back Right", icon: "pi pi-arrow-up-right", pos: "right" },
    { miss: "Left", icon: "pi pi-arrow-left", pos: "left" },
    { miss: "Middle", icon: "pi pi-plus-circle", pos: "bottom" },
    { miss: "Right", icon: "pi pi-arrow-right", pos: "right" },
    { miss: "Front Left", icon: "pi pi-arrow-down-left", pos: "left" },
    { miss: "Front Center", icon: "pi pi-arrow-down", pos: "bottom" },
    { miss: "Front Right", icon: "pi pi-arrow-down-right", pos: "right" }
  ];
  /**Number of total shot for the round */
  var [shotTotal, setShotTotal] = useState(1);
  /**Number of current shot on the hole */
  var [shotHole, setShotHole] = useState(1);
  /**Number of current hole */
  var [holeNo, setHoleNo] = useState(1);
  /**Current Lir of the shot */
  var [currentLie, setCurrentLie] = useState("tee");
  /**Lie of the next shot*/
  const [nextLie, setNextLie] = useState("");
  /**Lie of the previoius shot */
  var [prevLie, setPrevLie] = useState("");
  /**If miss buttons for approaches are disabled*/
  var [isMissDisabled, setIsMissDisabled] = useState(true);
  /**If miss buttons for tee shots are disabled*/
  var [isTeeMissDisabled, setIsTeeMissDisabled] = useState(false);
  /**Is distance input valid */
  var [validInput, setValidInput] = useState(false);
  /** Is mistake disabled*/
  const [isMentalDisabled, setIsMentalDisabled] = useState(false);
  /**if in the hole is clicked this will be true */
  const [isNextShotDisabled, setIsNextShotDisabled] = useState(false);
  /**Value of layup check */
  const [layup, setLayup] = useState(false);
  /**Value of penalty check */
  const [penalty, setPenalty] = useState(false);
  /**Value of shortsided check */
  const [shortSided, setShortSided] = useState(false);
  /**Vallue of forced layup check */
  const [forcedLayup, setForcedLayup] = useState(false);
  /**What was the furthes player could hit the tee shot */
  var [forcedLayupDistance, setForcedLayupDistance] = useState("");
  /**Value of direction miss */
  var [missDir, setMissDir] = useState("");
  /**Value of distance miss */
  var [missDst, setMissDst] = useState("");
  /**If current shot type is a tee shot */
  var [teeShot, setTeeShot] = useState(true);
  /**If current shot type is a approach or arg */
  var [appShot, setAppShot] = useState(false);
  /**If current shot type is a putt */
  var [putt, setPutt] = useState(false);
  /**Selected Lie */
  var [clickedLie, setClickedLie] = useState(null);
  /**Selected miss */
  var [clickedMiss, setClickedMiss] = useState(null);

  const [difficulty, setDifficulty] = useState("Medium");
  const difficultyOptions = ["Easy", "Medium", "Hard"];

  const [pinPosClicked, setPinPosClicked] = useState(null);
  const [pinDir, setPinDir] = useState(null);
  const [pinDst, setPinDst] = useState(null);

  const [shotsArray, setShotsArray] = useState([]);
  var [currentIndex, setCurrentIndex] = useState(0);
  const [prevClicked, setPrevClicked] = useState(false);
  const [endOfRound, setEndOfRound] = useState(false);
  const [baseline, setBaseline] = useState(userBaseline ? userBaseline : "");

  const [isMobile, setIsMobile] = useState(false);
  const [bag, setBag] = useState({})
  const [bagArr, setBagArr] = useState([]);
  const forcedLayupMult = 0.65;

  const [gfOpp, setGfOpp] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    handleResize(); // Initial check
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const fetchBag = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://parfectperformance.com/api',
          scope: 'read:myself'
        },
      });

      const response = await fetch(`api/User/myself?userId=${user.sub}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      if (responseData.bag !== null) {
        var bagtmp = window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
        var tmp = {};
        Object.keys(responseData.bag).forEach((key) => {
          if (bagtmp[key] !== 0) {
            tmp[key] = bagtmp[key];
            setBagArr(prevArr => [...prevArr, key.toString()])
          }
        })
        setBag(tmp)
      }
    } catch (error) {
      console.log(error.error)
      if (error.error === 'login_required') {

        await loginWithRedirect({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:users'
          },
        });
      } else if (error.error === 'consent_required') {

        await loginWithRedirect({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:users'
          },
        }, {
          appState: { targetUrl: window.location.href }
        });
      } else {
        console.error(error);
      }
    }
  }

  useEffect(() => {
    var bagtmp = JSON.parse(window.localStorage.getItem("bag"));
    if (bagtmp) {
      var tmp = {};
      Object.keys(bagtmp).forEach((key) => {
        if (bagtmp[key] !== 0) {
          tmp[key] = bagtmp[key];
          setBagArr(prevArr => [...prevArr, key.toString()])
        }
      })
      setBag(tmp)
    } else {
      fetchBag()
    }
  }, [])

  useEffect(() => {
    fetch(`/api/Course/${urlParams.get('courseId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        data.teeSet.forEach(tee => {
          if (tee.teeName === urlParams.get('tee')) {
            setSelectedTee(tee);
            if (urlParams.get('roundId') !== null)
              setLocalRoundId(urlParams.get('roundId'));
            getRound(tee);
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  }, [])

  function getUnits(tee, shotNo, hole, lastDst) {
    const fetchUser = async () => {
      try {
        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:users'
          },
        });

        const response = await fetch(`api/user/userId?userId=${user.sub}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const responseData = await response.json();
        if (responseData[0].units === "meters") {
          setYards(false);
          if (tee && shotNo === 1 && tee.holePar[hole - 1] !== 3) {
            setInputValue(Math.ceil(parseInt(tee.holeDist[hole] * yardsToMeters)))
          } else if (shotNo !== 1) {
            setInputValue(parseInt(lastDst * yardsToMeters))
          }
        } else {
          setYards(true);
          if (tee && shotNo === 1 && tee.holePar[hole - 1] !== 3) {
            setInputValue(Math.ceil(parseInt(tee.holeDist[hole])))
          } else if (shotNo !== 1) {
            setInputValue(parseInt(lastDst))
          }
        }

      } catch (error) {
        console.log(error.error)
        if (error.error === 'login_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          });
        } else if (error.error === 'consent_required') {

          await loginWithRedirect({
            authorizationParams: {
              audience: 'https://parfectperformance.com/api',
              scope: 'read:users'
            },
          }, {
            appState: { targetUrl: window.location.href }
          });
        } else {
          console.error(error);
        }
      }
    }
    var unitsLocal = window.localStorage.getItem("units");
    var baselineLocal = window.localStorage.getItem("baseline");

    if (unitsLocal && baselineLocal) {
      if (unitsLocal === "meters") {
        setYards(false);
        if (tee && shotNo === 1 && tee.holePar[hole - 1] !== 3) {
          setInputValue(Math.ceil(parseInt(tee.holeDist[hole] * yardsToMeters)))
        } else if (shotNo !== 1) {
          console.log(lastDst * yardsToMeters)
          setInputValue(parseInt(lastDst * yardsToMeters))
        }
      } else {
        setYards(true);
        if (tee && shotNo === 1 && tee.holePar[hole - 1] !== 3) {
          setInputValue(Math.ceil(parseInt(tee.holeDist[hole])))
        } else if (shotNo !== 1) {
          setInputValue(parseInt(lastDst))
        }
      }
    } else {
      fetchUser();
    }


  }

  function getRound(tee) {
    fetch(`/api/Round/id?id=${urlParams.get('roundId')}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        let lastIndex = data[0].shots.length - 1;
        if (data[0].shots[lastIndex]) {
          getUnits(tee, data[0].shots[lastIndex].shotHole, data[0].shots[lastIndex].holeNo, data[0].shots[lastIndex].shotDst);
        } else {
          getUnits(tee, 1, 1, 1);
        }
        setShotsArray(data[0].shots);
        setShotHole(data[0].shots[lastIndex].shotHole);
        setHoleNo(data[0].shots[lastIndex].holeNo);
        setCurrentLie(data[0].shots[lastIndex].lie);
        setDifficulty(data[0].shots[lastIndex].difficulty);
        if (data[0].shots[lastIndex].lie.toLowerCase() === "green") {
          setPutt(true);
          setAppShot(false);
          setTeeShot(false);
          if (data[0].shots[lastIndex].missDir && data[0].shots[lastIndex].missDst) {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDir) + " " + capitalizeFirstLetter(data[0].shots[lastIndex].missDst));
          } else if (data[0].shots[lastIndex].missDir && data[0].shots[lastIndex].missDst === "") {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDir));
          } else {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDst));
          }
          setNextLie("green")
        } else if (data[0].shots[lastIndex].lie.toLowerCase() === "tee") {
          setPutt(false);
          setAppShot(false);
          setTeeShot(true);
          if (data[0].shots[lastIndex].missDir) {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDir));
          }
        }
        else {
          setPutt(false);
          setAppShot(true);
          setTeeShot(false);
          if (data[0].shots[lastIndex].missDir && data[0].shots[lastIndex].missDst) {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDst) + " " + capitalizeFirstLetter(data[0].shots[lastIndex].missDir));
          } else if (data[0].shots[lastIndex].missDir && data[0].shots[lastIndex].missDst === "") {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDir));
          } else {
            setClickedMiss(capitalizeFirstLetter(data[0].shots[lastIndex].missDst));
          }
        }
        setBaseline(data[0].baseline)
        setClub(data[0].shots[lastIndex].club);
        setMental(data[0].shots[lastIndex].mental);
        handleInput(!yards ? parseInt(data[0].shots[lastIndex].shotDst * yardsToMeters) : data[0].shots[lastIndex].shotDst);
        setLayup(data[0].shots[lastIndex].layup);
        setPenalty(data[0].shots[lastIndex].penalty);
        setShotTotal(data[0].shots[lastIndex].shotTotal);
        setCurrentIndex(lastIndex);
        setCurrentCell(data[0].shots[lastIndex].holeNo - 1);
        setPrevLie(data[0].shots[lastIndex - 1].lie);
        let tempArr = Array(18).fill(0);
        let i = 0;
        let hole = 1;

        data[0].shots.forEach(s => {
          if (hole < s.holeNo) {
            hole++;
            i++;
          }
          tempArr[i] = s.shotHole;
          let color = "white";
          let text = "black";

          let diff = s.shotHole - s.parHole;
          if (diff === 1) {
            color = "green";
          } else if (diff === 2) {
            color = "blue";
            text = "white";
          } else if (diff >= 3) {
            color = "black";
            text = "white";
          } else if (diff === -1) {
            color = "red";
            text = "white";
          } else if (diff <= -2) {
            color = "yellow";
          }
          cellStyles[s.holeNo - 1] = { backgroundColor: color, color: text };

        });
        setCellValues(tempArr);
      })
      .catch(error => {
        console.error(error);
      });
  }

  /**
   * Adds the shot to the database
   */
  function addShot(wasTapIn) {
    const newItem = shotJSON;
    var tempArr = [...shotsArray];
    if (currentIndex < shotsArray.length) {
      tempArr[currentIndex] = newItem;
      setShotsArray(tempArr);
    } else {
      tempArr.push(newItem);
      if (wasTapIn) {
        console.log(tapInJSON)
        const tapInC = tapInJSON;
        tempArr.push(tapInC);
      }
      setShotsArray(tempArr);
    }

  }



  useEffect(() => {
    fetch(`/api/round/update-shots/${localRoundId}`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(shotsArray)
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Failed to add item to array');
        }
        return response;
      }).then(data => {
        if (endOfRound) {
          window.location.href = `/round-stats/${localRoundId}`;
        }
      })
      .catch(error => {
        console.error(error);
      });
  }, [shotsArray])

  function addScoreToRound(wasTapIn) {
    if (!penalty) {
      const documentId = localRoundId;
      fetch(`/api/round/add-score/${documentId}?score=${shotTotal + (wasTapIn ? 1 : 0)}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to add item to array');
          }
          return response.json();
        })
        .then(data => {
          console.log('Array item added successfully:', data);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      const documentId = localRoundId;
      fetch(`/api/round/add-score/${documentId}?score=${shotTotal + 1 + (wasTapIn ? 1 : 0)}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        }
      })
        .then(response => {
          if (!response.ok) {
            throw new Error('Failed to add item to array');
          }
          return response.json();
        })
        .then(data => {
          console.log('Array item added successfully:', data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }
  /**
   * Fills out the json keys with appropriate values
   */
  function fillJSON() {
    shotJSON.shotTotal = shotTotal;
    shotJSON.shotHole = shotHole;
    shotJSON.parHole = selectedTee.holePar[holeNo];
    shotJSON.holeNo = holeNo;

    if (!yards && !putt) {
      if (baseline === "LPGA") {
        if (teeShot && forcedLayup && forcedLayupDistance < 235) {
          //increase the length of the hole to adjust average score on the hole
          shotJSON.shotDst = parseInt(Math.ceil((parseInt(inputValue) + parseInt((235 - parseInt(forcedLayupDistance)) * forcedLayupMult) * 1.09361)));
        }
        else {
          shotJSON.shotDst = parseInt(Math.ceil(parseInt(inputValue) * 1.09361));
        }
      }
      else {
        if (teeShot && forcedLayup && forcedLayupDistance < 272) {
          //increase the length of the hole to adjust average score on the hole
          shotJSON.shotDst = parseInt(Math.ceil((parseInt(inputValue) + parseInt((272 - parseInt(forcedLayupDistance)) * forcedLayupMult)) * 1.09361));
        }
        else {
          shotJSON.shotDst = parseInt(Math.ceil(parseInt(inputValue) * 1.09361));
        }
      }
    } else if (yards && !putt) {
      if (baseline === "LPGA") {
        if (teeShot && forcedLayup && forcedLayupDistance < 257) {
          //increase the length of the hole to adjust average score on the hole
          shotJSON.shotDst = parseInt(Math.ceil(parseInt(inputValue) + parseInt((257 - forcedLayupDistance) * forcedLayupMult)));
        }
        else {
          shotJSON.shotDst = parseInt(Math.ceil(parseInt(inputValue)));
        }
      } else {
        if (teeShot && forcedLayup && forcedLayupDistance < 297) {
          //increase the length of the hole to adjust average score on the hole
          shotJSON.shotDst = parseInt(Math.ceil(parseInt(inputValue) + parseInt((297 - forcedLayupDistance) * forcedLayupMult)));
        }
        else {
          shotJSON.shotDst = parseInt(Math.ceil(parseInt(inputValue)));
        }
      }
    } else {
      shotJSON.shotDst = parseInt(inputValue);
    }
    if (currentLie.toLowerCase() === "fringe") {
      if (yards && parseInt(inputValue) > 40) {
        shotJSON.lie = "fairway";
      } else if (!yards && parseInt(inputValue) > 36) {
        shotJSON.lie = "fairway";
      } else {
        shotJSON.lie = currentLie.toLowerCase();
      }
    } else {
      shotJSON.lie = currentLie.toLowerCase();
    }
    shotJSON.club = club.toLowerCase();
    shotJSON.mental = mental.toLowerCase();
    shotJSON.missDst = missDst.toLowerCase();
    shotJSON.missDir = missDir.toLowerCase();
    shotJSON.penalty = penalty;
    shotJSON.layup = layup;
    shotJSON.shortSided = false;//depracated
    shotJSON.difficulty = difficulty.toLowerCase();
    shotJSON.pinDst = pinDst;
    shotJSON.pinDir = pinDir;
    shotJSON.gfOpp = gfOpp;
  }

  function fillTapInJSON() {
    tapInJSON.shotTotal = shotTotal + 1;
    tapInJSON.shotHole = shotHole + 1;
    tapInJSON.parHole = selectedTee.holePar[holeNo];
    tapInJSON.holeNo = holeNo;
    tapInJSON.shotDst = Math.floor(Math.random() * 2 + 1);
    tapInJSON.lie = "green";
    tapInJSON.club = "";
    tapInJSON.mental = "good";
    tapInJSON.missDst = "";
    tapInJSON.missDir = "";
    tapInJSON.penalty = penalty;
    tapInJSON.layup = layup;
    tapInJSON.shortSided = false;//depracated
    tapInJSON.difficulty = "";
    tapInJSON.pinDst = pinDst;
    tapInJSON.pinDir = pinDir;
  }
  /**
   * When lie is clicked set next lie and show that button was clicked.
   * 
   * @param {*} event 
   */
  function liesClick(value) {
    setClickedLie(value);
    setNextLie(value);
    if (teeShot) {
      handleTeeMiss(value);
    }

  }
  /**
 * If off the tee green or fairway was selected it will disable miss buttons
 * @param {*} event 
 */
  function handleTeeMiss(value) {
    if ((value.toLowerCase() === "fairway" || value.toLowerCase() === "green" || value.toLowerCase() === "fringe") && teeShot) {
      setIsTeeMissDisabled(true);
      setMissDir("");
      setMissDst("");
      setClickedMiss("")
    } else {
      if (value.toLowerCase() === "tee") {
        setPenalty(true);
      }
      setIsTeeMissDisabled(false);
    }
  };

  /**
   * When miss is clicked it sets the value of the miss and shows button was clicked
   * @param {*} event 
   */
  function missClick(value) {
    setClickedMiss(value);
    handleMiss(value);
  }
  /**
   * Clears input box
   */
  const handleClear = () => {
    setInputValue("");
  };

  /**
   * When user is inputting distance it:
   * 1. checks if it's a number
   * 2. if value is less than 50 and is not a putt it disables uneccessary buttons,
   * and will try to guess what club will be hit if value is more than 50
   * 3. checks that if current lie is green it will disable mistake button.
   * @param {*} value 
   */
  function handleInput(value) {
    setInputValue(value);
    if ((yards ? value <= 50 : value <= 46) && !putt) {
      setIsMissDisabled(true);
      setClub("")
    } else {
      setIsMissDisabled(false);
      if (teeShot) {
        setClub("Driver")
      } else if (appShot) {
        if (bag) {
          bagGuess(value);
        } else {
          if (userBaseline === "LPGA") {
            if (yards) {
              if (value <= 70) {
                setClub("SW");
              } else if (value <= 110) {
                setClub("PW");
              } else if (value <= 140) {
                setClub("8i");
              } else if (value <= 170) {
                setClub("6i");
              } else {
                setClub("4h");
              }
            } else {
              if (value <= 70) {
                setClub("SW");
              } else if (value <= 100) {
                setClub("PW");
              } else if (value <= 130) {
                setClub("8i");
              } else if (value <= 155) {
                setClub("6i");
              } else {
                setClub("4h");
              }
            }
          } else {
            if (yards) {
              if (value <= 100) {
                setClub("SW");
              } else if (value <= 150) {
                setClub("PW");
              } else if (value <= 175) {
                setClub("9i");
              } else if (value <= 200) {
                setClub("7i");
              } else {
                setClub("4i");
              }
            } else {
              if (value <= 100) {
                setClub("SW");
              } else if (value <= 130) {
                setClub("PW");
              } else if (value <= 150) {
                setClub("8i");
              } else if (value <= 175) {
                setClub("6i");
              } else if (value <= 200) {
                setClub("4i");
              } else {
                setClub("3i");
              }
            }
          }
        }
      }
    }
    if (currentLie.toLowerCase() === "green" && value <= 2) {
      setIsMentalDisabled(true);
      setMental("");
    } else {
      setIsMentalDisabled(false);
      setMental("Good");
    }
  };

  function bagGuess(dst) {
    const possibleClubs = ["LW", "SW", "GW", "PW", "9i", "8i", "7i", "6i", "5i", "4i", "3i", "2i", "4h", "3h", "5w", "3w", "Driver"];
    possibleClubs.reverse().forEach((club) => {
      if (bag[club] !== 0 && dst <= bag[club] + 3) {
        setClub(club);
      }
    });

  }
  /**
   * If layup was checked disable and reset misses and clubs
   * @param {*} checked 
   */
  function handleLayup(checked) {
    setLayup(checked)
    if (checked === true && !teeShot) {
      setIsMissDisabled(true);
      setMissDir("");
      setMissDst("");
      setClub("");
    } else {
      setIsMissDisabled(false);
      setMissDir("");
      setMissDst("");
      setClub("9i");
    }
  }

  /**
   * Depending on the button clicked sets direction and distance miss
   * @param {*} miss 
   */
  function handleMiss(miss) {
    switch (miss) {
      case "High":
        setMissDir("high");
        setMissDst("");
        break;
      case "High Short":
        setMissDir("high");
        setMissDst("short");
        break;
      case "Long":
        setMissDst("long");
        setMissDir("");
        break;
      case "In the Hole":
        if (inputValue) {
          if (holeNo === 18) {
            endRoundListiner();
          } else {
            setIsNextShotDisabled(false);
            nextHoleListiner();
          }
        }
        break;
      case "Short":
        setMissDst("short");
        setMissDir("");
        break;
      case "Low":
        setMissDir("low");
        setMissDst("");
        break;
      case "Low Short":
        setMissDir("low");
        setMissDst("short");
        break;
      case "Long Left":
        setMissDir("left");
        setMissDst("long");
        break;
      case "Left":
        setMissDir("left");
        setMissDst("");
        break;
      case "Short Left":
        setMissDir("left");
        setMissDst("short");
        break;
      case "Long Right":
        setMissDir("right");
        setMissDst("long");
        break;
      case "Right":
        setMissDir("right");
        setMissDst("");
        break;
      case "Short Right":
        setMissDir("right");
        setMissDst("short");
        break;
      default:
        setMissDir("");
        setMissDst("");
    }
    if (miss.toLowerCase() === "in the hole") {
      setIsNextShotDisabled(true);
    } else {
      setIsNextShotDisabled(false);
    }

  }

  /**
   * Increments strokes on the scorecard
   */
  const handleIncrement = (wasTapIn) => {
    setCellValues((prevValues) => {
      const newValues = [...prevValues];
      if (penalty || wasTapIn) {
        newValues[currentCell] += 2;
      } else {
        newValues[currentCell] += 1;
      }
      return newValues;
    });
  };
  /**
   * changes column for scorecard table
   */
  const handleNextColumn = () => {
    setCurrentCell((prevCell) => (prevCell + 1) % 18);
  };
  /**
   * Changes color on the scorecard and saves it
   */
  function getCellStyle(wasTapIn) {
    var currentValue = cellValues[currentCell] + 1;
    if (penalty || wasTapIn) {
      currentValue += 1;
    }
    const aboveValue = selectedTee.holePar[holeNo];
    let color = "white";
    let text = "black";

    if (aboveValue != null) {
      const diff = currentValue - aboveValue;
      if (diff === 1) {
        color = "green";
      } else if (diff === 2) {
        color = "blue";
        text = "white";
      } else if (diff >= 3) {
        color = "black";
        text = "white";
      } else if (diff === -1) {
        color = "red";
        text = "white";
      } else if (diff <= -2) {
        color = "yellow";
      }
    }
    cellStyles[holeNo - 1] = { backgroundColor: color, color: text };
  }
  /**
   * Depending on shot type it renders appropriate buttons for next lie. 
   * Doesn't render anything if putting
   * @returns Buttons
   */
  function renderLies() {
    if (appShot || teeShot) {
      return (
        <div className="p-fluid">
          <label>Resulting Lie:</label>
          {liesApp.reduce((rows, lie, i) => {
            return (
              i % 3 === 0
                ? [...rows, [lie]]
                : [...rows.slice(0, -1), [...rows.slice(-1)[0], lie]]
            );
          }, []).map((row, i) => {
            return (
              <div className="mb-3 p-buttonset" key={i}>
                {row.map((lie) => {
                  return (
                    <Button
                      severity={lie === clickedLie ? 'primary' : 'secondary'}
                      key={lie}
                      value={lie}
                      label={lie}
                      size="small"
                      onClick={() => liesClick(lie)}
                    />
                  );
                })}
              </div>
            );
          })}
          {teeShot && <Button
            severity={"Tee" === clickedLie ? 'primary' : 'secondary'}
            key={"Tee"}
            value={"Tee"}
            label={"Tee"}
            size="small"
            style={{ maxWidth: "33.3%", marginLeft: "33.3%" }}
            onClick={() => liesClick("Tee")}
          />}
        </div>
      );
    }
  }
  /**
   * Renders dropdown menu for mistakes
   * @returns Dropdown
   */
  function renderMistakes() {
    return (
      <div >
        <label>Mistake</label>
        {isMobile ?
          <div className="p-fluid"><SelectButton pt={{
            label: {
              style: {
                fontSize: "12px",
              }
            },
            button: {
              style: {
                width: "25%",
                justifyContent: "center"
              }
            }
          }} options={mentals} value={mental} disabled={isMentalDisabled} onChange={(e) => setMental(e.value)} /></div>
          : <Dropdown options={mentals} value={mental} disabled={isMentalDisabled} onChange={(e) => setMental(e.value)} />}
      </div>
    )
  }
  /**
   * Render scorecard with pars taken from the database
   * @returns Table
   */
  function renderScorecard() {
    return (
      <div className="table-responsive">
        <Table style={{ textAlign: "center" }}>
          <thead>
            <tr>
              <th>Hole</th>
              <th>1</th>
              <th>2</th>
              <th>3</th>
              <th>4</th>
              <th>5</th>
              <th>6</th>
              <th>7</th>
              <th>8</th>
              <th>9</th>
              <th>Front</th>
              <th>10</th>
              <th>11</th>
              <th>12</th>
              <th>13</th>
              <th>14</th>
              <th>15</th>
              <th>16</th>
              <th>17</th>
              <th>18</th>
              <th>Back</th>
              <th>Total</th>
            </tr>
            <tr>
              <th>PAR</th>
              {
                Object.keys(selectedTee.holePar).slice(0, 9).map((rowy, indy) => (
                  <td>
                    {selectedTee.holePar[indy + 1]}
                  </td>
                ))
              }

              <td>{selectedTee.holeParOutTotal}</td>

              {
                Object.keys(selectedTee.holePar).slice(9, 18).map((rowy, indy) => (
                  <td>
                    {selectedTee.holePar[indy + 10]}
                  </td>
                ))
              }
              <td>{selectedTee.holeParInTotal}</td>
              <td>{selectedTee.holeParTotal}</td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th id="score">Score</th>
              <td style={cellStyles[0]}>{cellValues[0]}</td>
              <td style={cellStyles[1]}>{cellValues[1]}</td>
              <td style={cellStyles[2]}>{cellValues[2]}</td>
              <td style={cellStyles[3]}>{cellValues[3]}</td>
              <td style={cellStyles[4]}>{cellValues[4]}</td>
              <td style={cellStyles[5]}>{cellValues[5]}</td>
              <td style={cellStyles[6]}>{cellValues[6]}</td>
              <td style={cellStyles[7]}>{cellValues[7]}</td>
              <td style={cellStyles[8]}>{cellValues[8]}</td>
              <td id="front">{frontTotal}</td>
              <td style={cellStyles[9]}>{cellValues[9]}</td>
              <td style={cellStyles[10]}>{cellValues[10]}</td>
              <td style={cellStyles[11]}>{cellValues[11]}</td>
              <td style={cellStyles[12]}>{cellValues[12]}</td>
              <td style={cellStyles[13]}>{cellValues[13]}</td>
              <td style={cellStyles[14]}>{cellValues[14]}</td>
              <td style={cellStyles[15]}>{cellValues[15]}</td>
              <td style={cellStyles[16]}>{cellValues[16]}</td>
              <td style={cellStyles[17]}>{cellValues[17]}</td>
              <td id="back">{backTotal}</td>
              <td id="total">{cellValues.reduce((sum, value) => sum + value)}</td>
            </tr>
          </tbody>

        </Table>
      </div>
    )
  }
  /**
   * Renders checks for layup and penalty
   * @returns Checks
   */
  function renderChecks() {

    return (
      <div className="flex flex-wrap justify-content-center gap-6">
        {teeShot && <div className="flex align-items-center mb-3">
          <Checkbox inputId="forcedlayup" name="forcedLayup" onChange={(e) => setForcedLayup(e.checked)} checked={forcedLayup} />
          <label htmlFor="forcedlayup" style={{ marginLeft: "5px" }}>{forcedLayupInfo()}</label>
        </div>}
        {selectedTee && ((selectedTee.holePar[holeNo] === 4 && shotHole === 1 && inputValue < 400) || (selectedTee.holePar[holeNo] === 5 && shotHole === 2)) &&
          <div className="flex align-items-center mb-3">
            <Checkbox inputId="gfOpp" name="gfOpp" onChange={(e) => setGfOpp(e.checked)} checked={gfOpp} />
            <label htmlFor="gfOpp" style={{ marginLeft: "5px" }}>{gfOppInfo()}</label>
          </div>}
        {(appShot || teeShot) && <div className="flex align-items-center mb-3">
          <Checkbox inputId="layup" name="layup" onChange={(e) => handleLayup(e.checked)} checked={layup} />
          <label htmlFor="layup" style={{ marginLeft: "5px" }}>Layup</label>
        </div>}
        <div className="flex align-items-center mb-3">
          <Checkbox inputId="penalty" name="penalty" onChange={(e) => setPenalty(e.checked)} checked={penalty} />
          <label htmlFor="penalty" style={{ marginLeft: "5px" }}>Penalty</label>
        </div>

        {/* {((yards && inputValue <= 30) || (!yards && inputValue <= 27)) && currentLie.toLowerCase() !== "green" && <div className="flex align-items-center mb-3">
          <Checkbox inputId="shortsided" name="shortSided" onChange={(e) => setShortSided(e.checked)} checked={shortSided} />
          <label htmlFor="shortsided" style={{ marginLeft: "5px" }}>{shortSidedInfo()}</label>
        </div>} */}
      </div>
    )
  }

  function gfOppInfo() {
    return (
      <div style={{ display: "inline-flex" }}>
        <Tooltip target=".gfInfo" />
        <div>Going for it Opp</div>
        <i className='pi pi-info-circle m-1 gfInfo' data-pr-tooltip={`Going for it opportunity is for any shot 1 on a par 4 or shot 2 on a par 5 when at least 1 player on the field went for the green under regulation.`} />
      </div>
    )
  }

  function forcedLayupInfo() {
    return (
      <div style={{ display: "inline-flex" }}>
        <Tooltip target=".UDInfo" />
        <div>Forced Layup</div>
        <i className='pi pi-info-circle m-1 UDInfo' data-pr-tooltip={`Only when below: ${baseline === "LPGA" ? (yards ? "257y" : "235m") : (yards ? "297y" : "272m")}`} />
      </div>
    )
  }
  /**
   * Renders next/previous hole/shot buttons.
   * On last hole changes next hole to finish round.
   * @returns Buttons
   */
  function renderButtons() {
    if (holeNo !== 18) {
      return (
        <div className="p-buttonset" style={{ display: "flex", flexDirection: "row" }}>
          <Button label="Prev Shot" icon="pi pi-caret-left" severity="secondary" iconPos="bottom" style={{ margin: "2px" }} disabled={shotHole <= 1 || prevClicked} id="prevShot" onClick={prevShotListiner} />
          <Button label="Next Shot" icon="pi pi-caret-right" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!validInput} onClick={nextShotListiner} id="nextShot" />
          <Button label="Tap-in" icon="pi pi-fast-forward" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!validInput} onClick={tapInListiner} id="nextHole" />
          <Button label="Next Hole" icon="pi pi-forward" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!validInput} onClick={nextHoleListiner} id="nextHole" />
        </div>
      )
    } else {
      return (
        <div className="p-buttonset" style={{ display: "flex", flexDirection: "row" }}>
          <Button
            style={{ margin: "2px" }}
            disabled={shotHole <= 1 || prevClicked}
            id="prevShot"
            onClick={prevShotListiner}
            severity="secondary"
            label="Prev Shot"
            icon="pi pi-caret-left" />
          <Button label="Next Shot" icon="pi pi-caret-right" iconPos="right" style={{ margin: "2px" }} severity="secondary" disabled={!validInput} onClick={nextShotListiner} id="nextShot" />
          <Button label="Tap-in" icon="pi pi-fast-forward" iconPos="bottom" severity="secondary" style={{ margin: "2px" }} disabled={!validInput} onClick={tapInListiner} id="nextHole" />
          <Button label="Submit" icon="pi pi-check" iconPos="right" style={{ margin: "2px" }} severity="success" disabled={!validInput} onClick={endRoundListiner} />
        </div >
      )
    }
  }
  /**
   * Renders possible misses depending on the shot type
   * @returns Buttons
   */
  function renderMiss() {
    if (teeShot) {
      return (
        <div className="p-fluid mb-3">
          <label>Miss: </label>
          <div className="p-buttonset" >
            {dirTee.map((dir) => (
              <Button size="small" label={dir.miss} disabled={isTeeMissDisabled}
                onClick={() => missClick(dir.miss)} severity={dir.miss === clickedMiss ? 'primary' : 'secondary'}
                key={dir.miss} value={dir.miss}
                icon={dir.icon} iconPos={dir.pos} />
            ))}
          </div>
        </div>
      )
    } else if (appShot && ((isMissDisabled && !layup) || (layup && currentLie.toLowerCase() === "recovery")) && currentLie.toLowerCase() !== "fringe") {
      return (renderDifficulty())

    }
    else if (appShot && !isMissDisabled) {
      return (
        <div className="p-fluid mb-3">
          <label>Miss: </label>
          {dirApp.reduce((rows, dir, i) => {
            return (
              i % 3 === 0
                ? [...rows, [dir]]
                : [...rows.slice(0, -1), [...rows.slice(-1)[0], dir]]
            );
          }, []).map((row, i) => {
            return (
              <div className="mb-3 p-buttonset" key={i}>
                {row.map((dir) => {
                  return (
                    <Button
                      disabled={isMissDisabled}
                      onClick={() => missClick(dir.miss)}
                      severity={dir.miss === clickedMiss ? 'primary' : 'secondary'}
                      key={dir.miss}
                      value={dir.miss}
                      size="small"
                      label={dir.miss}
                      icon={dir.icon}
                      iconPos={dir.pos}
                    />
                  );
                })}
              </div>
            );
          })}
        </div>
      );
    }
    else if (putt) {
      return (
        <div className="p-fluid mb-3">
          <label>Miss: </label>
          {
            dirPutt.reduce((rows, lie, i) => {
              return (
                i % 3 === 0
                  ? [...rows, [lie]]
                  : [...rows.slice(0, -1), [...rows.slice(-1)[0], lie]]
              );
            }, []).map((row, i) => {
              return (
                <div className="mb-3 p-buttonset" key={i}>
                  {row.map((dir) => {
                    return (
                      <Button
                        onClick={() => missClick(dir.miss)}
                        severity={dir.miss === clickedMiss ? 'primary' : 'secondary'}
                        key={dir.miss}
                        value={dir.miss}
                        label={dir.miss}
                        icon={dir.icon}
                        iconPos={dir.pos}
                        size="small"
                      />
                    );
                  })}
                </div>
              );
            })}
        </div>
      )
    }
  }
  /**
   * Renders clubs based on whether it's a tee shot or approach
   * @returns Dropdown
   */
  function renderClubs() {
    if (!putt) {
      if (teeShot) {
        return (
          <div className="p-fluid">
            <label>Club</label>
            <Dropdown options={bagArr.length > 0 ? bagArr : clubsTee} value={club} onChange={(e) => setClub(e.value)} />
          </div>
        )
      } else if (appShot) {
        return (
          <div className="p-fluid">
            <label>Club</label>
            <Dropdown options={bagArr.length > 0 ? bagArr : clubsApp} value={club} onChange={(e) => setClub(e.value)} />
          </div >
        )
      }
    }
  }
  /**
   * Renders input box for forced layup
   * @returns Input
   */
  function renderForcedLayupInput() {
    return (
      <div className="mb-2 mt-2">
        <label>
          The furthest you can hit the tee shot:
        </label>
        <InputNumber
          value={forcedLayupDistance}
          onChange={(e) =>
            setForcedLayupDistance(e.value)}
          max={baseline === "LPGA" ? (yards ? 257 : 235) : (yards ? 297 : 272)}
          placeholder={yards ? "Yards" : "Meters"}

        />
      </div>
    )
  }
  /**
   * When finish round is pressed it sends the shot to the database
   * and opens stats page.
   */
  function endRoundListiner() {
    setEndOfRound(true);
    fillJSON();
    addShot(false);
    addScoreToRound(false);
    scorecardChange(false);
  }

  function scorecardChange(wasTapIn) {
    handleIncrement(wasTapIn);
    getCellStyle(wasTapIn);
    handleNextColumn();
  }

  function nextHoleReset(wasTapIn) {
    setPrevClicked(false);
    if (wasTapIn) {
      setCurrentIndex(currentIndex + 2);
    } else {
      setCurrentIndex(currentIndex + 1);
    }
    if (penalty || wasTapIn) {
      setShotTotal(shotTotal + 2);
    } else {
      setShotTotal(shotTotal + 1)
    }
    setShotHole(1);
    setHoleNo(holeNo + 1);
    setPrevLie(currentLie);
    setCurrentLie("tee");
    handleClear();
    setClickedLie(null);
    setClickedMiss(null);
    scorecardChange(wasTapIn);

    //reset all variables
    setNextLie("");
    setClub("Driver");
    setMissDir("");
    setMissDst("");
    setMental("Good");
    setPenalty(false);
    setLayup(false);
    setValidInput(false);
    setIsMentalDisabled(false);
    setIsNextShotDisabled(false);
    setShortSided(false);
    setIsMissDisabled(false);
    if (yards) {
      setInputValue(selectedTee.holeDist[holeNo + 1])
    } else {
      setInputValue(Math.ceil(parseInt(selectedTee.holeDist[holeNo + 1] * yardsToMeters)))
    }
    if (selectedTee.holePar[holeNo + 1] === 3) {
      setInputValue("");
      setTeeShot(false);
      setAppShot(true);
      setPutt(false);
    } else {
      setTeeShot(true);
      setAppShot(false);
      setPutt(false);
    }
    setDifficulty("Medium");
    setPinPosClicked(null);
    setPinDir(null);
    setPinDst(null);
  }

  function tapInListiner() {
    if (holeNo !== 18) {
      fillJSON();
      fillTapInJSON();
      addShot(true);
      nextHoleReset(true);
    } else {
      setEndOfRound(true);
      fillJSON();
      fillTapInJSON();
      addShot(true);
      addScoreToRound(true);
      scorecardChange(true);
    }
  }

  /**
   * When next hole button is pressed it sends the shot to the database
   * resets all values. If next hole is a par 3 it will show approach view,
   * otherwise it will be tee shot view
   */
  function nextHoleListiner() {
    //fill out json
    fillJSON();
    addShot(false);
    nextHoleReset(false);
  }

  /**
   * When next hole button is pressed it sends the shot to the database.
   * Then it determines the type of the next shot to render appropriate components
   */
  function nextShotListiner() {
    //fill out json
    fillJSON();
    addShot();
    setPrevClicked(false);
    if (!penalty) {
      setShotHole(shotHole + 1);
      setShotTotal(shotTotal + 1);
    } else {
      setShotHole(shotHole + 2);
      setShotTotal(shotTotal + 2);
    }
    setPrevLie(currentLie);

    setNextLie("");


    //checks what the resulting lie is and establishes the shot type
    if (teeShot & (nextLie.toLowerCase() === "green")) {//if the green was hit the next shot will be a putt
      setTeeShot(false);
      setAppShot(false);
      setPutt(true);
      setNextLie("green");
    } else if (teeShot & (nextLie.toLowerCase() === "tee")) {
      setTeeShot(true);
      setAppShot(false);
      setPutt(false);
      setNextLie("tee");
    } else if (teeShot) {// after a tee shot if the green was not hit it will be an approach
      setTeeShot(false);
      setAppShot(true);
      setPutt(false);
      setClub("9i");
    } else if (appShot & (nextLie.toLowerCase() === "green")) {//if the green was hit the next shot will be a putt
      setTeeShot(false);
      setAppShot(false)
      setPutt(true);
      setNextLie("green");
      setClub("");
    } else if (putt) {
      setNextLie("green");
    }
    if (currentIndex < shotsArray.length && shotsArray[currentIndex + 1]) {
      var miss = null;
      if (shotsArray[currentIndex + 1].missDst === "") {
        miss = capitalizeFirstLetter(shotsArray[currentIndex + 1].missDir);
      } else {
        miss = capitalizeFirstLetter(shotsArray[currentIndex + 1].missDst) + " " + capitalizeFirstLetter(shotsArray[currentIndex + 1].missDir);
      }
      if (shotsArray[currentIndex + 2]?.lie) {
        setNextLie(shotsArray[currentIndex + 2].lie)
        setClickedLie(capitalizeFirstLetter(shotsArray[currentIndex + 2].lie));
      }
      setCurrentLie(nextLie);
      setClickedMiss(miss);
      handleMiss(miss);
      setIsMentalDisabled(false);
      setIsNextShotDisabled(false);
      setMental(shotsArray[currentIndex + 1].mental);
      setPenalty(shotsArray[currentIndex + 1].penalty);
      setLayup(shotsArray[currentIndex + 1].layup);
      if (yards) {
        setInputValue(shotsArray[currentIndex + 1].shotDst)
        handleInput(shotsArray[currentIndex + 1].shotDst);
      } else {
        setInputValue(Math.ceil(parseInt(shotsArray[currentIndex + 1].shotDst * yardsToMeters)));
        handleInput(Math.ceil(parseInt(shotsArray[currentIndex + 1].shotDst * yardsToMeters)));
      }
    } else {
      setCurrentLie(nextLie);
      if (currentIndex === shotsArray.length) {
        handleIncrement();
        getCellStyle();
      }
      if (teeShot & (nextLie.toLowerCase() === "tee")) {
        setInputValue(inputValue)
      } else {
        handleClear();
      }
      setClickedLie(null);
      setClickedMiss(null);
      //reset all variables
      setForcedLayup(false);
      setMissDir("");
      setMissDst("");
      setIsMentalDisabled(false);
      setIsNextShotDisabled(false);
      setMental("Good");
      setPenalty(false);
      if (nextLie.toLowerCase() === "recovery") {
        setLayup(true);
      } else {
        setLayup(false);
      }
      setShortSided(false);
    }
    setForcedLayupDistance("");
    setCurrentIndex(currentIndex + 1);
    setDifficulty("Medium");
    setGfOpp(false);
  };

  function prevShotListiner() {
    //setPrevClicked(true);
    if (!shotsArray[currentIndex - 1].penalty) {
      setShotHole(shotHole - 1);
      setShotTotal(shotTotal - 1);
    } else {
      setShotHole(shotHole - 2);
      setShotTotal(shotTotal - 2);
    }
    var pLie = shotsArray[currentIndex - 1] ? capitalizeFirstLetter(shotsArray[currentIndex - 1].lie) : prevLie;
    var nLie = shotsArray[currentIndex] ? shotsArray[currentIndex].lie : currentLie;

    setClickedLie(capitalizeFirstLetter(currentLie));
    setCurrentLie(pLie);
    setNextLie(nLie);
    if ((nLie.toLowerCase() === "fairway" || nLie.toLowerCase() === "green" || nLie.toLowerCase() === "fringe") && pLie.toLowerCase() === "tee") {
      setIsTeeMissDisabled(true);
    } else {
      setIsTeeMissDisabled(false);
    }
    var miss = null;
    if (shotsArray[currentIndex - 1].lie === "tee") {
      setTeeShot(true);
      setAppShot(false);
      setPutt(false);
      miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDir);
    } else if (shotsArray[currentIndex - 1].lie === "green") {
      setTeeShot(false);
      setAppShot(false);
      setPutt(true);
      if (shotsArray[currentIndex - 1].missDst === "") {
        miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDir);
      } else if (shotsArray[currentIndex - 1].missDir === "") {
        miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDst);
      } else {
        miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDir) + " " + capitalizeFirstLetter(shotsArray[currentIndex - 1].missDst);
      }
    } else {
      setTeeShot(false);
      setAppShot(true);
      setPutt(false);
      if (shotsArray[currentIndex - 1].missDst === "") {
        miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDir);
      } else if (shotsArray[currentIndex - 1].missDir === "") {
        miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDst);
      } else {
        miss = capitalizeFirstLetter(shotsArray[currentIndex - 1].missDst) + " " + capitalizeFirstLetter(shotsArray[currentIndex - 1].missDir);
      }
    }
    if (yards || shotsArray[currentIndex - 1].lie === "green") {
      setInputValue(shotsArray[currentIndex - 1].shotDst);
      handleInput(shotsArray[currentIndex - 1].shotDst);
    } else {
      setInputValue(Math.ceil(parseInt(shotsArray[currentIndex - 1].shotDst * yardsToMeters)))
      handleInput(Math.ceil(parseInt(shotsArray[currentIndex - 1].shotDst * yardsToMeters)))
    }
    setClickedMiss(miss);
    handleMiss(miss);

    setForcedLayup(false);
    setIsMentalDisabled(false);
    setIsNextShotDisabled(false);
    setMental(capitalizeFirstLetter(shotsArray[currentIndex - 1].mental));
    setPenalty(shotsArray[currentIndex - 1].penalty);
    setLayup(shotsArray[currentIndex - 1].layup);
    var club = shotsArray[currentIndex - 1].club.charAt(1) === 'w' ? shotsArray[currentIndex - 1].club.toUpperCase() : capitalizeFirstLetter(shotsArray[currentIndex - 1].club)
    setClub(club)

    setCurrentIndex(currentIndex - 1);
  }

  function renderDifficulty() {
    return (
      <div className="p-fluid mb-3">
        <label>Difficulty: {currentLie.toLowerCase() === "recovery" ? recoveryDifficultyInfo() : chipDifficultyInfo()}</label>
        <SelectButton severity="secondary" value={difficulty} options={difficultyOptions} onChange={(e) => setDifficulty(e.value)} />
      </div>
    )
  }

  function puttDifficultyInfo() {
    return (
      <div style={{ display: "inline-flex" }}>
        <Tooltip target=".puttInfo" />
        <i className='pi pi-info-circle m-1 puttInfo' data-pr-tooltip="Easy putt is a flat or downhill putt with 0-1% break or uphill putt with 0-2% of break.
        Medium putt is a putt with 2-3% break. Hard putt is a putt with 3+% of break. For longer putts also adjust for double
        breaks. Be reasonable. If not sure select medium." />
      </div>
    )
  }

  function chipDifficultyInfo() {
    return (
      <div style={{ display: "inline-flex" }}>
        <Tooltip target=".chipInfo" />
        <i className='pi pi-info-circle m-1 chipInfo' data-pr-tooltip="Inside 30yds. Easy chip is a chip where the distance from the ball to the edge of the green is less 
        than 1/3 of the distance of the shot. Hard chip is a chip where the distance from the ball to the edge of the green is more than 2/3
        of the total distance. Medium shot is in between. Adjust for lies. If not sure select medium. Outside of 30yds is more subjective." />
      </div>
    )
  }

  function recoveryDifficultyInfo() {
    return (
      <div style={{ display: "inline-flex" }}>
        <Tooltip target=".chipInfo" />
        <i className='pi pi-info-circle m-1 chipInfo' data-pr-tooltip="Easy recovery is when you don't have a clean shot at the pin, but still
        able to advance the green. For example a tree is in the way and you have to punch out but you're able to put the ball close or on the green.
        Medium reovery is a recovery where you cannot take a normal line at the pin. Hard recovery is basically a sideways or backwards chip out.
        If not sure select medium." />
      </div>
    )
  }

  function pinPosHandler(pos) {
    setPinPosClicked(pos);
    switch (pos) {
      case "Back Left":
        setPinDst("back");
        setPinDir("left");
        break;
      case "Back Center":
        setPinDst("back");
        setPinDir("center");
        break;
      case "Back Right":
        setPinDst("back");
        setPinDir("right");
        break;
      case "Left":
        setPinDst("middle");
        setPinDir("left");
        break;
      case "Middle":
        setPinDst("middle");
        setPinDir("center");
        break;
      case "Right":
        setPinDst("middle");
        setPinDir("right");
        break;
      case "Front Left":
        setPinDst("front");
        setPinDir("left");
        break;
      case "Front Center":
        setPinDst("front");
        setPinDir("center");
        break;
      case "Front Right":
        setPinDst("front");
        setPinDir("right");
        break;
      default:
        break;
    }
  }

  function renderPinPos() {
    return (
      <div className="p-fluid mb-3">
        <label>Pin Position: </label>
        {pinPos.reduce((rows, pos, i) => {
          return (
            i % 3 === 0
              ? [...rows, [pos]]
              : [...rows.slice(0, -1), [...rows.slice(-1)[0], pos]]
          );
        }, []).map((row, i) => {
          return (
            <div className="mb-3 p-buttonset" key={i}>
              {row.map((pos) => {
                return (
                  <Button
                    onClick={() => pinPosHandler(pos.miss)}
                    severity={pos.miss === pinPosClicked ? 'primary' : 'secondary'}
                    key={pos.miss}
                    value={pos.miss}
                    size="small"
                    label={pos.miss}
                    icon={pos.icon}
                    iconPos={pos.pos}
                    outlined={pos.miss !== pinPosClicked}
                  />
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  /**
   * checks if all necessary buttons/inputs are done so user can go to next shot/hole
   */
  useEffect(() => {
    if (teeShot) {
      if (inputValue && nextLie && mental && club) {
        if (nextLie.toLowerCase() === "fairway" || nextLie.toLowerCase() === "green" || nextLie.toLowerCase() === "fringe") {
          setValidInput(true);
        } else {
          if (clickedMiss) {
            setValidInput(true);
          } else {
            setValidInput(false);
          }
        }
      } else {
        setValidInput(false);
      }
    }
    else if (appShot) {
      if (inputValue <= 50) {
        if (inputValue && nextLie && mental) {
          setValidInput(true);
        } else {
          setValidInput(false);
        }
      } else {
        if (inputValue && nextLie && mental && (club || layup) && ((clickedMiss || layup))) {
          setValidInput(true);
        } else {
          setValidInput(false);
        }
      }
    } else if (putt) {
      if (inputValue <= 2 && inputValue && nextLie && clickedMiss) {
        setValidInput(true);
      }
      else if (inputValue && nextLie && mental && clickedMiss) {
        setValidInput(true);
      } else {
        setValidInput(false);
      }

    }
  }, [inputValue, nextLie, mental, club, layup, clickedMiss, isNextShotDisabled]);

  useEffect(() => {
    if (forcedLayup) {
      setLayup(true);
    } else {
      setLayup(false);
    }
  }, [forcedLayup])

  return (
    <div>
      <Container>
        <Row>
          <Col xs="12" md="12" lg="12">
            {selectedTee && renderScorecard()}
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs="6" md={{ offset: 2, size: 4 }} lg={{ offset: 4, size: 2 }}>
            <div>
              Current Shot: <Badge value={shotHole} />
            </div>
          </Col>
          <Col xs="6" md={{ offset: 2, size: 4 }} lg={{ offset: 0, size: 4 }}>
            <div>
              Current Hole: <Badge value={holeNo} />
            </div>
          </Col>
          <Row className="d-none d-md-flex mt-3">
            <Col xs="12" lg={{ offset: 0, size: 12 }} style={{ display: "flex", justifyContent: "center" }}>
              {renderButtons()}
            </Col>
          </Row>

        </Row>
        <Row className="mt-3 mb-5">
          <Col xs="12" md="4" lg="4" className="p-fluid">
            <label htmlFor="distance-in">Distance: </label>
            <InputNumber id="distance-in" value={inputValue}
              onChange={(e) => handleInput(e.value)}
              max={800}
              placeholder={putt ? "Feet" : yards ? "Yards" : "Meters"}
              className="mb-3" />
            {renderLies()}
          </Col>
          <Col xs="12" md="4" lg="4">
            {renderMiss()}
            {forcedLayup && renderForcedLayupInput()}
          </Col>
          <Col xs="12" md="4" lg="4">
            <Row>
              {appShot && (yards ? inputValue > 50 : inputValue > 46) && renderPinPos()}
            </Row>
            <Row >
              <Col xs="6" md="6" lg="4">
                {((inputValue > (yards ? 50 : 45) && !layup) || teeShot) && renderClubs()}
              </Col>
              <Col xs="12" md="6" lg="5">
                {renderMistakes()}
              </Col>
              <Col className="mt-3" xs="12" md="12" lg="12">
                {renderChecks()}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="d-flex d-md-none">
          <Col xs="12" className="mb-4" >
            {renderButtons()}
          </Col>
        </Row>

      </Container>
    </div >
  );
}
