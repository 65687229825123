import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import AddCourse from "./AddCourse";


function DisplayCourses(props) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);


  let courseData = props.courseData;
  useEffect(() => {
    if (selectedCourse != null) {
      props.onClick(selectedCourse);
    }
  }, [selectedCourse]);


  const header = (

    <div className="table-header" >
      Search courses
      <span className="p-input-icon-right" style={{ marginLeft: "5px" }}>
        <i className="pi pi-search" />
        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search a course" />
      </span>
      <span className="p-input-icon-right" style={{ marginLeft: "0px", scale: "1", position: "relative", zIndex: "2" }}>
        <AddCourse onClick2={props.onClick2} toast={props.toast} style={{ scale: "0.5", zIndex: "5" }} />
      </span>
    </div>
  );
  return (
    <div>
      <DataTable
        className={courseData.length > 0 ? "" : "loading-dots"}
        value={courseData}
        header={header}
        globalFilter={globalFilter}
        size={'small'}
        resizableColumns
        columnResizeMode="expand"
        selectionMode="single"
        dataKey="id"
        tableStyle={{}}
        selection={selectedCourse}
        onSelectionChange={(e) => setSelectedCourse(e.value)}
        paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}>

        <Column field="courseName" header="Course Name"></Column>
        <Column field="city" header="City"></Column>
        <Column field="country" header="Country"></Column>

      </DataTable>
    </div>
  );
}

export { DisplayCourses };
