import React from "react";
import "../../css/new-round.css";
import { Dropdown } from "primereact/dropdown";

export function GamesDropdown({ selectedGame, setSelectedGame, games }) {




    return (
        <div>
            <Dropdown id="gamesdropdown"
                color="primary"
                value={selectedGame}
                onChange={(event) => {
                    setSelectedGame(event.target.value)
                }}
                options={games.sort((a, b) => {
                    // Convert names to lowercase for case-insensitive sorting
                    const nameA = a.name.toLowerCase();
                    const nameB = b.name.toLowerCase();

                    // Compare the names
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                })}
                optionLabel="name"
                placeholder="Select a Game"
                className="w-full md:w-14rem" />
        </div>
    )
}
