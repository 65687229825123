import React, { useState } from "react";
import "../../css/new-round.css";
import { Dropdown } from "primereact/dropdown";

export function DurationDropdown({ setNumberOfRounds, setTimeDuration, setCustom, setDurationType, durations }) {

    /** Selected duration time*/
    const [duration, setDuration] = useState("Last 10 Rounds");


    /**
     * Depending on time duration selected it sets the type to number/time/custom/tournament
     * number - number of last rounds;
     * time - number of last months;
     * custom - render custom date range fields;
     * torunament - render tournament dropdown;
     * @param {*} event 
     */
    function handleDuration(event) {
        setDuration(event.target.value);
        if (event.target.value.toLowerCase() !== "custom") {
            setCustom(false);
        }
        switch (event.target.value.toLowerCase()) {
            case "last round":
                setNumberOfRounds(1);
                setDurationType("number");
                break;
            case "last 3 rounds":
                setNumberOfRounds(3);
                setDurationType("number");
                break;
            case "last 4 rounds":
                setNumberOfRounds(4);
                setDurationType("number");
                break;
            case "last 10 rounds":
                setNumberOfRounds(10);
                setDurationType("number");
                break;
            case "last 15 rounds":
                setNumberOfRounds(15);
                setDurationType("number");
                break;
            case "last 20 rounds":
                setNumberOfRounds(20);
                setDurationType("number");
                break;
            case "last 30 rounds":
                setNumberOfRounds(30);
                setDurationType("number");
                break;
            case "last month":
                setTimeDuration(1);
                setDurationType("time");
                break;
            case "last 3 months":
                setTimeDuration(3);
                setDurationType("time");
                break;
            case "last 6 months":
                setTimeDuration(6);
                setDurationType("time");
                break;
            case "last 12 months":
                setTimeDuration(12);
                setDurationType("time");
                break;
            case "this year":
                setDurationType("this year");
                break;
            case "fall":
                setDurationType("fall");
                break;
            case "spring":
                setDurationType("spring");
                break;
            case "custom":
                setDurationType("custom");
                setCustom(true);
                break;
            case "select tournament":
                setDurationType("tournament");
                //setSelectTournament(true);
                break;
            default:
                break;
        }
    }




    const selectedDurationTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center"><div>{option}</div>
                </div>
            );
        }
        return <span>{props.placeholder}</span>;
    };

    const durationOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center"><div>{option}</div>
            </div>
        );
    };

    return (
        <Dropdown value={duration} onChange={(event) => handleDuration(event)} options={durations} optionLabel="name" placeholder="Select a Duration"
            valueTemplate={selectedDurationTemplate} itemTemplate={durationOptionTemplate} className="w-full md:w-14rem" />
    )
}