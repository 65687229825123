import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Profile } from "./Profile";

function Auth0Example() {

  const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
  const [apiData, setApiData] = useState(null);

  const callApi = async () => {
    try {
      const token = await getAccessTokenSilently({
        authorizationParams: {
          audience: 'https://parfectperformance.com/api',
          scope: 'read:courses'
        },
      });

      const response = await fetch('api/course', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      setApiData(responseData);
      console.log(responseData)
    } catch (error) {
      console.log(error.error)
      if (error.error === 'login_required') {
        loginWithRedirect();
      } else if (error.error === 'consent_required') {

        const token = await getAccessTokenSilently({
          authorizationParams: {
            audience: 'https://parfectperformance.com/api',
            scope: 'read:courses'
          },
        });
      } else {
        console.error(error);
      }
    }
  };


  return (
    <div>
      <Profile> </Profile>

      <div>
        <button onClick={callApi}>Call API</button>
        {apiData && <pre>{JSON.stringify(apiData, null, 2)}</pre>}
      </div>
    </div>
  );
}

export { Auth0Example };