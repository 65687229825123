import React, { useState, useEffect } from "react";

import { SelectButton } from "primereact/selectbutton";
const ApproachPractice = ({ setStartNewApproach, setShowApproachResult, setLowDistance, setHighDistance }) => {


    const options = ["Start New 50-100y", "Start New 75-125y", "Start New 100-150y", "Start New 150-200y", "View Results"];
    const [value, setValue] = useState("")

    useEffect(() => {
        approachOptions()
    }, [value])

    function approachOptions() {
        if (value === "Start New 50-100y") {
            setStartNewApproach(true); setShowApproachResult(false); setLowDistance(50); setHighDistance(100);
        } else if (value === "Start New 75-125y") {
            setStartNewApproach(true); setShowApproachResult(false); setLowDistance(75); setHighDistance(125);
        } else if (value === "Start New 100-150y") {
            setStartNewApproach(true); setShowApproachResult(false); setLowDistance(100); setHighDistance(150);
        } else if (value === "Start New 150-200y") {
            setStartNewApproach(true); setShowApproachResult(false); setLowDistance(150); setHighDistance(200);
        } else if (value === "View Results") {
            setShowApproachResult(true); setStartNewApproach(false);
        }
    }

    return (
        <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
            <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} style={{ display: "flex", justifyContent: "center", width: "90%", }} />
        </div>
    )
}

export { ApproachPractice }