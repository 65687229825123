import React, { useState, useEffect } from "react";
import "../../css/new-round.css";
import { useAuth0 } from "@auth0/auth0-react";
import { Dropdown } from "primereact/dropdown";

export function PlayersDropdown({ setSelectedUserId, selectedUser, setSelectedUser, coachTeam }) {

    const [allUserAccounts, setAllUserAccounts] = useState([]);
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [loading, setLoading] = useState(true);
    //const [selectedUser, setSelectedUser] = useState({ name: "Select Player", userId: 0 })
    const [groupedPlayers, setGroupedPlayers] = useState(null);
    const coachesIds = ["google-oauth2|107153675129420164686", "google-oauth2|111751767583089427570", "google-oauth2|103519004092922203859", "google-oauth2|109182936532771609243", "google-oauth2|104069399016484953210", "google-oauth2|101828915672761203306"]


    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:users'
                    },
                });

                const response = await fetch('api/user', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setAllUserAccounts(responseData);
                setLoading(false);
                //console.log(responseData)
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    const token = await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    const token = await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        fetchAllUsers();

    }, [loginWithRedirect, getAccessTokenSilently]);

    useEffect(() => {
        if (allUserAccounts) {
            var group = [{
                groupName: "Women",
                baseline: "LPGA",
                players: []
            },
            {
                groupName: "Men",
                baseline: "PGA",
                players: []
            },
            {
                groupName: "Other Players",
                baseline: "",
                players: []
            }]
            for (const p of allUserAccounts) {
                const match = group.find(grp => grp.baseline === p.baseline);
                if (match && coachTeam === p.team && !coachesIds.includes(p.userId)) {
                    match.players.push(p);
                } else if (coachTeam !== p.team || coachesIds.includes(p.userId)) {
                    group.find(grp => grp.groupName === "Other Players")?.players.push(p)
                }
            }
            setGroupedPlayers(group);
        }
    }, [allUserAccounts])


    useEffect(() => {
        var user = JSON.parse(window.sessionStorage.getItem("selectedUser"));
        if (user) {
            setSelectedUser(user);
            setSelectedUserId(user.userId.substring(user.userId.indexOf('|') + 1))
        }
    }, [])

    const groupedPlayerTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <div>{option.groupName}</div>
            </div>
        );
    };

    return (
        <div>
            <Dropdown id="playersdropdown"
                color="primary"
                value={selectedUser}
                onChange={(event) => {
                    setSelectedUser(event.target.value);
                    window.sessionStorage.setItem("selectedUser", JSON.stringify(event.target.value));
                    setSelectedUserId(event.target.value.userId.substring(event.target.value.userId.indexOf('|') + 1));
                }}
                options={groupedPlayers}
                optionLabel="name"
                optionGroupLabel="groupName"
                optionGroupChildren="players"
                optionGroupTemplate={groupedPlayerTemplate}
                placeholder={loading ? "Loading" : "Select a Player"}
                dropdownIcon={loading ? "pi pi-spinner" : "pi pi-chevron-down"}
                filter
                filterPlaceholder="Search Player"
                className="w-full md:w-14rem" />
        </div>
    )
}
