import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";

const GetScoresApproach = ({ player, lowDistancePicked, setSeeDetails, setUserDetailsSelected }) => {
    const [rounds, setRounds] = useState([]);
    const [scores, setScores] = useState([])
    const [lastScore, setLastScore] = useState(null);
    const [last5Score, setLast5Score] = useState(null);
    const [last10Score, setLast10Score] = useState(null);
    const [last30Score, setLast30Score] = useState(null);

    const id = player.userId.substring(player.userId.indexOf('|') + 1);

    useEffect(() => {
        getScores();

    }, [player]);
    function getScores() {
        fetch(`/api/practiceapproachcalculation/user-last?userId=${id}&lowDistance=${lowDistancePicked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                setScores(data);
                setLastScore(data[0])
                setLast5Score(parseFloat(data.slice(0, 5).reduce((sum1, score) => sum1 + score, 0)).toFixed(2))
                setLast10Score(parseFloat(data.slice(0, 10).reduce((sum2, score) => sum2 + score, 0)).toFixed(2))
                setLast30Score(parseFloat(data.slice(0, 30).reduce((sum2, score) => sum2 + score, 0)).toFixed(2))
            })
            .catch(error => {
                setLast30Score("--");
                setLast10Score("--");
                setLast5Score("--");
                setLastScore("--");
            });
    }
    function getRounds() {
        fetch(`/api/approachpractice/user?userId=${id}&lowDistance=${lowDistancePicked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => response.json())
            .then(data => {
                setRounds(data);
                getScores();
            })
            .catch(error => {

            });
    }
    useEffect(() => {
        setRounds([])
        setScores([])
        setLastScore(null);
        setLast5Score(null);
        setLast10Score(null);
        setLast30Score(null);
        getRounds();
    }, [lowDistancePicked])

    function selectDetails(player) {
        setSeeDetails(true);
        setUserDetailsSelected(player);
    }
    return (
        <tr key={player.userId}>
            <td>{player.name}</td>
            <td style={{ color: lastScore < 0 ? "red" : lastScore > 0 ? "green" : "" }}>{lastScore}</td>
            <td style={{ color: last5Score < 0 ? "red" : last5Score > 0 ? "green" : "" }}>{last5Score}</td>
            <td style={{ color: last10Score < 0 ? "red" : last10Score > 0 ? "green" : "" }}>{last10Score}</td>
            <td style={{ color: last30Score < 0 ? "red" : last30Score > 0 ? "green" : "" }}>{last30Score}</td>
            <td><Button size="small" severity="secondary" label="See Details" onClick={() => selectDetails(player)} /></td>
        </tr>
    );
};

export { GetScoresApproach };
