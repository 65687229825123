import { useAuth0 } from "@auth0/auth0-react";
import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { MultiSelect } from "primereact/multiselect"
import { useEffect, useState } from "react";
import { RoundTypeDropdown } from "../dropdowns/RoundTypeDropdown";
import { DurationDropdown } from "../dropdowns/DurationDropdown";
import { Button } from "primereact/button";
import { Table } from "reactstrap";
import { Message } from 'primereact/message';

import { calculateTime } from "./PracticeFunctions";

const Planner = () => {
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"
    const { user, isAuthenticated, getAccessTokenSilently, loginWithRedirect } = useAuth0();
    const userId = user.sub.substring(user.sub.indexOf('|') + 1);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [elements, setElements] = useState(1);
    const [roundType, setRoundType] = useState("All");
    const [numberOfRounds, setNumberOfRounds] = useState(10);
    const [timeDuration, setTimeDuration] = useState(0);
    const [durationType, setDurationType] = useState("number");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [custom, setCustom] = useState(false);
    const [stats, setStats] = useState(null);
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "This Year", "Fall", "Spring", "Custom"];
    const options = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement', 'Time'];
    const analysisOptions = ['Name', 'Total', 'Attempts', 'PerShot', 'Improvement'];
    const practiceOptions = ['Name', 'Total', 'Time'];
    const [state, setState] = useState(() => {
        const initialState = {};
        for (var i = 1; i < 8; i++) {
            options.forEach((option) => {
                initialState[`analysis${i}${option}`] = '';
                initialState[`strength${i}${option}`] = '';
            })
        }
        return initialState;
    })
    const [numberImprovement, setNumberImprovement] = useState(2);
    const [numberStrength, setNumberStrength] = useState(1);
    const [scoreAverage, setScoreAverage] = useState("");
    const [totalImprovement, setTotalImprovement] = useState("");
    const [newScoreAverage, setNewScoreAverage] = useState("");
    const [units, setUnits] = useState("yards")

    const [loadingButton, setLoadningButton] = useState(false);
    const facilities = ["Range Driver", "Range Irons", "Short Game Area", "Putting Green"];
    const [selectedFacilities, setSelectedFacilities] = useState(facilities)

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:myself'
                    },
                });

                const response = await fetch(`api/User/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setUnits(responseData.units);
                window.localStorage.setItem("units", responseData.units);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        var units = window.localStorage.getItem("units");
        if (units) {
            setUnits(units);
            fetchUser();
        } else {
            fetchUser();
        }
    }, [isAuthenticated]);

    useEffect(() => {
        switch (elements) {
            case 3:
                setNumberImprovement(2);
                setNumberStrength(1);
                break;
            case 4:
                setNumberImprovement(3);
                setNumberStrength(1);
                break;
            case 5:
                setNumberImprovement(3);
                setNumberStrength(2);
                break;
            case 6:
                setNumberImprovement(4);
                setNumberStrength(2);
                break;
            case 7:
                setNumberImprovement(5);
                setNumberStrength(2);
                break;
            case 8:
                setNumberImprovement(5);
                setNumberStrength(3);
                break;
            case 9:
                setNumberImprovement(6);
                setNumberStrength(3);
                break;
            case 10:
                setNumberImprovement(7);
                setNumberStrength(3);
                break;
        }
    }, [elements])

    function fillAnalysis(data) {
        // Define the variables and their values
        var variables = [
            { name: "PUTT 3'", value: data.sgPutting3, attempts: data.attempts3, perShot: data.sgPutting3 / data.attempts3, improvement: (data.sgPutting3 / data.attempts3) * (data.attempts3 / data.noOfRounds), time: 0, facility: "Putting Green" },
            { name: "PUTT 3-5'", value: data.sgPutting35, attempts: data.attempts35, perShot: data.sgPutting35 / data.attempts35, improvement: (data.sgPutting35 / data.attempts35) * (data.attempts35 / data.noOfRounds), time: 0, facility: "Putting Green" },
            { name: "PUTT 5-10'", value: data.sgPutting510, attempts: data.attempts510, perShot: data.sgPutting510 / data.attempts510, improvement: (data.sgPutting510 / data.attempts510) * (data.attempts510 / data.noOfRounds), time: 0, facility: "Putting Green" },
            { name: "PUTT 10-20'", value: data.sgPutting1020, attempts: data.attempts1020, perShot: data.sgPutting1020 / data.attempts1020, improvement: (data.sgPutting1020 / data.attempts1020) * (data.attempts1020 / data.noOfRounds), time: 0, facility: "Putting Green" },
            { name: "PUTT 20-30'", value: data.sgPutting2030, attempts: data.attempts2030, perShot: data.sgPutting2030 / data.attempts2030, improvement: (data.sgPutting2030 / data.attempts2030) * (data.attempts2030 / data.noOfRounds), facility: "Putting Green" },
            { name: "Lag Putting 30'+", value: data.sgPuttingLagOver30, attempts: data.attempts30, perShot: data.sgPuttingLagOver30 / data.attempts30, improvement: (data.sgPuttingLagOver30 / data.attempts30) * (data.attempts30 / data.noOfRounds), facility: "Putting Green" },
            { name: `ARG ${units === "meters" ? "0-27m" : "0-30y"}`, value: data.sgARG030 * data.sgARG030Attempts, attempts: data.sgARG030Attempts, perShot: data.sgARG030, improvement: (data.sgARG030) * (data.sgARG030Attempts / data.noOfRounds), time: 0, facility: "Short Game Area" },
            { name: `ARG ${units === "meters" ? "27-46m" : "30-50y"}`, value: data.sgARG3050 * data.sgARG3050Attempts, attempts: data.sgARG3050Attempts, perShot: data.sgARG3050, improvement: (data.sgARG3050) * (data.sgARG3050Attempts / data.noOfRounds), time: 0, facility: "Short Game Area" },
            { name: `APP ${units === "meters" ? "46-91m" : "50-100y"}`, value: data.sg50100 * data.sg50100Attempts, attempts: data.sg50100Attempts, perShot: data.sg50100, improvement: (data.sg50100) * (data.sg50100Attempts / data.noOfRounds), time: 0, facility: "Range Irons" },
            { name: `APP ${units === "meters" ? "91-137m" : "100-150y"}`, value: data.sg100150 * data.sg100150Attempts, attempts: data.sg100150Attempts, perShot: data.sg100150, improvement: (data.sg100150) * (data.sg100150Attempts / data.noOfRounds), time: 0, facility: "Range Irons" },
            { name: `APP ${units === "meters" ? "137-183m" : "150-200y"}`, value: data.sg150200 * data.sg150200Attempts, attempts: data.sg150200Attempts, perShot: data.sg150200, improvement: (data.sg150200) * (data.sg150200Attempts / data.noOfRounds), time: 0, facility: "Range Irons" },
            { name: `APP ${units === "meters" ? "183+m" : "200+y"}`, value: data.sg200 * data.sg200Attempts, attempts: data.sg200Attempts, perShot: data.sg200, improvement: (data.sg200) * (data.sg200Attempts / data.noOfRounds), time: 0, facility: "Range Irons" },
            { name: 'Tee Shots', value: data.sgOTTTotal, attempts: data.teeAttempts, perShot: data.sgOTTTotal / data.teeAttempts, improvement: (data.sgOTTTotal / data.teeAttempts) * (data.teeAttempts / data.noOfRounds), time: 0, facility: "Range Driver" }
        ];

        // Sort the variables based on their values in ascending order
        variables.sort(function (a, b) {
            return a.value - b.value;
        });
        let result = variables.filter(e => selectedFacilities.includes(e.facility));
        console.log(result);
        // Get the first five variables with the lowest values
        var numberOfBad = numberImprovement < 5 ? 5 : numberImprovement
        var lowestVariables = result.slice(0, numberOfBad);

        for (let i = 0; i < numberOfBad; i++) {
            setState((prev) => ({
                ...prev,
                [`analysis${i + 1}Name`]: lowestVariables[i].name,
                [`analysis${i + 1}Total`]: parseFloat(lowestVariables[i].value).toFixed(2),
                [`analysis${i + 1}Attempts`]: lowestVariables[i].attempts,
                [`analysis${i + 1}PerShot`]: parseFloat(lowestVariables[i].perShot).toFixed(3),
                [`analysis${i + 1}Improvement`]: parseFloat(lowestVariables[i].improvement).toFixed(2),
            }));
        }


        setScoreAverage(data.scoreAvg);
        let sum = 0.0;
        for (var i = 0; i < 5; i++) {
            if (lowestVariables[i].name !== "ARG Sand" && lowestVariables[i].name !== 'ARG Fringe') {
                sum += lowestVariables[i].improvement;
            }
        }
        setTotalImprovement((parseFloat(sum).toFixed(2)));
        setNewScoreAverage((parseFloat(data.scoreAvg + sum).toFixed(2)))

        var numberOfGood = numberStrength < 5 ? 5 : numberStrength
        // Get the last five variables with the lowest values
        var highestVariables = result.slice(result.length - numberOfGood, result.length);


        for (let i = 0; i < numberOfGood; i++) {
            setState((prev) => ({
                ...prev,
                [`strength${i + 1}Name`]: highestVariables[4 - i].name,
                [`strength${i + 1}Total`]: parseFloat(highestVariables[4 - i].value).toFixed(2),
                [`strength${i + 1}Attempts`]: highestVariables[4 - i].attempts,
                [`strength${i + 1}PerShot`]: parseFloat(highestVariables[4 - i].perShot).toFixed(3),
                [`strength${i + 1}Improvement`]: parseFloat(highestVariables[4 - i].improvement).toFixed(2),
            }));
        }

        calculateTime(result, numberImprovement, numberStrength, totalMinutes, setState);
    }



    useEffect(() => {
        if (stats !== null)
            fillAnalysis(stats);
    }, [stats])
    function renderStrengths() {
        return (
            <div className="table-responsive">
                <Table id='strengthtable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Total SG</th>
                            <th>Att.</th>
                            <th>SG per Shot</th>
                            <th>Avg. SG per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <tr style={{
                                color: 'white',
                                backgroundColor: green
                            }}>
                                <td className='stats-title'>{index + 1}</td>
                                {analysisOptions.map((option) => (
                                    <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </Table>
            </div>
        )

    }
    function renderAnalysis() {
        return (
            <div className="table-responsive">
                <Table id='analysistable' className='stats-table'>
                    <thead>
                        <tr>
                            <th>Rank</th>
                            <th>Name</th>
                            <th>Total SG</th>
                            <th>Att.</th>
                            <th>SG per Shot</th>
                            <th>Avg. SG per Round</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Array.from({ length: 5 }).map((_, index) => (
                            <tr style={{
                                color: 'white',
                                backgroundColor: red
                            }}>
                                <td className='stats-title'>{index + 1}</td>
                                {analysisOptions.map((option) => (
                                    <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                ))}
                            </tr>
                        ))}

                    </tbody>
                </Table>
                <div>Current Score Avg.: {scoreAverage}</div>
                <div>Total Score Avg. Improvement: {totalImprovement}</div>
                <div>New Score Avg.: {newScoreAverage}</div>
            </div>
        )
    }

    useEffect(() => {
        setTotalMinutes(hours * 60 + minutes)
    }, [hours, minutes])

    /**
    * Gets rounds from the logged in user database depending on user's selection. 
    * @param {string} durationType - wheter time/number of rounds/custom/tournament
    * @param {int} timeDuration - how many months back
    * @param {int} numberOfRounds - how many last rounds
    * @param {string} roundType - type of rounds to get
    */
    function getMyRounds(durationType, timeDuration, numberOfRounds, roundType) {
        setStats(null);
        setLoadningButton(true);
        if (durationType.toLowerCase() === "time") {

            fetch(`/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setStats(data);
                    setLoadningButton(false)

                })
                .catch(error => {
                    console.error(error);


                });
        } else if (durationType.toLowerCase() === "number") {
            fetch(`/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setStats(data);
                    setLoadningButton(false)

                })
                .catch(error => {
                    console.error(error);


                });
        } else if (durationType.toLowerCase() === "custom" && startDate !== null && endDate !== null) {
            fetch(`/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setStats(data);
                    setLoadningButton(false)
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "fall") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2023-08-25"}&endDate=${"2023-11-01"}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setStats(data);
                    setLoadningButton(false)
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "spring") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2023-02-10"}&endDate=${"2023-05-01"}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setStats(data);
                    setLoadningButton(false)
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "this year") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2024-01-01"}&endDate=${"2024-12-31"}&userId=${userId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setStats(data);
                    setLoadningButton(false)
                })
                .catch(error => {
                    console.error(error);

                });
        }
    }

    return (
        <div>
            <Card className="mt-2 mb-2" title="Plan your day (beta)" >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div>
                        <label htmlFor="HoursInput">Hours</label>
                        <InputNumber className="m-2" inputId="HoursInput" value={hours} min={0} onValueChange={(e) => setHours(e.value)} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                    <div>
                        <label htmlFor="MinutesInput">Minutes</label>
                        <InputNumber className="m-2" inputId="MinutesInput" value={minutes} onValueChange={(e) => setMinutes(e.value)} showButtons buttonLayout="horizontal"
                            max={59} step={15} min={0}
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                    <div>
                        <label htmlFor="noElem">Number of Elements</label>
                        <InputNumber className="m-2" inputId="noElem" value={elements} min={3} max={10} onValueChange={(e) => setElements(e.value)} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                </div>
                <label htmlFor="dropdowns">Rounds for analysis</label>
                <div id="dropdowns">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="m-2">
                            <RoundTypeDropdown className="m-2" roundTypes={roundTypes} roundType={roundType} setRoundType={setRoundType} setTournamentSelected={() => console.log()} />
                        </div>
                        <div className="m-2">
                            <DurationDropdown className="m-2" durations={durations} setNumberOfRounds={setNumberOfRounds}
                                setDurationType={setDurationType} setTimeDuration={setTimeDuration} setCustom={setCustom} />
                        </div>
                    </div>
                </div>
                <div className="card flex justify-content-center">
                    <MultiSelect
                        options={facilities}
                        value={selectedFacilities}
                        onChange={(e) => setSelectedFacilities(e.value)}
                        display="chip"
                        className="m-2"
                    />
                </div>
                <Button loading={loadingButton} className="m-2" disabled={totalMinutes <= 0} label="Plan my practice" onClick={() => getMyRounds(durationType, timeDuration, numberOfRounds, roundType)} />
            </Card >
            {
                <div>
                    <Card className="mt-2 mb-2">
                        {stats && <Message className='m-3' severity="info" text={`${stats.noOfRounds} round${stats.noOfRounds === 1 ? "" : "s"}`} />}

                        {renderStrengths()}
                        {renderAnalysis()}
                    </Card>
                    <Card className="mt-2 mb-2" title="Suggested Practice">
                        <div className="table-responsive">
                            <Table id='strengthtable' className='stats-table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Total SG</th>
                                        <th>Time (min)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {Array.from({ length: numberImprovement }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: red
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`analysis${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                    {Array.from({ length: numberStrength }).map((_, index) => (
                                        <tr style={{
                                            color: "white",
                                            backgroundColor: green
                                        }}>
                                            {practiceOptions.map((option) => (
                                                <td className={`${stats === null && 'loading-cell '} data-cell`}>{state[`strength${index + 1}${option}`]}</td>
                                            ))}
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Card></div>}
        </div >
    )

}
export { Planner }