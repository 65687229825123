import React, { useEffect, useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { AddTournament } from './AddTournament'
import { Button } from 'reactstrap'
//theme
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";

/**Displays a search bar, AddTournament and all tournaments */
function DisplayTournaments(props) {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [selectedTournament] = useState(null);

    const [tournamentData, setTournametData] = useState([]);
    const [newTournament, setNewTournament] = useState(null);
    const [deletedTournament, setDeletedTournament] = useState(null);

    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        fetch('/api/Tournament', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                data.forEach(t => {
                    t.startDate = formatDate(t.startDate);
                    t.endDate = formatDate(t.endDate);
                });
                setTournametData(data)
            })
            .catch(error => console.error(error));
    }, [newTournament, deletedTournament]);






    const header = (

        <div className="table-header" >
            Search tournaments
            <span className="p-input-icon-right" style={{ marginLeft: "0px", scale: "0.8" }}>
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>

            {/* Add tournament */}
            <span className="p-input-icon-right" style={{ marginLeft: "0px", scale: "1", position: "relative", zIndex: "2" }}>
                <AddTournament setNewTournament={setNewTournament} />
            </span>
        </div>
    );


    const deleteButton = (rowData) => {
        const handleDelete = async (e) => {
            fetch(`/api/Tournament/${rowData.id}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                })
                .then(data => {
                    setDeletedTournament(data);
                })
                .catch(error => console.error(error));
        };
        return (
            <Button color="danger" onClick={handleDelete}>
                Delete
            </Button>
        );
    };

    return (
        <div>
            <h1>Tournaments</h1>
            <p>
                <strong>Select or add a new tournament</strong>
            </p>
            <DataTable value={tournamentData} header={header} globalFilter={globalFilter} size={'small'} resizableColumns
                columnResizeMode="expand" selectionMode="single"
                dataKey="id" tableStyle={{}} selection={selectedTournament}
                onSelectionChange={(e) => console.log(e)}>

                <Column field="name" header="Tournament Name"></Column>
                <Column field="startDate" header="Start Date"></Column>
                <Column field="endDate" header="End Date"></Column>
                <Column body={(rowData) => deleteButton(rowData)} header=""></Column>
            </DataTable>
        </div>
    );
}

export { DisplayTournaments };
