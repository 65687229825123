import React, { useState, useEffect, useRef } from "react";
import {
    Table, Row, Col, Container, Card, CardText, CardTitle
} from "reactstrap";
import { useParams } from 'react-router-dom';
import { EditShot } from "./EditShot";
import { AddShot } from "./AddShot";
import { RoundTypeDropdown } from "../dropdowns/RoundTypeDropdown";
import { DateInput } from "../DateInput";
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { Accordion, AccordionTab } from 'primereact/accordion';

import { useAuth0 } from "@auth0/auth0-react";
import { Scorecard } from "../Scorecard";
const EditRound = () => {
    const { id } = useParams();
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const { user } = useAuth0();
    const [round, setRound] = useState(null);
    const [shots, setShots] = useState([]);
    const [isShotSelected, setIsShotSelected] = useState(false);
    /**false means edit, true is adding */
    const [isShotAdded, setIsShotAdded] = useState(false)
    const [shotDeleted, setShotDeleted] = useState(false);
    const [selectedShot, setSelectedShot] = useState(null);
    const [shotIndex, setShotIndex] = useState(null);
    const [prevShot, setPrevShot] = useState(null);
    const holes = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18];
    /**Was tournament/qualifying selected */
    var [tournamentSelected, setTournamentSelected] = useState(true);
    const [selectedDate, setSelectedDate] = useState("");
    const [roundType, setRoundType] = useState(null);
    const [dataLoaded, setDataLoaded] = useState(false);
    //const [tournamentName, setTounamentName] = useState(null);
    /**Possible round types */
    const roundTypes = ["Tournament", "Practice", "Qualifying"];
    const [userUnits, setUserUnits] = useState("");
    const deleteToast = useRef(null);
    const updateToast = useRef(null);
    const [initialDate, setInitialDate] = useState('');
    const [initialType, setInitialType] = useState('');
    const [activeTabs, setActiveTabs] = useState([]);

    useEffect(() => {
        setDataLoaded(false);
        setRound(null);
        fetch(`/api/Round/id?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setRound(data[0]);
                setShotDeleted(false);
                setSelectedDate(new Date(data[0].date));
                setRoundType(data[0].type);
                setInitialType(data[0].type)
                setInitialDate(new Date(data[0].date))
                setDataLoaded(true);
            })
            .catch((error) => console.log(error));

    }, [shotDeleted, isShotSelected])

    useEffect(() => {
        if (round) {
            setShots(round.shots);
        }
    }, [round])

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:myself'
                    },
                });

                const response = await fetch(`api/User/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setUserUnits(responseData.units);
                window.localStorage.setItem("units", responseData.units);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        var units = window.localStorage.getItem("units");
        if (units) {
            setUserUnits(units);
            fetchUser();
        } else {
            fetchUser();
        }

    }, [loginWithRedirect, getAccessTokenSilently]);


    const shotDetails = (shot) => {
        return (
            <Table striped>
                <thead>
                    <tr>
                        <th>

                        </th>
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th scope="row">
                            Distance
                        </th>
                        <td>
                            {shot.lie === "green" ? shot.shotDst : (userUnits === "meters" ? parseInt(shot.shotDst * 0.9144) : shot.shotDst)} {shot.lie === "green" ? "ft" : (userUnits === "meters" ? "m" : "yds")}
                        </td>
                    </tr>
                    <tr>
                        <th scope="row">
                            Lie
                        </th>
                        <td>
                            {shot.lie}
                        </td>
                    </tr>
                    {shot.club != "" && < tr >
                        <th scope="row">
                            Club
                        </th>
                        <td>
                            {shot.club}
                        </td>
                    </tr>}
                    {shot.missDst !== "" && shot.misDir !== "" && <tr>
                        <th scope="row">
                            Miss direction
                        </th>
                        <td>
                            {shot.missDir}
                        </td>
                    </tr>}
                    {shot.missDst !== "" && shot.misDir !== "" && <tr>
                        <th scope="row">
                            Miss distance
                        </th>
                        <td>
                            {shot.missDst}
                        </td>
                    </tr>}
                    {shot.mental !== "" && <tr>
                        < th scope="row">
                            Mistake
                        </th>
                        <td>
                            {shot.mental}
                        </td>
                    </tr>}
                    {
                        ((shot.parHole === 5 && shot.shotHole === 2) || (shot.parHole === 4 && shot.shotHole === 1)) && < tr >
                            <th scope="row">
                                Going for it Opportunity?
                            </th>
                            <td>
                                {shot.gfOpp ? "yes" : "no"}
                            </td>
                        </tr>
                    }
                    {
                        shot.layup != null && shot.lie !== "green" && shot.lie !== "recovery" && < tr >
                            <th scope="row">
                                Layup?
                            </th>
                            <td>
                                {shot.layup ? "yes" : "no"}
                            </td>
                        </tr>
                    }
                    <tr>
                        <th scope="row">
                            Pentalty?
                        </th>
                        <td>
                            {shot.penalty ? "yes" : "no"}
                        </td>
                    </tr>
                    {
                        shot.dificulty !== null && (shot.lie === "recovery" || shot.lie === "green" || shot.shotDst <= 50) && <tr>
                            <th scope="row">
                                Difficulty
                            </th>
                            <td>
                                {shot.difficulty}
                            </td>
                        </tr>
                    }
                    {
                        shot.pinDst !== null && <tr>
                            <th scope="row">
                                Pin Distance Location
                            </th>
                            <td>
                                {shot.pinDst}
                            </td>
                        </tr>
                    }
                    {
                        shot.pinDst !== null && <tr>
                            <th scope="row">
                                Pin Direction Location
                            </th>
                            <td>
                                {shot.pinDir}
                            </td>
                        </tr>
                    }
                </tbody >
            </Table >
        )
    }
    const showDeleteToast = () => {
        deleteToast.current.show({ severity: 'success', summary: 'Deleted', detail: 'Shot was deleted' });
    };
    function handleDelete(shot) {
        fetch(`/api/Round/delete-shot?roundId=${id}&index=${shots.indexOf(shot)}&penalty=${shot.penalty}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(shot)
        })
            .then((response) => {
                console.log(response);
                showDeleteToast();
            })
            .then(() => setShotDeleted(true))
            .catch((error) => console.log(error));

    }
    function handleEdit(shot) {
        setIsShotSelected(true);
        setIsShotAdded(false)
        setShotIndex(shots.indexOf(shot));
        setSelectedShot(shot);
    }
    function handleAdd(hole) {
        var lastShot = null;
        for (var s in shots) {
            if (shots[s].holeNo <= hole) {
                lastShot = shots[s];
            }
            else if (shots[s].holeNo > hole) {

                break;
            }
        }
        setPrevShot(lastShot);
        setIsShotSelected(true);
        setIsShotAdded(true);

    }
    const displayShot = (hole, shot) => {
        if (shot.holeNo === hole) {
            return (
                <Card body className="mt-1">
                    <Row>
                        <CardTitle tag="h5" >Shot {shot.shotHole}</CardTitle>
                    </Row>
                    <Row>
                        <CardText >{shotDetails(shot)}</CardText>
                    </Row>
                    <Row>
                        <Col sm="12" lg="6">
                            <Button label="Edit Shot" style={{ width: "100%" }} severity="secondary" onClick={() => handleEdit(shot)} />
                        </Col>
                        <Col className="mt-2 mt-lg-0" sm="12" lg="6">
                            {<Button disabled={shot.shotHole === 1} label="Delete Shot" style={{ width: "100%" }} severity="danger" onClick={() => handleDelete(shot)} />}
                        </Col>
                    </Row>
                </Card>
            )
        }
    }
    const showUpdateToast = () => {
        updateToast.current.show({
            severity: 'success', summary: 'Round Updated', detail: `Date: ${selectedDate.toLocaleDateString('gb', {
                day: '2-digit',
                month: 'short',
                year: 'numeric'
            })}, Round type: ${roundType}`
        });
    }
    function updateDetails() {
        fetch(`/api/Round/update-details?roundId=${id}&type=${roundType}&date=${selectedDate.toISOString()}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(() => {
                showUpdateToast();
                setInitialDate(selectedDate);
                setInitialType(roundType);
            })
            .then(() => console.log())
            .catch((error) => console.log(error));
    }

    useEffect(() => {
        if (selectedDate && roundType) {
            if (selectedDate.toISOString() !== initialDate.toISOString() || roundType !== initialType) {
                updateDetails();
            }
        }
    }, [selectedDate, roundType])

    function openAll() {
        if (activeTabs.length > 0) {
            setActiveTabs([])
        } else {

            setActiveTabs([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17])
        }
    }
    return (
        <div>
            <Container className="">
                <Toast ref={deleteToast} />
                <Toast ref={updateToast} />
                <Row className={dataLoaded ? "w-100 d-flex justify-content-center" : "w-100 d-flex justify-content-center loading-dots"} >
                    {!isShotSelected &&
                        <Col sm="12" md={{ offset: 1, size: 12 }} lg={{ offset: 1, size: 8 }}>
                            <Row>
                                <Col xs="6" md="3" lg="6">
                                    <RoundTypeDropdown setTournamentSelected={setTournamentSelected} roundType={roundType} setRoundType={setRoundType} roundTypes={roundTypes} />
                                </Col>
                                <Col xs="6" md="4" lg="6">
                                    <DateInput setSelectedDate={setSelectedDate} selectedDate={selectedDate} />
                                </Col>
                            </Row>
                        </Col>}

                </Row>
                <Row className={dataLoaded ? "w-100 d-flex justify-content-center mt-3" : "w-100 d-flex justify-content-center mt-3 loading-dots"}>
                    {round && <Scorecard round={round} />}
                </Row>
                <Row className="mt-3 w-100 d-flex justify-content-center" >
                    <Col sm="12" md={{ offset: 0, size: 8 }} lg={{ offset: 0, size: 6 }}>
                        {!isShotSelected ? (
                            <div>
                                <Button style={{ width: "100%" }} label="Round Stats" severity="secondary" icon="pi pi-chart-bar"
                                    onClick={() => window.open(`/round-stats/${round.id}`, "_self")} />
                                <Button label={activeTabs.length > 0 ? "Close All" : "Open All"}
                                    icon={activeTabs.length > 0 ? "pi pi-minus" : "pi pi-plus"}
                                    className="mb-4 mt-3" onClick={() => openAll()} />

                                <Accordion multiple activeIndex={activeTabs} onTabChange={(e) => setActiveTabs(e.index)}>
                                    {holes.map((hole) => (
                                        <AccordionTab key={hole} header={`Hole ${hole}`}>
                                            <p>
                                                {shots.map((s) => (
                                                    displayShot(hole, s)
                                                ))}
                                                {!dataLoaded && <div className="loading-dots"></div>}
                                                <Button severity="secondary" className="mt-2 w-100" onClick={() => handleAdd(hole)} label="Add Shot" />
                                            </p>
                                        </AccordionTab>
                                    ))}
                                </Accordion>
                            </div>

                        ) : isShotAdded ? (<AddShot roundId={id} prevShot={prevShot} setIsShotSelected={setIsShotSelected} id={id} units={userUnits} />) : (
                            <EditShot shot={selectedShot} roundId={id} index={shotIndex} setIsShotSelected={setIsShotSelected} id={id} units={userUnits} />
                        )

                        }
                    </Col>
                </Row>
            </Container >

        </div >

    );
};

export { EditRound };
