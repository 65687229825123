import React, { useEffect, useState } from "react";
import { Calendar } from 'primereact/calendar';
export function DateInput({ setSelectedDate, selectedDate }) {
    const [displayedDate, setDisplayedDate] = useState(selectedDate ? selectedDate : null)
    useEffect(() => {
        setDisplayedDate(selectedDate)
    }, [selectedDate])
    function setDate(value) {
        setSelectedDate(new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate())))
        setDisplayedDate(value)
    }
    return (

        <Calendar iconPos="left" dateFormat="dd M yy" value={displayedDate} onChange={(e) => setDate(e.value)} showIcon />

    )
}