import { React, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle } from 'reactstrap'
import { ShowCoachPages } from './ShowCoachPages';
import { ShowIndividualStats } from './ShowIndividualStats';
import { ShowTeamPractice } from './ShowTeamPractice';
import { ShowTeamRounds } from './ShowTeamRounds';
import { ShowToDateRounds } from "./ShowToDateRounds";


const ShowCoachTools = () => {
    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();
    const [userRoles, setUserRoles] = useState([]);

    useEffect(() => {
        if (isAuthenticated) {
            setUserRoles(user["https://parfectperformance.com/role"] || []);
            if (userRoles.length > 0) {
                //console.log("The user roles are " + userRoles)
            }
            //console.log("The user roles are" + userRoles) 
        }
    }, [getIdTokenClaims, isAuthenticated, userRoles]);



    return (
        <>
            {isAuthenticated && userRoles.includes("Coach") && (
                <UncontrolledDropdown nav inNavbar >
                    <DropdownToggle className="text-light" nav >
                        Coach Tools
                    </DropdownToggle>
                    <DropdownMenu className="mb-3">
                        <DropdownItem ><ShowIndividualStats /></DropdownItem>
                        <DropdownItem><ShowCoachPages /></DropdownItem>
                        <DropdownItem><ShowTeamRounds /></DropdownItem>
                        <DropdownItem><ShowTeamPractice /></DropdownItem>
                        <DropdownItem><ShowToDateRounds /></DropdownItem>
                    </DropdownMenu>
                </UncontrolledDropdown>
            )}
        </>
    );
};

export { ShowCoachTools };

