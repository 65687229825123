import React, { useState, useEffect } from "react";
import {
    Container, Row
} from "reactstrap";
import { TeamApproach } from "./TeamApproach";
import { TeamPutting } from "./TeamPutting";
import { TeamGames } from "./TeamGames";
import { useAuth0 } from "@auth0/auth0-react";
import { TabMenu } from "primereact/tabmenu"
import { TeamPlanner } from "./TeamPlanner";
import { GroupPlanner } from "./GroupPlanner";
const TeamPractice = () => {
    const [selectedPractice, setSelectedPractice] = useState("Individual Planner")
    const [players, setPlayers] = useState([]);
    const [allUserAccounts, setAllUserAccounts] = useState([]);
    const optionsMenu = [{ label: "Individual Planner", icon: "" }, { label: "Group Planner", icon: "" }, { label: "Putting Combine", icon: "" }, { label: "Putting Games", icon: "" },
    { label: "Short Game Games", icon: "" }, { label: "Approach", icon: "" }, { label: "Approach Games", icon: "" }, { label: "Tee Games", icon: "" }];
    const [activeIndex, setActiveIndex] = useState(0);

    const { loginWithRedirect, getAccessTokenSilently, isLoading } = useAuth0();
    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:users'
                    },
                });

                const response = await fetch('api/user', {
                    method: 'GET',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setPlayers(responseData);
                setAllUserAccounts(responseData);
            } catch (error) {
                if (error.error === 'login_required') {
                    loginWithRedirect();
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    //console.error(error);
                }
            }
        }

        fetchAllUsers();

    });
    return (
        <Container>
            <Row>
                <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
                    <TabMenu model={optionsMenu} activeIndex={activeIndex} onTabChange={(e) => { setSelectedPractice(e.value.label); setActiveIndex(e.index) }} />
                </div>
            </Row>
            <Row>
                {selectedPractice === "Individual Planner" && <TeamPlanner players={players} allUserAccounts={allUserAccounts} />}
                {selectedPractice === "Group Planner" && <GroupPlanner players={players} allUserAccounts={allUserAccounts} />}
                {selectedPractice === "Putting Combine" && <TeamPutting players={players} allUserAccounts={allUserAccounts} />}
                {selectedPractice === "Putting Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"puttinggames"} />}
                {selectedPractice === "Short Game Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"arggames"} />}
                {selectedPractice === "Approach" && <TeamApproach players={players} allUserAccounts={allUserAccounts} />}
                {selectedPractice === "Approach Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"approachgames"} />}
                {selectedPractice === "Tee Games" && <TeamGames players={players} allUserAccounts={allUserAccounts} games={"teegames"} />}
            </Row>
        </Container >
    )
}

export { TeamPractice }