import React, { useState, useEffect } from "react";
const GetScoresGames = ({ player, selectedGame, setLoading, games }) => {
    const [lastScore, setLastScore] = useState(null);
    const [last5Score, setLast5Score] = useState(null);
    const [last10Score, setLast10Score] = useState(null);
    const [last30Score, setLast30Score] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true)
            try {
                const id = player.userId.substring(player.userId.indexOf('|') + 1);
                const response = await fetch(`/api/${games}/user-last?userId=${id}&numberOfRounds=30&name=${selectedGame.name}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                });
                const data = await response.json();

                if (data) {
                    let lastOne = data[0].score;
                    let avg30 = data.map((round) => round.score).reduce((sum, score) => sum + score, 0) / (data.length >= 5 ? 5 : data.length);
                    let avg10 = data.slice(0, 10).map((round) => round.score).reduce((sum, score) => sum + score, 0) / (data.length >= 5 ? 5 : data.length);
                    let avg5 = data.slice(0, 5).map((round) => round.score).reduce((sum, score) => sum + score, 0) / (data.length >= 5 ? 5 : data.length);
                    setLast30Score(avg30);
                    setLast10Score(avg10);
                    setLast5Score(avg5);
                    setLastScore(lastOne);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                }
            } catch (error) {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
                setLast30Score("--");
                setLast10Score("--");
                setLast5Score("--");
                setLastScore("--");
            }
        };

        fetchData();
    }, [player, selectedGame]);



    return (
        <tr key={player.userId}>
            <td>{player.name}</td>
            <td style={{ color: selectedGame ? (lastScore < selectedGame.low ? "red" : lastScore > selectedGame.high ? "green" : "") : "" }}>{lastScore}</td>
            <td style={{ color: selectedGame ? (last5Score < selectedGame.low ? "red" : last5Score > selectedGame.high ? "green" : "") : "" }}>{last5Score}</td>
            <td style={{ color: selectedGame ? (last10Score < selectedGame.low ? "red" : last10Score > selectedGame.high ? "green" : "") : "" }}>{last10Score}</td>
            <td style={{ color: selectedGame ? (last30Score < selectedGame.low ? "red" : last30Score > selectedGame.high ? "green" : "") : "" }}>{last30Score}</td>

            {/*             <td><Button size="small" severity="secondary" label="See Details" onClick={() => selectDetails(player)} /></td>*/}
        </tr>
    );
};

export { GetScoresGames };
