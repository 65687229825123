import React, { useState } from "react";
import RoundTable from "./RoundTable";
import { PlayersDropdown } from './dropdowns/PlayersDropdown';
import { Message } from "primereact/message";
const TeamRounds = () => {
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedUserId, setSelectedUserId] = useState(null);
    const [wasRoundDeleted, setWasRoundDeleted] = useState(false);
    const [coachTeam, setCoachTeam] = useState(window.localStorage.getItem("team"))
    return (
        <div>
            {selectedUser === null && <Message className='mt-3 mb-3' severity="warn" text="No player was selected" />}
            <PlayersDropdown className='mt-3' setSelectedUserId={setSelectedUserId} selectedUser={selectedUser} setSelectedUser={setSelectedUser} coachTeam={coachTeam} />
            <RoundTable userId={selectedUserId} setWasRoundDeleted={setWasRoundDeleted} home={false} />
        </div>
    )
};

export { TeamRounds };
