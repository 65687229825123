import React, { useEffect, useState } from "react";
import { SelectButton } from "primereact/selectbutton";
const PuttingPractice = ({ setStartNewPutting, setShowPuttingResult }) => {

    const options = ["Start New", "View Results"];
    const [value, setValue] = useState("")

    useEffect(() => {
        puttingOptions()
    }, [value])
    function puttingOptions() {
        if (value === "Start New") {
            setStartNewPutting(true);
            setShowPuttingResult(false);
        } else if (value === "View Results") {
            setStartNewPutting(false);
            setShowPuttingResult(true);
        }
    }

    return (
        <div className="p-fluid mb-3" style={{ display: "flex", justifyContent: "center", width: "100%", }} >
            <SelectButton value={value} onChange={(e) => setValue(e.value)} options={options} style={{ display: "flex", justifyContent: "center", width: "80%", }} />
        </div>
    )
}

export { PuttingPractice }