import { Form, FormGroup, Input, Label } from "reactstrap";
import { useState, useEffect } from 'react';
import HeaderThing from "./HeaderThing";
import { EditableTable } from "./EditableTable";
import { FloatingThreeFieldContainer } from "./FloatingThreeFieldContainer";


/**Page where you can add a tee box to the course */
function AddTee(props) {
  /**Whether user uses yards or meters */
  const [yards, setYards] = useState(true);
  /**Changes value of yards to the switch value */
  function handleYards(event) {
    setYards(event.target.checked)
  }
  /**True when all input is filled, false otherwise */
  const [isInputFilled, setInputFilled] = useState(false);
  /**Changes value of isInputFilled */
  function handleInputFilled(isFilled) {
    setInputFilled(isFilled);
  }

  return (
    <div>
      <h1 style={{ marginBottom: "20px", textAlign: "center" }}>Add Tee</h1>

      <FloatingThreeFieldContainer teeNameRef={props.teeNameRef} onInputFilled={handleInputFilled} />
      <Form>
        <FormGroup switch>
          <Label switch>
            <Input
              type="switch"
              checked={yards}
              onClick={handleYards}
            /> <span className="ml-2">{yards ? "Yards" : "Meters"}</span></Label>
        </FormGroup>
      </Form>
      <EditableTable yards={yards} isInputFilled={isInputFilled} SelectedCourse={props.SelectedCourse} onClick1={props.onClick1} onClick2={props.onClick2} />
    </div>
  );
}
export { AddTee };