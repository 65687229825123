import React, { useEffect, useRef } from "react";
import { Table } from "reactstrap";
import '../css/loading.css'
/**Table with Tiger 5 stats from the last 10 rounds */
const HomeTiger5 = (props) => {
    /**Par 5 bogeys per round in last 10 rounds */
    const par5 = useRef(null);
    /**Double bogeys per round in last 10 rounds */
    const double = useRef(null);
    /**2 Chips per round in last 10 rounds */
    const chip = useRef(null);
    /**3 putts per round in last 10 rounds */
    const putt = useRef(null);
    /**Bogeys inside 150y in last 10 rounds */
    const bogey150 = useRef(null)



    useEffect(() => {
        if (props.noRounds) {
            var table = document.getElementById("tiger5table");
            var cells = table.getElementsByClassName("loading-cell");

            // Show the loading animation
            while (cells.length) {
                cells[0].classList.remove("loading-cell");
            }
        }
        else if (props.data) {
            fillQuickStats(props.data)
            table = document.getElementById("tiger5table");
            cells = table.getElementsByClassName("loading-cell");

            // Show the loading animation
            while (cells.length) {
                cells[0].classList.remove("loading-cell");
            }
        }

    }, [props.data, props.noRounds]);

    /**Fills out the tiger 5 table */
    function fillQuickStats(data) {
        console.log(props.baseline)
        par5.current.textContent = data.bogeyPar5PerRound;
        double.current.textContent = data.doublePerRound;
        chip.current.textContent = data.easyChipPerRound.toFixed(0) + "%";
        putt.current.textContent = data.threePuttPerRound;
        bogey150.current.textContent = data.bogey150PerRound;
    }



    return (
        <Table id="tiger5table">
            <thead>
                <tr>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            {<tbody>
                <tr>
                    <td>Bogeys on par 5</td>
                    <td className="loading-cell" ref={par5}></td>
                </tr >
                <tr>
                    <td>Doubles</td>
                    <td className="loading-cell" ref={double}></td>
                </tr>
                <tr>
                    <td>Easy u&d</td>
                    <td className="loading-cell" ref={chip}></td>
                </tr>
                <tr>
                    <td>3 putts</td>
                    <td className="loading-cell" ref={putt}></td>
                </tr>
                <tr>
                    <td>Bogeys inside {props.baseline == "LPGA" ? (props.units === "meters" ? "114m" : "125y") : (props.units === "meters" ? "137m" : "150y")}</td>
                    <td className="loading-cell" ref={bogey150}></td>
                </tr>
            </tbody >}

        </Table>
    );
};

export default HomeTiger5;
