import React, { useState } from "react";
import RoundTable from "./RoundTable";
import { Card } from "primereact/card";
import { useAuth0 } from "@auth0/auth0-react";
const MyRounds = () => {
    const [wasRoundDeleted, setWasRoundDeleted] = useState(false);
    const { user } = useAuth0();
    var [userId] = useState(user.sub.substring(user.sub.indexOf('|') + 1));
    return (
        <Card
            title="My Rounds" >
            <RoundTable userId={userId} setWasRoundDeleted={setWasRoundDeleted} home={false} />
        </Card>
    )
};

export { MyRounds };
