import React from "react";
import { useState } from 'react';

import { Form, Row, Col, FormGroup, Input, Label } from "reactstrap";

function FloatingThreeFieldContainer(props) {
  const [inputValue, setInputValue] = useState('');
  /**Changes the value of the tee box name and will send to parent 
   * component if the name is filled
   */
  function sanitizeInput(str) {
    while (str.charAt(0) === " ") {
      str = str.substring(1);
    }
    while (str.charAt(str.length - 1) === " ") {
      str = str.substring(0, str.length - 1);
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  function handleInputChange(event) {
    setInputValue(event.target.value);
    if (event.target.value !== '') {
      props.onInputFilled(true);
    } else {
      props.onInputFilled(false);
    }
  }
  return (
    <div className="f3fc">
      <Form>
        <Row style={{ textAlign: "center" }}>

          <Col md={4}>
            <FormGroup>
              <Label for="exampleTeeName">Tee Name</Label>
              <Input
                id="exampleTeeName"
                name="teeName"
                placeholder="Enter Tee Name"
                type="text"
                style={{ textAlign: "center" }}
                innerRef={props.teeNameRef}
                value={inputValue}
                onChange={handleInputChange}
              />
            </FormGroup>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
export { FloatingThreeFieldContainer };