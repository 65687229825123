import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Row,
  Table,
  UncontrolledAccordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Badge,
} from "reactstrap";




function DisplayTees(props) {

  // Tee Data
  const [teeData, setTeeData] = useState(props.course);


  // Tee Logger
  useEffect(() => {
    setTeeData(props.allTees);
  }, [teeData]);

  return (
    <div>
      <head>
        <link rel="stylesheet" href="https://unpkg.com/primeicons/primeicons.css" />
        <link rel="stylesheet" href="https://unpkg.com/primereact/resources/themes/lara-light-indigo/theme.css" />
        <link rel="stylesheet" href="https://unpkg.com/primereact/resources/primereact.min.css" />
        <link rel="stylesheet" href="https://unpkg.com/primeflex@3.2.1/primeflex.min.css" />
      </head>
      <Row className="mt-5">
        <Col>
          <h3 className="mb-3">All Tees</h3>
        </Col>
        <Col>
          <div className="d-flex justify-content-end mb-3">
            <Button className="btn-primary float-right" onClick={props.onClick}>Add New Tee +</Button>
          </div>
        </Col>
      </Row>

      {/* Dynamic accordions */}

      {teeData?.map((row, index) => (
        <UncontrolledAccordion defaultOpen={[1, 2]} stayOpen>
          <AccordionItem>
            <AccordionHeader targetId={index + 1} >{"Tee name: " + teeData[index].teeName}</AccordionHeader>
            <AccordionBody accordionId={index + 1}>

              <div className="d-flex flex-row gap-4" style={{ justifyContent: "center" }}>
                <h5>
                  Yards: <Badge color="badge-gold">{teeData[index].holeDisTotal}</Badge>
                </h5>
                <h5>
                  Par: <Badge color="badge-gold">{teeData[index].holeParTotal}</Badge>
                </h5>
                <h5>
                  Holes: <Badge color="badge-gold">18</Badge>
                </h5>
              </div>


              <Table className="mt-3" bordered responsive>
                <thead>
                  <tr>
                    <th>Hole</th>
                    <th>1</th>
                    <th>2</th>
                    <th>3</th>
                    <th>4</th>
                    <th>5</th>
                    <th>6</th>
                    <th>7</th>
                    <th>8</th>
                    <th>9</th>
                    <th>Out</th>
                    <th>10</th>
                    <th>11</th>
                    <th>12</th>
                    <th>13</th>
                    <th>14</th>
                    <th>15</th>
                    <th>16</th>
                    <th>17</th>
                    <th>18</th>
                    <th>In</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Distance</th>
                    {
                      Object.keys(teeData[index].holeDist).slice(0, 9).map((rowy, indy) => (
                        <td>
                          {teeData[index].holeDist[indy + 1]}
                        </td>
                      ))
                    }

                    <td>{teeData[index].holeDisOutTotal}</td>

                    {
                      Object.keys(teeData[index].holeDist).slice(9, 18).map((rowy, indy) => (
                        <td>
                          {teeData[index].holeDist[indy + 10]}
                        </td>
                      ))
                    }

                    <td>{teeData[index].holeDisInTotal}</td>
                    <td>{teeData[index].holeDisTotal}</td>

                    {console.log(index)}

                  </tr>
                  <tr>
                    <th scope="row">Par</th>

                    {
                      Object.keys(teeData[index].holePar).slice(0, 9).map((rowy, indy) => (
                        <td>
                          {teeData[index].holePar[indy + 1]}
                        </td>
                      ))
                    }

                    <td>{teeData[index].holeParOutTotal}</td>

                    {
                      Object.keys(teeData[index].holePar).slice(9, 18).map((rowy, indy) => (
                        <td>
                          {teeData[index].holePar[indy + 10]}
                        </td>
                      ))
                    }

                    <td>{teeData[index].holeParInTotal}</td>
                    <td>{teeData[index].holeParTotal}</td>

                  </tr>
                </tbody>
              </Table>
            </AccordionBody>
          </AccordionItem>

          {/*         <AccordionItem>
          <AccordionHeader targetId="1">
            Accordion Item 1
          </AccordionHeader>
          <AccordionBody accordionId="1">
            <strong>
              This is the first item's accordion body.
            </strong>
            You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the{' '}
            <code>
              .accordion-body
            </code>
            , though the transition does limit overflow.
          </AccordionBody>
        </AccordionItem> */}

        </UncontrolledAccordion>))}

      {/* Dynamic accordions */}


      {/* {Object.keys(teeData[1].holeDist).map((rowy, indy) => {
      console.log("h1")
    })} */}





    </div>
  );
}

export { DisplayTees };
