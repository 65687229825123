import React, { useEffect, useState } from 'react';
import '../custom.css'
import {
    Table
} from "reactstrap";
import { Button } from 'primereact/button';
import { useAuth0 } from "@auth0/auth0-react";
import { Card } from 'primereact/card';
import { DateInput } from './DateInput'
import '../css/sgHole.css'
import { RoundTypeDropdown } from './dropdowns/RoundTypeDropdown';
import RoundTable from './RoundTable';
import { MultiSelectPlayers } from './dropdowns/MultiSelectPlayers';
const ToDateRounds = () => {
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [roundType, setRoundType] = useState(null);
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]
    const [tournamentSelected, setTournamentSelected] = useState(false);
    const [allUserAccounts, setAllUserAccounts] = useState([])
    const [seeAllPlayers, setSeeAllPlayers] = useState(true);
    const [playersSelectedStats, setPlayersSelectedStats] = useState(null);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [coachTeam, setCoachTeam] = useState(window.localStorage.getItem("team"))
    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:users'
                    },
                });

                const response = await fetch('api/user', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setAllUserAccounts(responseData);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    const token = await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    const token = await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        fetchAllUsers();

    }, [loginWithRedirect, getAccessTokenSilently]);

    useEffect(() => {
        if (allUserAccounts.length > 0 && startDate && endDate && roundType) {
            const fetchPromises = [];
            allUserAccounts.forEach(function (p) {
                const fetchPromise = fetch(`/api/round/number-rounds?start=${startDate.toISOString().substring(0, 10)}&end=${endDate.toISOString().substring(0, 10)}&userId=${p.userId.substring(p.userId.indexOf('|') + 1)}&roundType=${roundType}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                    .then(response => response.json())
                    .then(data => {
                        p.roundCount = data;
                    })
                    .catch(error => console.error(error));
                fetchPromises.push(fetchPromise);
            });
            Promise.all(fetchPromises)
                .then(() => {
                    // After all fetch requests are done, you can set the modified state
                    // Assuming you're using a state hook to manage the allUserAccounts array
                    const updatedUserAccounts = [...allUserAccounts];
                    setAllUserAccounts(updatedUserAccounts);
                })
                .catch(error => console.error(error));
        }
    }, [startDate, endDate, roundType])
    return (

        <div>
            {seeAllPlayers ?
                <div>
                    <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                        <label htmlFor='startLabel'>Start Date</label>
                        <DateInput id="startLabel" setSelectedDate={setStartDate} selectedDate={startDate} />
                        <label htmlFor='endLabel'>End Date</label>
                        <DateInput id="endLabel" setSelectedDate={setEndDate} selectedDate={endDate} />
                        <RoundTypeDropdown setTournamentSelected={setTournamentSelected} setRoundType={setRoundType} roundType={roundType} roundTypes={roundTypes} />
                    </div>
                    <div className='mt-4'>
                        <MultiSelectPlayers players={allUserAccounts} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} coachTeam={coachTeam} />

                    </div>

                    <Card className='mt-4 mb-4'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Number of Rounds</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {selectedUsers.length > 0 && selectedUsers.map(p => (
                                    <tr>
                                        <td>{p.name}</td>
                                        <td>{p.roundCount}</td>
                                        <td><Button size='small' label='See Rounds' severity='secondary'
                                            onClick={() => { setSeeAllPlayers(false); setPlayersSelectedStats(p) }} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                </div>
                :
                <div>
                    <Button label='Go back' onClick={() => setSeeAllPlayers(true)} className='mb-6' />
                    <RoundTable className="mt-5" userId={playersSelectedStats.userId.substring(playersSelectedStats.userId.indexOf('|') + 1)} />
                </div>}
        </div>
    )
}

export { ToDateRounds }