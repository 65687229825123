// ConstantContext.js
import React, { createContext, useContext } from 'react';

const GamesContext = createContext();

export const GamesProvider = ({ children }) => {

    const puttingGames = [

        {
            name: "Aimpoint Spot Check",
            description: "Walk around the greed and feel side tilt in 10 different areas of the green. Call out the slope and place a level across your toes. To receive credit your guess must be +/- 0.5 of the actual break. Write down how many correct reads you had.",
            low: 8,
            high: 9,
            isLow: false//is low good
        },
        {
            name: "4 feet drill",
            description: "5 tees around the hole from 4 feet (centre hole to centre ball). 5 rounds, a total of 25 putts. Score it total putts made. A typical tour score is 20-24.",
            low: 20,
            high: 24,
            isLow: false
        },
        {
            name: "Break Putt Drill",
            description: "3 putts RL & 3 puts LR from 4' on the same hole. Change hole and do the same from 6, 8, 10'. See how many you can hole out of 24 putts. Tour average is 16.5.",
            low: 16,
            high: 17,
            isLow: false
        },
        {
            name: "Two putt ladder",
            description: "Start from 10m/32ft. Random putts all the time. Add 1m/3ft if you 2-putt, and add 2m/6ft if you hole the long putt. Game over when you 3-putt. Record the last putt you hit before 3-putting.",
            low: 15,
            high: 17,
            isLow: false
        },
        {
            name: "6-8-10 meter up/down-drill",
            description: "Two lines, one up one down. Tees on 6, 8, 10m (20, 26, 32ft) on both lines. Hit series of 3 putts: 6 up, 6 down, 8 up, 8 down, 10 up, 10 down, total = 18putts. Holed = birdie, good speed = par, short or more than 3ft pass hole = bogey. Record your score in relation to par Tour players score around par. ",
            low: 1,
            high: -1,
            isLow: true
        },
        {
            name: "2-6 meter drill (holed putts)",
            description: "Drill for mid-range putts and analysis of miss patterns. Four lines: straight upp, straight down, left-to-right, right-to-left. 15 putts on each line (6-5-4-3-2m/20-16-13-10-7ft), a total of 60 putts. Tour average is 26.1 holed putts. Also note the number of putts that that reached the front edge of the hole, and not went more than 3 feet behind the hole and record in speed version of the drill.",
            low: 25,
            high: 27,
            isLow: false
        },
        {
            name: "2-6 meter drill (speed)",
            description: "Write down the number of putts from 2-6 meter drill that reached the front edge of the hole, and not went more than 3 feet behind the hole. Tour average is 54.2",
            low: 54,
            high: 55,
            isLow: false
        },
        {
            name: "4-5-6 feet 3-4-5 meter-drill",
            description: "Hole 6 putts with as few trials as possible. 4-5-6 feet and 3-4-5 meter (10-13-16 feet). Start on shortest putt, and finish with holing 5 meters. If you miss one of the 'feet-putts' you move one putt back, if you miss one of the 'meter-putts' hit the same putt again. Score is number of putts needed to complete the drill. Tour players need 10-14 putts.",
            low: 14,
            high: 10,
            isLow: true
        },
        {
            name: "Star ladder",
            description: "Set up tees at 3' at South, 4' at East, 5' at North, 6' at West, 7' at SE, 8' at NE, 9' at NW, 10' at SW. Start at 3ft and move counter clockwise to end on a 10ft putt. Hole = move to next putt, miss = move back (no futher than 3'). Record total number of putts.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "Circuit",
            description: "3 lines on the same hole, lines 1 and 2 have safezones: 1: 12-30ft every 3ft (7 putts) safezone 2ft semi circle past the hole , 2: 35-50fr every 5ft (4 putts) safezone 2ft circle around the hole, 3: 3-9ft every 1ft (7 putts). Lines 1 and 2: hole=move on + life*, safe sone = move on, outside safe zone = move back. Line 3 hole = move on, miss = move back. *life = avoid moving back or -1 off final score. Record numer of putts needed to finish the game. ",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "Safety Drawback",
            description: "18 holes of random putts. 6x10-15ft, 6x12-25ft, 3x25-40ft, 3x 40ft+. Safe zones: Inside 25ft 2ft semicircle past the front edge of the hole. 25-40' 2ft circle, 40'+ 3ft circle. If the putt is in the safezone hole out as normal, if outside pull back 1 putter length and continue until the ball is in the hole. Each hole is par 2. Record score to par.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "4-7 feet drill",
            description: "All random putts. 4 Challenges: 1. 3x4' in a row, 2. 3x5' in a row, 3. 2x 6' in a row, 4. 2x7' in a row. Score is total number of putts needed to complete the drill. Tour players need 16-20 putts",
            low: 20,
            high: 16,
            isLow: true
        },
        {
            name: "4-5-6m offence",
            description: "Alternate random putts from 4-5-6-4-5-6...m (13-16.5-20ft). Drill ends when you get 15 points. Hole = 3p, missed inside 3ft pass hole = 1p, more than 3ft pass = 0p, short or 3-putt=-3p. Record total number of putts needed to complete the drill.",
            low: 13,
            high: 11,
            isLow: true
        },
        {
            name: "12 to 6 feet ball start",
            description: "Put 2 tees/lines one at 12' and one at 6'. Using Peltz Tutor hit the first ball as close as possible to 12ft, hit the next one shorter (leapfrog). Game ends when you strike a marble on peltz tutor, hit another golf ball or roll it past the previous one. Record number of balls that finished between 12 and 6 feet.",
            low: 0,
            high: 8,
            isLow: false
        },
        {
            name: "King of ball start",
            description: "You will hit a total of 36 putts to a phantom hole with a Peltz Tutor. 12 putts from 6ft, 12 from 9ft and 12 from 12ft. Half of them from each distance will be with eyes closed. To get a point you have to hit it in between the marbles and the ball has to stop past the hole but no more than 3ft.",
            low: 30,
            high: 34,
            isLow: false
        },
        {
            name: "Technique circle",
            description: "You will setup 8 putts from 6ft around a hole on a small break. You will complete 4 rounds for a total of 32 putts. For each round you will hit 5 putts, 2 putts with Peltz Tutor (L-R & R-L), 1 putter aim check (check if putter is ligned up correctly, e.g. middle of the hole for a straight putt).",
            low: 27,
            high: 30,
            isLow: false
        },
        {
            name: "Hell drill",
            description: "You will setup 5 coins at 6 feet and 5 tees at 8 feet. You will always putt from where the coin is. When you make the putt you move the coin 1ft back. If you miss a putt you move the coin 1ft forward. The game ends when all the coins are at 8ft or more. As the score put down the time it took you to finish the drill.",
            low: 0,
            high: 100,
            isLow: false
        },
        {
            name: "Darts",
            description: "You will putt from 4 directions: soft L-R downhill, soft R-L downhill, soft L-R uphill, soft R-L uphill. Set up tees at 4, 5, 6, 7, 8 and 9ft from each direction. You will start from all 4' putts, then all 5' and so on. Write down how many putts you made. Good score is 18.",
            low: 17,
            high: 18,
            isLow: false
        },
        {
            name: "Zach Johnson",
            description: "You will set up putts from 3, 6 and 9ft to 6 different holes. Play from each location until holed out. Good score is 23 or better",
            low: 23,
            high: 22,
            isLow: true
        },
        {
            name: "McCabe Drill",
            description: "Set up 8 putts from 5'. Once you make 3 straight putts from one place you move to the next one. If you miss a putt you move back a station. Write down the time it took you to finish the drill.",
            low: 0,
            high: 100,
            isLow: false
        },
        {
            name: "Leapfrog",
            description: "Place 2 tees 10’ apart. Then walk from the nearest tee 20’ away. Putt first ball just past first tee. Then putt next ball past the previous ball putted. Then putt the next ball just past the second ball. Continue this until you get 8 balls in between the tees. You start over if you hit a ball or don’t get a ball past the previous ball that you hit. Goal is to get all 8 balls in between the tees. Write down how many times it took you.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "Lag putt footage",
            description: "Place tees at 30', 40', 50', 60' and 70'. Add up the total distanca of each putt from the hole. The goal is to get 18' or less.",
            low: 18,
            high: 15,
            isLow: true
        },
        {
            name: "2-5m Green Reading Drill",
            description: "Use a Pelz Tutor. 4 random putts, distance 2, 3, 4 & 5m. One putt at a time has to be hit until you hole it without touching the marbles (good read and ball start). Score = total number of putts to hole all four situations. Tour average is 11.6 putts.",
            low: 11,
            high: 12,
            isLow: true
        },
        {
            name: "10 Hole Putting Benchmark Game",
            description: "You will play 10 holes on the putting green. Pick a new starting location and different hole each time. After you hit your first putt, continue putting until you hole out. These are the starting distances for each putt: 20', 35', 25', 40', 30', 20', 35', 25', 40', 30'. Tour average is 19.86 putts",
            low: 20,
            high: 19,
            isLow: true
        },
        {
            name: "Aiming Challenge",
            description: "You will need a phantom hole, peltz putting tutor or triangle alingment tool, a string, golf ball and a partner. On the phantom hole draw another circle or mark points that create a hole that is 2\" in diameter. Set a tee 10' from the phantom hole. Now set up to the putt assuming it's perfectly straight. After you set up to the ball your partner will take away the golf ball and put the putting tutor along the putter face. Once that is done you will take the string and draw a line with it starting where the ball would be and going towards where the tutor is aiming towards the hole. If the string is inside the small hole you get 2 points, if it's inside the regular hole it's 1 point. Otherwise is's 0 points. Repeat this 10 times.",
            low: 12,
            high: 16,
            isLow: false//is low good
        },
        {
            name: "Aiming Challenge (slopes)",
            description: "You will need a phantom hole, peltz putting tutor or triangle alingment tool, a string, golf ball and a partner. On the phantom hole draw another circle or mark points that create a hole that is 2\" in diameter. Place the hole on a break. Set one tee 10' from the phantom hole on a straight putt, one on L-R putt and one on R-L putt. You will always set up to the middle of the hole. After you set up to the ball your partner will take away the golf ball and put the putting tutor along the putter face. Once that is done you will take the string and draw a line with it starting where the ball would be and going towards where the tutor is aiming towards the hole. If the string is inside the small hole you get 2 points, if it's inside the regular hole it's 1 point. Otherwise is's 0 points. Repeat this 3 times for the total of 9 'putts'.",
            low: 10,
            high: 14,
            isLow: false//is low good
        },
        {
            name: "AimPoint Calibration",
            description: "Find in order a 0%, 1%, 1.5%, 2%, 2.5%, 3%, 3.5%, 4%, 5% slopes. Write how many tries it took you to find all of them. A sucessful find has to be within +/- 0.5%.",
            low: 16,
            high: 14,
            isLow: true//is low good
        },


    ]
    const argGames = [
        {
            name: "21",
            description: "Play 9 holes (3 easy, 3 medium, 3 hard. Grass only) no longer than 30m or yards. Always drop the ball. Putt out every hole. Write down your score. Good score is 21 or better.",
            low: 21,
            high: 22,
            isLow: true
        },
        {
            name: "Knockout",
            description: "Choose a pin around 15m from the edge of the green. Create 6 zones (5ft long) in one line. Choose a place to hit from and grab some balls. The Goal is to eliminaate all zones (front to back or back to front). Zone is eliminated when the ball lands in the zone and finishes 6ft or less from the hole. Score is the number of shots needed to eliinate all zones.",
            low: 0,
            high: 100
        },
        {
            name: "Darts",
            description: "Play 9 holes (3 easy, 3 medium, 3 hard. Grass only) inside 30m. Always drop the ball. No need to putt out. Hole = 5p. Inside 3ft=3p, 6ft=2p, 9ft=1p. Outside 9ft=0p. Write down your score.",
            low: 0,
            high: 100
        },
        {
            name: "Chipping leapfrog",
            description: "Pick a place on the green where you have around 20m of room and mark the edge of the green and a spot 20m from it. Drop some balls 10m from the edge of the green. Hit first ball as close as possible to the edge of the green. Hit the second one between the first ball and the marker and so on. Score is the number of balls in the 20m zone. Drill ends when you hit a ball too short or run out of room.",
            low: 0,
            high: 100
        },
        {
            name: "Elimination",
            description: "Pick a hole on the green. In the bunker find 6 spots: Uphill, Downhill, Ball above-, Ball below-the feet, plugged, normal. To eliminate the spot, ball has to finish 6ft or less from the hole. You hit until the spot is eliminated and then you move on. Score is the number of shots needed to eliminate all the spots.",
            low: 0,
            high: 100
        },
        {
            name: "Bunker Darts",
            description: "Play 9 holes (3 easy, 3 medium, 3 hard. Bunker only) inside 30m. Always roll the ball. No need to putt out. Hole = 5p. Inside 3ft=3p, 6ft=2p, 9ft=1p. Outside 9ft=0p. Write down your score.",
            low: 0,
            high: 100
        },
        {
            name: "774 footage",
            description: "Pick 7 easy, 7 medium and 4 hard locations (grass and bunker). Record the distance in feet from the hole on each shot. Add up all proximities and divide by 18 to get the average. Record the average as the score of the game.",
            low: 0,
            high: 100
        },
        {
            name: "Chipping ladder",
            description: "Hit chips through the agility ladder. How many shots does it take you to get through the ladder in a row?",
            low: 0,
            high: 100
        },

    ]

    const appGames = [
        {
            name: "7 Circle Wedge Drill",
            description: "Take your favorite wedge. How many shots does it take you to hit it through every circle?",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "7 Circle Wedge Drill (hard)",
            description: "Take your favorite wedge. If you miss, start over. How many shots does it take you to hit it through every circle in a row?",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "7 Circle Trajectory",
            description: "Take your 8 iron. How many shots does it take you to hit a ball through each circle? Then take your 5 iron and do the same thing.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "7 Circle Trajectory (hard)",
            description: "Take your 8 iron. How many shots does it take you to hit a ball through each circle in a row? Then take your 5 iron and do the same thing.",
            low: 0,
            high: 18,
            isLow: true
        },
        {
            name: "Side Danger 50-100",
            description: "You will need a target at 50, 60, 70, 80, 90 and 100 yds. Launch monitor is suggested but not required. You will hit one ball to a target at a time and alternate the \"dangerouns\" side and repeat it for a total of 24 shots. If you miss the target on the dangerous side you get -5pts. If you miss target on the safe side by less than 5% (one finger) you get 3pts, less than 10% (2 fingers) 1pt, more than 10% 0pts.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "Side Danger 100-150",
            description: "You will need a target at 100, 110, 120, 130, 140, 150 yds. Launch monitor is suggested but not required. You will hit one ball to a target at a time and alternate the \"dangerouns\" side and repeat it for a total of 24 shots. If you miss the target on the dangerous side you get -5pts. If you miss target on the safe side by less than 5% (one finger) you get 3pts, less than 10% (2 fingers) 1pt, more than 10% 0pts.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "Side Danger 150-200",
            description: "You will need a target at 150, 160, 170, 180, 190 and 200 yds. Launch monitor is suggested but not required. You will hit one ball to a target at a time and alternate the \"dangerouns\" side and repeat it for a total of 24 shots. If you miss the target on the dangerous side you get -5pts. If you miss target on the safe side by less than 5% (one finger) you get 3pts, less than 10% (2 fingers) 1pt, more than 10% 0pts.",
            low: 0,
            high: 100,
            isLow: true
        },
        {
            name: "Side Danger 200+",
            description: "You will need a target at 200, 210, 220, 230, 240, 250 yds. Launch monitor is suggested but not required. You will hit one ball to a target at a time and alternate the \"dangerouns\" side and repeat it for a total of 24 shots. If you miss the target on the dangerous side you get -5pts. If you miss target on the safe side by less than 5% (one finger) you get 3pts, less than 10% (2 fingers) 1pt, more than 10% 0pts.",
            low: 0,
            high: 100,
            isLow: true
        },
    ]

    const teeGames = [
        {
            name: "Tee Shot Points",
            description: "You will hit 14 balls with your driver.  Pick a different target for each shot. Create 2 zones with the middle being your target: 25y wide=2p, 45y wide=1p. On shot 1 there are no penalties. On shot 2 if you miss the 45y zone to the right=-1p. On shot 3 if you miss the 45y zone to the left=-1p. On shot 4if you miss the 45y zone on either side=-1p and so on... Shot 13 no penalties. Shot 14 penalties on both sides. Write down your score.",
            low: 10,
            high: 20,
            isLow: false
        },
        {
            name: "Driver Impact Control",
            description: "You will need 10 balls and spray/face sticker/GC Quad to track impact location. Draw a cross on your driver face ccreating 4 zones: high toe, high heel, low toe, low heel. Hit each zone and record if you were succesfull. On 5th shot hit the middle. Repeat for a total number of 10 shots. Write down the number of successes.",
            low: 10,
            high: 20,
            isLow: false
        }
    ]

    const combineGames = [
        {
            name: "Putting Combine",
        },
        {
            name: "50-100y Combine",
        },
        {
            name: "75-125y Combine",
        },
        {
            name: "100-150y Combine",
        },
        {
            name: "150-200y Combine",
        }

    ]

    return (
        <GamesContext.Provider value={{ puttingGames, argGames, appGames, teeGames, combineGames }} >
            {children}
        </GamesContext.Provider>
    );
};

export const useGames = () => {
    return useContext(GamesContext);
};
