import React, { useState, useEffect } from "react";
import {
    Container, Row, Col, Table
} from "reactstrap";
import { Button } from 'primereact/button';
import { InputNumber } from "primereact/inputnumber";

const EditApproach = ({ id, setEdit }) => {

    const rows = Array.from({ length: 18 }, (_, i) => i + 1);

    const [distance, setDistance] = useState([]);
    const [missDir, setMissDir] = useState(Array(20).fill(0));
    const [missDst, setMissDst] = useState(Array(20).fill(0));



    const updateDistanceArray = (index, value) => {
        const newArray = [...missDst];
        newArray[index - 1] = Number(value);
        setMissDst(newArray);
    };
    const updateDirectionArray = (index, value) => {
        const newArray = [...missDir];
        newArray[index - 1] = Number(value);
        setMissDir(newArray);
    };



    function submitApproach() {
        fetch(`/api/approachpractice/id?id=${id}`, {
            method: 'PATCH',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ missDir: missDir, missDst: missDst })
        })
            .then(response => response)
            .then(data => {
                setEdit(false);
            })
            .catch(error => console.error(error));
    }

    useEffect(() => {
        getApproach();
    }, [])

    function getApproach() {
        fetch(`/api/approachpractice/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                setMissDir(data.missDir);
                setMissDst(data.missDst);
                setDistance(data.distances)

            })
            .catch(error => console.error(error));
    }

    return (
        <Container >
            <Row className="table-container mt-3 mb-4">
                <Col md="12" lg="12">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Hole</th>
                                <th>Distance (yds)</th>
                                <th>Miss direction (L/R)</th>
                                <th>Miss distance (S/L)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row) => (
                                <tr key={row}>
                                    <td>
                                        {row}
                                    </td>
                                    <td>
                                        {distance[row - 1]}
                                    </td>
                                    <td>
                                        <InputNumber
                                            onValueChange={(e) => updateDirectionArray(row, e.target.value)}
                                            value={missDir[row - 1]}
                                            showButtons buttonLayout="vertical"
                                            className="p-fluid"
                                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                            maxLength={2}
                                            min={0}
                                        />
                                    </td>
                                    <td>
                                        <InputNumber
                                            onValueChange={(e) => updateDistanceArray(row, e.target.value)}
                                            value={missDst[row - 1]}
                                            showButtons buttonLayout="vertical"
                                            className="p-fluid"
                                            incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus"
                                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success"
                                            maxLength={2}
                                            min={0}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row>
                <Col style={{ display: "flex", justifyContent: "center" }}>
                    <span className="p-buttonset mb-5" style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                        <Button className="m-1" label="Cancel" severity="secondary" onClick={() => { setEdit(false) }} style={{ width: "45%" }} />
                        <Button className="m-1" label="Submit" icon="pi pi-check" iconPos="right" severity="success" onClick={() => { submitApproach() }} style={{ width: "45%" }} />

                    </span>
                </Col>
            </Row>
        </Container >
    )
}

export { EditApproach }