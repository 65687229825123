import { Card } from "primereact/card";
import { InputNumber } from "primereact/inputnumber";
import { useEffect, useState } from "react";
import { RoundTypeDropdown } from "../dropdowns/RoundTypeDropdown";
import { DurationDropdown } from "../dropdowns/DurationDropdown";
import { Button } from "primereact/button";
import { Message } from 'primereact/message'
import { DataTable } from "primereact/datatable";
import { MultiSelectPlayers } from "../dropdowns/MultiSelectPlayers";
import { Column } from "primereact/column";

import { MultiSelectGames } from "../dropdowns/MultiSelectGames";
import { useGames } from "../../GamesProvider";
import { useAuth0 } from "@auth0/auth0-react";
const TeamPlanner = (props) => {
    const { user, loginWithRedirect, getAccessTokenSilently } = useAuth0();

    const [selectedUsers, setSelectedUsers] = useState([]);
    const [displayedPlayers, setDisplayedPlayers] = useState([]);
    const [allUserAccounts] = useState(props.allUserAccounts);
    useEffect(() => {
        if (selectedUsers.length > 0) {
            setDisplayedPlayers(selectedUsers);
        } else {
            setDisplayedPlayers(allUserAccounts)
        }
    }, [selectedUsers, allUserAccounts])
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [totalMinutes, setTotalMinutes] = useState(0);
    const [elements, setElements] = useState(1);
    const [roundType, setRoundType] = useState("All");
    const [numberOfRounds, setNumberOfRounds] = useState(10);
    const [timeDuration, setTimeDuration] = useState(0);
    const [durationType, setDurationType] = useState("number");
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [custom, setCustom] = useState(false);
    const [stats, setStats] = useState(null);
    const roundTypes = ["All", "Tournament", "Qualifying", "Practice"]
    const durations = ["Last Round", "Last 3 Rounds", "Last 4 Rounds", "Last 10 Rounds", "Last 15 Rounds",
        "Last 20 Rounds", "Last 30 Rounds", "Last Month", "Last 3 Months", "Last 6 Months", "Last 12 Months", "Fall", "Spring", "Custom"];
    const green = "rgba(0, 128, 0, 0.75)";
    const red = "rgba(220, 0, 0, 0.75)"
    const [numberImprovement, setNumberImprovement] = useState(2);
    const [numberStrength, setNumberStrength] = useState(1);
    const [units, setUnits] = useState("yards")

    const [loadingButton, setLoadningButton] = useState(false)

    const [dataState, setDataState] = useState([]);

    const gamesConst = useGames();
    const [selectedGames, setSelectedGames] = useState({});

    const [coachTeam, setCoachTeam] = useState(null)
    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:myself'
                    },
                });

                const response = await fetch(`api/User/myself?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                console.log(response.json())
                const responseData = await response.json();
                window.localStorage.setItem("team", responseData.team);
                if (responseData.bag !== null) {
                    window.localStorage.setItem("bag", JSON.stringify(responseData.bag));
                }
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        var team = window.localStorage.getItem("team");
        if (team) {
            setCoachTeam(team);
        } else {
            fetchUser();
        }
    }, [])



    useEffect(() => {
        switch (elements) {
            case 3:
                setNumberImprovement(2);
                setNumberStrength(1);
                break;
            case 4:
                setNumberImprovement(3);
                setNumberStrength(1);
                break;
            case 5:
                setNumberImprovement(3);
                setNumberStrength(2);
                break;
            case 6:
                setNumberImprovement(4);
                setNumberStrength(2);
                break;
            case 7:
                setNumberImprovement(5);
                setNumberStrength(2);
                break;
            case 8:
                setNumberImprovement(5);
                setNumberStrength(3);
                break;
            case 9:
                setNumberImprovement(6);
                setNumberStrength(3);
                break;
            case 10:
                setNumberImprovement(7);
                setNumberStrength(3);
                break;
        }
    }, [elements])

    function fillAnalysis(data) {
        // Define the variables and their values
        var variables = [
            { name: "PUTT 3'", value: data.sgPutting3, attempts: data.attempts3, perShot: data.sgPutting3 / data.attempts3, improvement: (data.sgPutting3 / data.attempts3) * (data.attempts3 / data.noOfRounds), time: 0 },
            { name: "PUTT 3-5'", value: data.sgPutting35, attempts: data.attempts35, perShot: data.sgPutting35 / data.attempts35, improvement: (data.sgPutting35 / data.attempts35) * (data.attempts35 / data.noOfRounds), time: 0 },
            { name: "PUTT 5-10'", value: data.sgPutting510, attempts: data.attempts510, perShot: data.sgPutting510 / data.attempts510, improvement: (data.sgPutting510 / data.attempts510) * (data.attempts510 / data.noOfRounds), time: 0 },
            { name: "PUTT 10-20'", value: data.sgPutting1020, attempts: data.attempts1020, perShot: data.sgPutting1020 / data.attempts1020, improvement: (data.sgPutting1020 / data.attempts1020) * (data.attempts1020 / data.noOfRounds), time: 0 },
            { name: "Lag Putting 20'+", value: data.sgPuttingLagOver20, attempts: data.attempts20, perShot: data.sgPuttingLagOver20 / data.attempts20, improvement: (data.sgPuttingLagOver20 / data.attempts20) * (data.attempts20 / data.noOfRounds), time: 0 },
            { name: `ARG ${units === "meters" ? "0-27m" : "0-30y"}`, value: data.sgARG030 * data.sgARG030Attempts, attempts: data.sgARG030Attempts, perShot: data.sgARG030, improvement: (data.sgARG030) * (data.sgARG030Attempts / data.noOfRounds), time: 0 },
            { name: `ARG ${units === "meters" ? "27-46m" : "30-50y"}`, value: data.sgARG3050 * data.sgARG3050Attempts, attempts: data.sgARG3050Attempts, perShot: data.sgARG3050, improvement: (data.sgARG3050) * (data.sgARG3050Attempts / data.noOfRounds), time: 0 },
           /*  { name: 'ARG Sand', value: data.sgARGSand * data.sgARGSandAttempts, attempts: data.sgARGSandAttempts, perShot: data.sgARGSand, improvement: (data.sgARGSand) * (data.sgARGSandAttempts / data.noOfRounds), time: 0 },
            { name: 'ARG Fringe', value: data.sgARGFringe * data.sgARGFringeAttempts, attempts: data.sgARGFringeAttempts, perShot: data.sgARGFringe, improvement: (data.sgARGFringe) * (data.sgARGFringeAttempts / data.noOfRounds), time: 0 },
            */ { name: `APP ${units === "meters" ? "46-91m" : "50-100y"}`, value: data.sg50100 * data.sg50100Attempts, attempts: data.sg50100Attempts, perShot: data.sg50100, improvement: (data.sg50100) * (data.sg50100Attempts / data.noOfRounds), time: 0 },
            { name: `APP ${units === "meters" ? "91-137m" : "100-150y"}`, value: data.sg100150 * data.sg100150Attempts, attempts: data.sg100150Attempts, perShot: data.sg100150, improvement: (data.sg100150) * (data.sg100150Attempts / data.noOfRounds), time: 0 },
            { name: `APP ${units === "meters" ? "137-183m" : "150-200y"}`, value: data.sg150200 * data.sg150200Attempts, attempts: data.sg150200Attempts, perShot: data.sg150200, improvement: (data.sg150200) * (data.sg150200Attempts / data.noOfRounds), time: 0 },
            { name: `APP ${units === "meters" ? "183+m" : "200+y"}`, value: data.sg200 * data.sg200Attempts, attempts: data.sg200Attempts, perShot: data.sg200, improvement: (data.sg200) * (data.sg200Attempts / data.noOfRounds), time: 0 },
            { name: 'Tee Shots', value: data.sgOTTTotal, attempts: data.teeAttempts, perShot: data.sgOTTTotal / data.teeAttempts, improvement: (data.sgOTTTotal / data.teeAttempts) * (data.teeAttempts / data.noOfRounds), time: 0 }
        ];

        // Sort the variables based on their values in ascending order
        variables.sort(function (a, b) {
            return a.value - b.value;
        });

        return calculateTime(variables);
    }

    function calculateTime(variables) {
        var weaknesses = variables.slice(0, numberImprovement);
        var strengths = variables.slice(14 - numberStrength, 14);
        var totalLost = 0
        var totalGain = 0
        weaknesses.forEach(function (val, i) {
            totalLost += Math.abs(val.value)
        })
        strengths.forEach(function (val, i) {
            totalGain += Math.pow(val.value, 1 / 3)
        })
        var mult = numberImprovement / (numberImprovement + numberStrength) * 1.2;
        var timeStrength = (totalMinutes * (1 - mult)) / (Math.abs(totalGain));
        var timeWeak = (totalMinutes * mult) / (Math.abs(totalLost));
        weaknesses.forEach((val, i) => {
            var t = timeWeak * Math.abs(weaknesses[i].value);
            val.time = parseFloat(t).toFixed(0);
        })
        strengths.forEach((val, i) => {
            var t = timeStrength * Math.pow(Math.abs(strengths[numberStrength - 1 - i].value), 1 / 3);
            val.time = parseFloat(t).toFixed(0);
        })
        return weaknesses.concat(strengths);

    }



    useEffect(() => {
        setTotalMinutes(hours * 60 + minutes)
    }, [hours, minutes])

    /**
   * Gets rounds from selected user's database depending on user's selection.
   * @param {string} durationType - wheter time/number of rounds/custom/tournament
   * @param {int} timeDuration - how many months back
   * @param {int} numberOfRounds - how many last rounds
   * @param {string} roundType - type of rounds to get
   */
    function getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType, selectedUserId) {

        if (durationType.toLowerCase() === "time") {
            fetch(`/api/Calculation/user-last-duration?timeType=month&duration=${timeDuration}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setDataState((prev) => [
                        ...prev,
                        { userId: selectedUserId, stats: data }
                    ])
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "number") {
            fetch(`/api/Calculation/user-last?numberOfRounds=${numberOfRounds}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setDataState((prev) => [
                        ...prev,
                        { userId: selectedUserId, stats: fillAnalysis(data), dataLoaded: true }
                    ])
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "custom" && startDate !== null && endDate !== null) {
            fetch(`/api/Calculation/user-date-range?startDate=${startDate.toISOString().substring(0, 10)}&endDate=${endDate.toISOString().substring(0, 10)}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setDataState((prev) => [
                        ...prev,
                        { userId: selectedUserId, stats: data }
                    ])
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "fall") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2023-08-25"}&endDate=${"2023-10-29"}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setDataState((prev) => [
                        ...prev,
                        { userId: selectedUserId, stats: data }
                    ])
                })
                .catch(error => {
                    console.error(error);
                });
        } else if (durationType.toLowerCase() === "spring") {
            fetch(`/api/Calculation/user-date-range?startDate=${"2023-02-10"}&endDate=${"2023-05-01"}&userId=${selectedUserId}&roundType=${roundType}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    setDataState((prev) => [
                        ...prev,
                        { userId: selectedUserId, stats: data }
                    ])
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    function sendEmail(name, userId, email) {
        var subject = `Practice ${name}`
        var time = dataState.filter((d) => d.userId === userId.substring(userId.indexOf('|') + 1))
            .map((d) => d.stats)[0].map((s) => `${s.name}: ${s.time}min%0D%0A`).join('');
        var puttingGames = selectedGames[userId].puttingGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var argGames = selectedGames[userId].argGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var appGames = selectedGames[userId].appGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var teeGames = selectedGames[userId].teeGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";
        var combineGames = selectedGames[userId].combineGames?.map((g) => ` ${g.name}%0D%0A`).join('') ?? "none%0D%0A";

        var body = `Time:%0D%0A${time}%0D%0A Putting games:%0D%0A${puttingGames}%0D%0A Arg games:%0D%0A${argGames}%0D%0A Approach games:%0D%0A${appGames}%0D%0A Tee games:%0D%0A${teeGames}%0D%0A Combines:%0D%0A${combineGames}`
        window.open(`mailto:${email}?subject=${subject} &body=${body}`, '_blank')
    }

    return (
        <div>
            <Card className="mt-2 mb-2" title="Plan team's day (beta)" >
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <div>
                        <label htmlFor="HoursInput">Hours</label>
                        <InputNumber className="m-2" inputId="HoursInput" value={hours} min={0} onValueChange={(e) => setHours(e.value)} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                    <div>
                        <label htmlFor="MinutesInput">Minutes</label>
                        <InputNumber className="m-2" inputId="MinutesInput" value={minutes} onValueChange={(e) => setMinutes(e.value)} showButtons buttonLayout="horizontal"
                            max={59} step={15} min={0}
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                    <div>
                        <label htmlFor="noElem">Number of Elements</label>
                        <InputNumber className="m-2" inputId="noElem" value={elements} min={3} max={10} onValueChange={(e) => setElements(e.value)} showButtons buttonLayout="horizontal"
                            decrementButtonClassName="p-button-danger" incrementButtonClassName="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" />
                    </div>
                </div>
                <label htmlFor="dropdowns">Rounds for analysis</label>
                <div id="dropdowns">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        <div className="m-2">
                            <RoundTypeDropdown className="m-2" roundTypes={roundTypes} roundType={roundType} setRoundType={setRoundType} setTournamentSelected={() => console.log()} />
                        </div>
                        <div className="m-2">
                            <DurationDropdown className="m-2" durations={durations} setNumberOfRounds={setNumberOfRounds}
                                setDurationType={setDurationType} setTimeDuration={setTimeDuration} setCustom={setCustom} />
                        </div>
                    </div>
                </div>
                <div>
                    <MultiSelectPlayers players={props.players} selectedUsers={selectedUsers} setSelectedUsers={setSelectedUsers} durations={durations} coachTeam={coachTeam} />

                </div>
                <Button loading={loadingButton} className="m-2" disabled={totalMinutes <= 0 || selectedUsers.length <= 0} label="Plan my practice"
                    onClick={() => {
                        setDataState([])
                        selectedUsers.map((u) => {
                            getSelectedRounds(durationType, timeDuration, numberOfRounds, roundType, u.userId.substring(u.userId.indexOf('|') + 1))
                        })
                    }}
                />
            </Card >
            {
                <div>
                    <Card className="mt-2 mb-2" title="Suggested Practice">

                        {selectedUsers.map((u) => {
                            return (
                                <Card className="m-2" title={u.name}>

                                    <DataTable value={dataState.filter((d) => d.userId === u.userId.substring(u.userId.indexOf('|') + 1))
                                        .map((d) => d.stats)[0]}>
                                        <Column field="name" header="Name"></Column>
                                        <Column field="value" header="Total SG"></Column>
                                        <Column field="time" header="Time"></Column>
                                    </DataTable>
                                    <MultiSelectGames games={gamesConst.puttingGames} selectedGames={selectedGames[u.userId]?.puttingGames || []}
                                        setSelectedGames={(games) => {
                                            setSelectedGames((prevSelectedGames) => ({
                                                ...prevSelectedGames,
                                                [u.userId]: {
                                                    ...prevSelectedGames[u.userId],
                                                    puttingGames: games,
                                                },
                                            }));
                                        }} part="Putting" />
                                    <MultiSelectGames games={gamesConst.argGames} selectedGames={selectedGames[u.userId]?.argGames || []}
                                        setSelectedGames={(games) => {
                                            setSelectedGames((prevSelectedGames) => ({
                                                ...prevSelectedGames,
                                                [u.userId]: {
                                                    ...prevSelectedGames[u.userId],
                                                    argGames: games,
                                                },
                                            }));
                                        }} part="Arg" />
                                    <MultiSelectGames games={gamesConst.appGames} selectedGames={selectedGames[u.userId]?.appGames || []}
                                        setSelectedGames={(games) => {
                                            setSelectedGames((prevSelectedGames) => ({
                                                ...prevSelectedGames,
                                                [u.userId]: {
                                                    ...prevSelectedGames[u.userId],
                                                    appGames: games,
                                                },
                                            }));
                                        }} part="Approach" />
                                    <MultiSelectGames games={gamesConst.teeGames} selectedGames={selectedGames[u.userId]?.teeGames || []}
                                        setSelectedGames={(games) => {
                                            setSelectedGames((prevSelectedGames) => ({
                                                ...prevSelectedGames,
                                                [u.userId]: {
                                                    ...prevSelectedGames[u.userId],
                                                    teeGames: games,
                                                },
                                            }));
                                        }} part="Tee" />
                                    <MultiSelectGames games={gamesConst.combineGames} selectedGames={selectedGames[u.userId]?.combineGames || []}
                                        setSelectedGames={(games) => {
                                            setSelectedGames((prevSelectedGames) => ({
                                                ...prevSelectedGames,
                                                [u.userId]: {
                                                    ...prevSelectedGames[u.userId],
                                                    combineGames: games,
                                                },
                                            }));
                                        }} part="Combine" />
                                    <div>
                                        <label>Selected games:</label>
                                        {selectedGames[u.userId]?.puttingGames?.map((game) => (
                                            <li>{game.name}</li>
                                        ))}
                                        {selectedGames[u.userId]?.argGames?.map((game) => (
                                            <li>{game.name}</li>
                                        ))}
                                        {selectedGames[u.userId]?.appGames?.map((game) => (
                                            <li>{game.name}</li>
                                        ))}
                                        {selectedGames[u.userId]?.teeGames?.map((game) => (
                                            <li>{game.name}</li>
                                        ))}
                                        {selectedGames[u.userId]?.combineGames?.map((game) => (
                                            <li>{game.name}</li>
                                        ))}
                                    </div>
                                    <Button label="Send" onClick={() => sendEmail(u.name, u.userId, u.email)} />

                                </Card>)
                        })}
                    </Card>
                </div>}
        </div >
    )

}
export { TeamPlanner }