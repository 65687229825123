import React, { useState, useEffect, useRef } from "react";
import { Button, Table, Input, Label } from "reactstrap";
import { Link } from 'react-router-dom';

function EditableTable(props) {

  // Table helper states
  const [parSum, setParSum] = useState(0);
  const [disSum, setDisSum] = useState(0);

  const [parInSum, setParInSum] = useState(0);
  const [parOutSum, setParOutSum] = useState(0);
  const [disInSum, setDisInSum] = useState(0);
  const [disOutSum, setDisOutSum] = useState(0);


  const teeNameInputRef = useRef(null);

  const [total, setTotal] = useState(0);

  const initialParArray = [
    4, 4, 4, 4, 4, 4,
    4, 4, 4, 4, 4, 4,
    4, 4, 4, 4, 4, 4,
  ]

  const initialDisArray = [
    0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0,
    0, 0, 0, 0, 0, 0,
  ]


  const [parInputArray, setParInputArray] = useState(initialParArray);

  const [disInputArray, setDisInputArray] = useState(initialDisArray);

  const rows = Array.from({ length: 18 }, (_, i) => i + 1);


  const calcTotals = () => {
    setParSum(parInputArray.reduce((acc, curr) => acc + curr, 0));
    setParInSum(parInputArray.slice(0, 9).reduce((acc, curr) => acc + curr, 0));
    setParOutSum(parInputArray.slice(9, 18).reduce((acc, curr) => acc + curr, 0));
    setDisSum(disInputArray.reduce((acc, curr) => acc + curr));
    setDisInSum(disInputArray.slice(0, 9).reduce((acc, curr) => acc + curr));
    setDisOutSum(disInputArray.slice(9, 18).reduce((acc, curr) => acc + curr));
  }


  const updateParInputArray = (index, value) => {
    const newArray = [...parInputArray];
    newArray[index - 1] = Number(value);
    setParInputArray(newArray);
  };

  const updateDisInputArray = (index, value) => {
    const newDisArray = [...disInputArray];
    newDisArray[index - 1] = Number(value);
    if (props.yards) {
      if (value < 250) {
        updateParInputArray(index, 3);
      } else if (value > 500) {
        updateParInputArray(index, 5);
      } else {
        updateParInputArray(index, 4);
      }
    } else {
      if (value < 240) {
        updateParInputArray(index, 3);
      } else if (value > 460) {
        updateParInputArray(index, 5);
      } else {
        updateParInputArray(index, 4);
      }
    }

    setDisInputArray(newDisArray);
  };



  useEffect(() => {
    calcTotals();

  }, [parInputArray, parSum, disSum, disInputArray]);
  /**Checks if all distances have been filled out */
  const checkDistanceFilled = () => {
    return disInputArray.every((distance) => distance !== '' && distance !== 0);
  };

  return (
    <div>
      <div
        className="table-container"
        style={{ marginTop: "20px", textAlign: "center" }}
      >
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Hole</th>
              <th>Distance</th>
              <th>Par</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(0, 9).map((row) => (
              <tr key={row}>
                <td>{row}</td>
                <td>
                  <Input
                    type="text"
                    maxLength={3}
                    inputMode="numeric"
                    onClick={(e) => e.target.value = ""}
                    onChange={(e) => updateDisInputArray(row, e.target.value)}
                    placeholder={props.yards ? "yards" : "meters"} min={0} />
                </td>
                <td>
                  <Input
                    type="number"
                    maxLength={1}
                    inputMode="numeric"
                    value={parInputArray[row - 1]}
                    onClick={(e) => e.target.value = ""}
                    onChange={(e) => updateParInputArray(row, e.target.value)}
                    min={3} />
                </td>
              </tr>
            ))}

          </tbody>
          <tfoot>
            <tr>
              <th>Front</th>
              <th>{disInSum}</th>
              <th>{parInSum}</th>
            </tr>
          </tfoot>
        </Table>

        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Hole</th>
              <th>Distance</th>
              <th>Par</th>
            </tr>
          </thead>
          <tbody>
            {rows.slice(9, 18).map((row) => (
              <tr key={row}>
                <td>{row}</td>
                <td>
                  <Input
                    type="text"
                    maxLength={3}
                    inputMode="numeric"
                    onClick={(e) => e.target.value = ""}
                    onChange={(e) => updateDisInputArray(row, e.target.value)}
                    placeholder={props.yards ? "yards" : "meters"}
                    min={0} />
                </td>
                <td>
                  <Input
                    type="number"
                    maxLength={1}
                    inputMode="numeric"
                    value={parInputArray[row - 1]}
                    onChange={(e) => updateParInputArray(row, e.target.value)}
                    onClick={(e) => e.target.value = ""}
                    min={3} />
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <th>Back</th>
              <th>{disOutSum}</th>
              <th>{parOutSum}</th>
            </tr>
          </tfoot>
        </Table>
      </div>

      <div className="d-grid gap-2">
        <Table striped bordered hover>
          <tfoot>
            <tr style={{ width: "50%", textAlign: "center" }}>
              <th>Total {props.yards ? "Yards" : "Meters"}: {disSum}</th>
              <th>Total Par: {parSum}</th>
            </tr>
          </tfoot>
        </Table>
        <div className="d-flex gap-3">
          <Button disabled={!(checkDistanceFilled() && props.isInputFilled)} onClick={() => {
            if (!props.yards) {
              disInputArray.forEach((dst, index) => {
                disInputArray[index] = parseInt(dst * 1.09361);
              });
            }
            props.onClick2(parInputArray, disInputArray)
          }
          } color="primary" size="lg" style={{ width: "50%" }} tag={Link} to="/">
            Submit Tee
          </Button>
          <Button onClick={props.onClick1} color="danger" size="lg" style={{ width: "50%" }}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}

export { EditableTable };