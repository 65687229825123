import React, { useEffect, useState } from 'react';
import '../custom.css'
import {
    Container, Form, FormGroup, Input, Label,
} from "reactstrap";

import { Card } from 'primereact/card';
import { useAuth0 } from "@auth0/auth0-react";
import { Scorecard } from './Scorecard';
import { IndividualStats } from './IndividualStats';
import { useParams } from 'react-router-dom';
import { StrokesGainedHolesTable } from './StrokesGainedHolesTable';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { ProgressBar } from 'primereact/progressbar';
import { Button } from 'primereact/button';
import '../css/sgHole.css'
const RoundStats = () => {

    const { user } = useAuth0();
    const { loginWithRedirect, getAccessTokenSilently } = useAuth0();
    const [data, setData] = useState("loading");
    const [round, setRound] = useState(null);
    const [baseline, setBaseline] = useState("PGA");
    const [userUnits, setUserUnits] = useState("");
    const { id } = useParams();
    /**Good switch*/
    const [bottom10, setBottom10] = useState(false);
    /**Bad switch */
    const [top20, setTop20] = useState(false);
    useEffect(() => {
        fetch(`/api/Calculation/one-round/${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {

                //setWasDataLoaded(true);
                return response.json();
            })
            .then(data => {
                setData(data);
            })
            .catch(error => {
                console.error(error);
            });
        fetch(`/api/Round/id?id=${id}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            },
        })
            .then(response => {

                //setWasDataLoaded(true);
                return response.json();
            })
            .then(data => {
                setRound(data[0]);
                setBaseline(data[0].baseline)
            })
            .catch(error => {
                console.error(error);
            });
    }, [])
    const formatDate = (date) => {
        const d = new Date(date);
        const month = d.getMonth() + 1;
        const day = d.getDate();
        const year = d.getFullYear();
        if (isNaN(month)) {
            return "...";
        }
        return `${month}/${day}/${year}`;
    };

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const token = await getAccessTokenSilently({
                    authorizationParams: {
                        audience: 'https://parfectperformance.com/api',
                        scope: 'read:users'
                    },
                });

                const response = await fetch(`api/user/userId?userId=${user.sub}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                const responseData = await response.json();
                setUserUnits(responseData[0].units);
            } catch (error) {
                console.log(error.error)
                if (error.error === 'login_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    });
                } else if (error.error === 'consent_required') {

                    await loginWithRedirect({
                        authorizationParams: {
                            audience: 'https://parfectperformance.com/api',
                            scope: 'read:users'
                        },
                    }, {
                        appState: { targetUrl: window.location.href }
                    });
                } else {
                    console.error(error);
                }
            }
        }
        var units = window.localStorage.getItem("units");
        if (units) {
            setUserUnits(units);
        } else {
            fetchUser();
        }

    }, [loginWithRedirect, getAccessTokenSilently]);

    return (
        <div>
            <div className='mb-2' style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "10px" }}>
                <h1>
                    {round && `${round.type} round on ${formatDate(round.date)} at ${round.course}`}
                </h1>
                <Button className="mb-4" label="Edit Round" severity="secondary" icon="pi pi-file-edit"
                    onClick={() => window.open(`/round-edit/${round.id}`, "_self")} />

            </div>
            <Container>
                <div className='table-responsive'>
                    {round &&
                        <Scorecard round={round} />
                    }
                </div>
                <Accordion multiple className='mt-3 mb-3' activeIndex={[0, 1, 2, 3]}>
                    <AccordionTab header={`Strokes Gained Off the Tee Per Hole `}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgOTT}`}
                                style={{
                                    backgroundColor: data.sgOTT > 0.45 ? 'green' : data.sgOTT < -0.5 ? 'red' : '',
                                    color: data.sgOTT > 0.45 ? 'white' : data.sgOTT < -0.5 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgOttPerHole} header={"Strokes Gained Off the Tee"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                    <AccordionTab header={"Strokes Gained Approach Per Hole"}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgApp}`}
                                style={{
                                    backgroundColor: data.sgApp > 0.49 ? 'green' : data.sgApp < -0.53 ? 'red' : '',
                                    color: data.sgApp > 0.49 ? 'white' : data.sgApp < -0.53 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgAppPerHole} header={"Strokes Gained Approach"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                    <AccordionTab header={"Strokes Gained Around the Green Per Hole"}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgARG}`}
                                style={{
                                    backgroundColor: data.sgARG > 0.39 ? 'green' : data.sgARG < -0.33 ? 'red' : '',
                                    color: data.sgARG > 0.39 ? 'white' : data.sgARG < -0.33 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgArgPerHole} header={"Strokes Gained Around the Green"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                    <AccordionTab header={"Strokes Gained Putting Per Hole"}>
                        {data !== "loading" ?
                            <Card title={`Total: ${data.sgPutting}`}
                                style={{
                                    backgroundColor: data.sgPutting > 0.45 ? 'green' : data.sgPutting < -0.46 ? 'red' : '',
                                    color: data.sgPutting > 0.45 ? 'white' : data.sgPutting < -0.46 ? 'white' : ''
                                }}>
                                <StrokesGainedHolesTable data={data.sgPuttingPerHole} header={"Strokes Gained Putting"} />
                            </Card> :
                            <ProgressBar className="mb-2 mt-1" mode="indeterminate" style={{ height: '6px' }}></ProgressBar>
                        }
                    </AccordionTab>
                </Accordion>
                <Form>
                    <FormGroup switch>
                        <Input
                            type="switch"
                            checked={bottom10}
                            style={{ backgroundColor: bottom10 === true ? 'red' : '' }}
                            onClick={() => {
                                setBottom10(!bottom10);
                            }}
                        />
                        <Label check>Bad</Label>
                    </FormGroup>
                    <FormGroup switch >
                        <Input
                            type="switch"
                            checked={top20}
                            style={{ backgroundColor: top20 === true ? 'green' : '' }}
                            onClick={() => {
                                setTop20(!top20);
                            }}
                        />
                        <Label check>Good</Label>
                    </FormGroup>
                </Form>
                <IndividualStats data={data} top20={top20} bottom10={bottom10} baseline={baseline} units={userUnits} />
            </Container>
        </div>
    )
}

export { RoundStats }